import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { finalityFailure, finalitySuccess } from './actions';

export function* saveFinality({ payload }) {
  try {
    const { finalidade } = payload;
    const response = yield call(api.post, 'ptams', {
      finalidade,
    });

    toast.success('Finalidade cadastrada com sucesso!');

    yield put(finalitySuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao cadastrar a finalidade, verifique seus dados!');

    yield put(finalityFailure());
  }
}

export function* updateFinality({ payload }) {
  try {
    const { id, finalidade } = payload;
    const response = yield call(api.put, 'ptams', {
      id,
      finalidade,
    });

    toast.success('Finalidade atualizada com sucesso!');

    yield put(finalitySuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao atualizar a finalidade, verifique seus dados!');

    yield put(finalityFailure());
  }
}

export default all([
  takeLatest('@req/FINALITY_REQUEST', saveFinality),
  takeLatest('@req/FINALITY_UPDATE', updateFinality),
]);
