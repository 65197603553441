import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background: #0d2c5b;
`;

export const Content = styled.div`
  width: 80%;
  text-align: center;
`;
