export function areasRequest({
  terreno,
  construido,
  privado,
  comum,
  total,
  fracao,
  id_user,
  id_ptam,
}) {
  return {
    type: '@area/AREAS_REQUEST',
    payload: {
      terreno,
      construido,
      privado,
      comum,
      total,
      fracao,
      id_user,
      id_ptam,
    },
  };
}

export function areasUpdate({
  id,
  terreno,
  construido,
  privado,
  comum,
  total,
  fracao,
  id_user,
  id_ptam,
}) {
  return {
    type: '@area/AREAS_UPDATE',
    payload: {
      id,
      terreno,
      construido,
      privado,
      comum,
      total,
      fracao,
      id_user,
      id_ptam,
    },
  };
}

export function areasLoad(id) {
  return {
    type: '@area/AREAS_LOAD',
    payload: { id },
  };
}

export function areasDelete(id) {
  return {
    type: '@area/AREAS_DELETE',
    payload: { id },
  };
}

export function areasSuccess(areas_id) {
  return {
    type: '@area/AREAS_SUCCESS',
    payload: { areas_id },
  };
}

export function areasFailure() {
  return {
    type: '@area/AREAS_FAILURE',
  };
}
