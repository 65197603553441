import styled from 'styled-components';

export const Container = styled.div`
  div {
    img {
      width: 10px;
      height: 10px;
      border: 0;
      border-radius: 4px;
    }

    input,
    select,
    textarea {
      margin-bottom: 3%;
      width: 500px;
    }

    label {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 3%;
    }
  }
`;
