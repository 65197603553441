import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../services/api';

import { Container } from './styles';

export default function LegendaSamples({ file, setOpen, open, handleRefresh }) {
  const [legenda, setLegenda] = useState(file.legenda || '');
  const img = `data:image/jpeg;base64,${file.base64}` || '';

  async function handleUpdateLegenda(id) {
    await api.put('images-sample', {
      id,
      legenda,
    });

    setOpen(false);
    handleRefresh(true);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Dados da Legenda</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha abaixo a legenda da imagem
            </DialogContentText>

            <img src={img} alt={legenda} />

            <label htmlFor="legenda">
              Legenda
              <input
                autoFocus
                id="legenda"
                label="Digite a legenda"
                type="text"
                value={legenda}
                onChange={e => setLegenda(e.target.value)}
              />
            </label>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleUpdateLegenda(file.id);
              }}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

LegendaSamples.propTypes = {
  file: PropTypes.objectOf(PropTypes.object).isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};
