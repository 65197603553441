import React from 'react';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import api from '../../services/api';
import history from '../../services/history';

import logo from '../../assets/logobranco.png';

import { Container, Content, Background, AnimatedContainer } from './styles';

/* validação de campos */
const schema = Yup.object().shape({
  token: Yup.string().required('O token é obrigatório!'),
  password: Yup.string()
    .min(6, 'No mínimo 6 caracteres!')
    .required('A senha é obrigatória!'),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required()
          .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
      : field
  ),
});

export default function ResetPass() {
  async function handleSubmit({ token, password }) {
    await api
      .post('/reset-pass', {
        token,
        password,
      })
      .then(function(response) {
        toast.success('Senha alterada com sucesso!');
        history.push('/');
      })
      .catch(function(error) {
        console.log(error);
        toast.error('Falha ao alterar a senha, verifique seus dados!');
      });
  }

  return (
    <Container>
      <Content>
        <AnimatedContainer>
          <img src={logo} alt="Avalie Fácil" />
          <Form schema={schema} onSubmit={handleSubmit}>
            <h2>Alteração de Senha</h2>
            <p>
              Para alterar sua senha digite abaixo o token enviado para seu
              celular, escolha e digite no campo abaixo a nova senha depois
              novamente para confirmação! !
            </p>
            <Input name="token" type="text" placeholder="Digite o token" />
            <hr />
            <Input
              name="password"
              type="password"
              placeholder="Digite sua Senha"
            />
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirme sua Senha"
            />
            <button type="submit">Enviar</button>
          </Form>
        </AnimatedContainer>
      </Content>
      <Background />
    </Container>
  );
}
