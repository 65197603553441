import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { DropContainer, UploadMessage } from './styles';

export default class UploadImagesSample extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <UploadMessage>Clique ou arraste aqui a imagem...</UploadMessage>;
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Arquivo não suportado ou limite de imagens ultrapassado
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  };

  render() {
    const { onUpload, totalFiles } = this.props;

    return (
      <Dropzone
        accept="image/*"
        onDropAccepted={onUpload}
        maxFiles={1}
        multiple={false}
        disabled={totalFiles !== 0}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}

UploadImagesSample.propTypes = {
  onUpload: PropTypes.func.isRequired,
  totalFiles: PropTypes.number.isRequired,
};
