import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100% !important;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  transition: all 0.2s 0.2s;

  z-index: 999;
`;

export const Content = styled.div`
  position: relative;

  margin: 36px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;

  background: #fff;
  box-shadow: 0 2px 8px #fa923f;
  border-radius: 8px;

  h1 {
    font-size: 32px;
    text-transform: uppercase;
    margin: 36px auto;
  }

  label {
    font-size: 16px;
    color: #fa923f;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  button {
    background: transparent;
    border: 0;

    display: flex;
    align-items: center;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
