/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import MaskedInput from 'react-text-mask';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import AvatarInput from '../AvatarInput';
import CompanyImage from '../CompanyImage';
import apiCep from '../../../services/cep';
import { updateProfileRequest } from '../../../store/modules/user/actions';

import { Container, Form } from './styles';

export default function Handle({ open, setModal }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);

  const [profile, setProfile] = useState(user);

  async function buscaCep(e) {
    const CEPvalue = e.target.value;

    const response = await apiCep.get(`${CEPvalue}/json`);

    setProfile({
      ...profile,
      cep: CEPvalue,
      address: response.data.logradouro,
      complement: response.data.complemento,
      neighborhood: response.data.bairro,
      state: response.data.uf,
      city: response.data.localidade,
    });
  }

  const userDocValue = useMemo(() => {
    switch (profile.usertype) {
      case 'Avaliador Imobiliário(a)':
        return 'CNAI';

      case 'Arquiteto(a)':
        return 'CAU';

      case 'Engenheiro(a)':
        return 'CREA';

      default:
        setProfile({ ...profile, cnai: null });
        return null;
    }
  }, [profile.usertype]);

  function handleSave() {
    if (!profile.name) {
      toast.error('O seu nome é obrigatório para ser informado !');
      return;
    }

    if (!profile.email) {
      toast.error('O seu e-mail é obrigatório para ser informado !');
      return;
    }

    if (!profile.mobile) {
      toast.error('Um numero de celular com DDD é obrigatório!');
      return;
    }

    if (!profile.cep) {
      toast.error('O CEP é obrigatório!');
      return;
    }

    if (!profile.address) {
      toast.error('Um endereço válido é obrigatório!');
      return;
    }

    if (!profile.number) {
      toast.error('Um numero para o endereço é obrigatório!');
      return;
    }

    delete profile.avatar;

    dispatch(updateProfileRequest(profile));
    setModal(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados do perfil do Usuário
          </DialogTitle>
          <Box display="flex" justifyContent="space-around">
            <Box display="flex" flexDirection="column" alignItems="center">
              <AvatarInput profile={profile} setProfile={setProfile} />
              <span>Foto do Perfil</span>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CompanyImage profile={profile} setProfile={setProfile} />
              <span>Logotipo da Empresa</span>
            </Box>
          </Box>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para gravação, edição do Perfil do
              Usuário! ** ATENÇÃO ** ITENS EM VERMELHO SÃO OBRIGATÓRIOS!!
            </DialogContentText>
            <Form>
              <div className="side1">
                <h1>Dados Pessoais</h1>
                <label htmlFor="name">
                  Nome
                  <input
                    required
                    id="name"
                    label="Nome"
                    type="text"
                    value={profile.name || ''}
                    onChange={e =>
                      setProfile({ ...profile, name: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="email">
                  E-mail
                  <input
                    required
                    id="email"
                    label="E-mail"
                    type="email"
                    value={profile.email || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        email: e.target.value.toLowerCase(),
                      })
                    }
                  />
                </label>

                <label htmlFor="site">
                  Site
                  <input
                    id="site"
                    label="Site"
                    type="site"
                    value={profile.site || ''}
                    onChange={e =>
                      setProfile({ ...profile, site: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="usertype">
                  Tipo Usuário
                  <select
                    id="usertype"
                    label="Tipo Usuário"
                    value={profile.usertype || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        usertype: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Avaliador Imobiliário(a)">
                      Avaliador Imobiliário(a)
                    </option>
                    <option value="Arquiteto(a)">Arquiteto(a)</option>
                    <option value="Engenheiro(a)">Engenheiro(a)</option>
                  </select>
                </label>

                {userDocValue && (
                  <label htmlFor="cnai">
                    {userDocValue}
                    <input
                      id="cnai"
                      label={`Nº do ${userDocValue}`}
                      type="number"
                      value={profile.cnai || ''}
                      onChange={e =>
                        setProfile({ ...profile, cnai: e.target.value })
                      }
                    />
                  </label>
                )}

                <label htmlFor="celular">
                  Celular
                  <MaskedInput
                    required
                    id="celular"
                    label="Celular"
                    type="text"
                    value={profile.mobile || ''}
                    onChange={e =>
                      setProfile({ ...profile, mobile: e.target.value })
                    }
                    mask={[
                      '(',
                      /[0-9]/,
                      /[0-9]/,
                      ')',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                  />
                </label>

                <label htmlFor="telefone">
                  Telefone Fixo
                  <MaskedInput
                    id="telefone"
                    label="Telefone"
                    type="text"
                    value={profile.phone || ''}
                    onChange={e =>
                      setProfile({ ...profile, phone: e.target.value })
                    }
                    mask={[
                      '(',
                      /[0-9]/,
                      /[0-9]/,
                      ')',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                  />
                </label>

                <label htmlFor="cpf">
                  CPF
                  <MaskedInput
                    disabled
                    id="cpf"
                    label="CPF"
                    type="text"
                    value={profile.cpf || ''}
                    onChange={e =>
                      setProfile({ ...profile, cpf: e.target.value })
                    }
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '.',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '.',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                  />
                </label>

                <label htmlFor="creci">
                  CRECI (Se se for corretor)
                  <input
                    id="creci"
                    label="Nº CRECI"
                    type="number"
                    value={profile.creci || ''}
                    onChange={e =>
                      setProfile({ ...profile, creci: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="regiao_creci">
                  Estado do CRECI (Se for corretor)
                  <select
                    id="regiao_creci"
                    label="Estado do Creci"
                    value={profile.regiao_creci || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        regiao_creci: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernanbuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="**">Exterior</option>
                  </select>
                </label>
                <h1>Dados de Acesso</h1>
                <label htmlFor="password">
                  Senha Atual
                  <input
                    id="password"
                    type="password"
                    value={profile.oldPassword || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        oldPassword: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="new_password">
                  Nova senha
                  <input
                    id="new_password"
                    type="password"
                    value={profile.password || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        password: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="confirm_password">
                  Confirme a nova senha
                  <input
                    id="confirm_password"
                    type="password"
                    value={profile.confirmPassword || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <h1>Endereço</h1>
                <label htmlFor="cep">
                  CEP
                  <MaskedInput
                    required
                    id="cep"
                    label="Cep"
                    value={profile.cep || ''}
                    onChange={e =>
                      setProfile({ ...profile, cep: e.target.value })
                    }
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    onBlur={e => buscaCep(e)}
                  />
                </label>

                <label htmlFor="address">
                  Endereço
                  <input
                    required
                    id="address"
                    label="Endereço"
                    type="text"
                    value={profile.address || ''}
                    onChange={e =>
                      setProfile({ ...profile, address: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="number">
                  Numero
                  <input
                    required
                    id="number"
                    label="Número"
                    type="text"
                    value={profile.number || ''}
                    onChange={e =>
                      setProfile({ ...profile, number: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="complement">
                  Complemento
                  <input
                    id="complement"
                    label="Complemento (se houver)"
                    type="text"
                    value={profile.complement || ''}
                    onChange={e =>
                      setProfile({ ...profile, complement: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="neighborhood">
                  Bairro
                  <input
                    required
                    id="neighborhood"
                    label="Bairro"
                    type="text"
                    value={profile.neighborhood || ''}
                    onChange={e =>
                      setProfile({ ...profile, neighborhood: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="city">
                  Cidade
                  <input
                    required
                    id="city"
                    label="Cidade"
                    type="text"
                    value={profile.city || ''}
                    onChange={e =>
                      setProfile({ ...profile, city: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="state">
                  Estado
                  <select
                    required
                    id="state"
                    label="Estado"
                    value={profile.state || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        state: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernanbuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="**">Exterior</option>
                  </select>
                </label>

                <h1>Área do Avaliador</h1>
                <label htmlFor="show_data">
                  Exibe seus Dados em Encontre um Avaliador?
                  <select
                    id="show_data"
                    value={profile.show_data || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        show_data: e.target.value,
                      })
                    }
                  >
                    <option value="Sim">Sim</option>
                    <option value="Nao">Não</option>
                  </select>
                </label>

                <h1>Qualificações e Experiências</h1>
                <label htmlFor="professional_qualification">
                  Qualificações Profissionais
                  <textarea
                    id="professional_qualification"
                    label="Qualificações Profissionais"
                    type="text"
                    value={profile.professional_qualification || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        professional_qualification: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="professional_experience">
                  Experiências Profissionais
                  <textarea
                    id="professional_experience"
                    label="Experiências Profissionais"
                    type="text"
                    value={profile.professional_experience || ''}
                    onChange={e =>
                      setProfile({
                        ...profile,
                        professional_experience: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};
