export function signatureRequest({
  sku,
  titulo,
  descricao,
  valor_total,
  desconto,
  dias_validade,
  parcelas,
  vlr_parcela,
  botao,
}) {
  return {
    type: '@signature/REQUEST',
    payload: {
      sku,
      titulo,
      descricao,
      valor_total,
      desconto,
      dias_validade,
      parcelas,
      vlr_parcela,
      botao,
    },
  };
}

export function signatureUpdate({
  id,
  sku,
  titulo,
  descricao,
  valor_total,
  desconto,
  dias_validade,
  parcelas,
  vlr_parcela,
  botao,
}) {
  return {
    type: '@signature/UPDATE',
    payload: {
      id,
      sku,
      titulo,
      descricao,
      valor_total,
      desconto,
      dias_validade,
      parcelas,
      vlr_parcela,
      botao,
    },
  };
}

export function signatureDelete(id) {
  return {
    type: '@signature/DELETE',
    payload: { id },
  };
}

export function signatureSuccess(signarure) {
  return {
    type: '@signature/SUCCESS',
    payload: { signarure },
  };
}

export function signatureFailure() {
  return {
    type: '@signature/FAILURE',
  };
}
