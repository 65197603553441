import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdInput } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../services/api';
import { cartorioSuccess } from '../../../store/modules/registration/actions';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, Body } from './styles';

export default function Cartorio({ setFlag, flag }) {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartorio, setCartorio] = useState({
    id: null,
    matricula: '',
    cartorio: '',
    descricao: '',
    cadastro: '',
    vlr_imposto_anual: 0,
    vlr_imposto_mensal: 0,
    mts_terreno: 0,
    vlr_venal_terreno: 0,
    mts_construcao: 0,
    vlr_venal_construcao: 0,
    vlr_venal_total: 0,
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadRegistrations() {
      const response = await api.get(`/registrations/${ptam.id_registration}`);

      if (response.data) {
        setCartorio(response.data);
        setLoading(false);
      }
      /* setRefresh(false); */
    }

    if (ptam.id_registration) {
      setLoading(true);
      loadRegistrations();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/registrations/${cartorio.id}`);

      setCartorio({
        id: null,
        matricula: '',
        cartorio: '',
        descricao: '',
        cadastro: '',
        vlr_imposto_anual: 0,
        vlr_imposto_mensal: 0,
        mts_terreno: 0,
        vlr_venal_terreno: 0,
        mts_construcao: 0,
        vlr_venal_construcao: 0,
        vlr_venal_total: 0,
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(cartorioSuccess(null));

      setFlag({ ...flag, cartorio: false });
      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          cartorio={cartorio}
          setCartorio={setCartorio}
          setEdit={setEdit}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir a Matrícula e o Cartório de
            Registro ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da Matrícula e o Cartório de Registro atual ela
              não estará mais disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdInput size={20} color="#fa923f" />
          <h1>Matrícula e Registro de Cartório</h1>
        </div>
        <div>
          {!ptam.id_registration ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Registro</span>
              </div>
            </button>
          ) : null}

          {ptam.id_registration && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Registro</span>
              </div>
            </button>
          )}

          {ptam.id_registration && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Registro</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados do Cartório</h1>

        <strong>Nº Matrícula</strong>
        <span>{cartorio.matricula}</span>

        <strong>Cartório</strong>
        <span>{cartorio.cartorio}</span>

        <strong>Descrição</strong>
        <span>{cartorio.descricao}</span>
        <hr />

        <h1>Dados do Imposto (Valor Venal)</h1>

        <strong>Nº Cadastro IPTU</strong>
        <span>{cartorio.cadastro}</span>

        <strong>Valor do Imposto Anual</strong>
        <span>{cartorio.vlr_imposto_anual}</span>

        <strong>Valor do Imposto Mensal</strong>
        <span>{cartorio.vlr_imposto_mensal}</span>

        <strong>Metragem do Terreno (m²)</strong>
        <span>
          {Intl.NumberFormat('pt-BR', {
            style: 'decimal',
          }).format(cartorio.mts_terreno)}
        </span>

        <strong>Valor Venal do Terreno</strong>
        <span>{cartorio.vlr_venal_terreno}</span>

        <strong>Metragem da Construção (m²)</strong>
        <span>
          {Intl.NumberFormat('pt-BR', {
            style: 'decimal',
          }).format(cartorio.mts_construcao)}
        </span>

        <strong>Valor Venal da Construção</strong>
        <span>{cartorio.vlr_venal_construcao}</span>

        <strong>Perfazendo o Valor Total do Imóvel</strong>
        <span>{cartorio.vlr_venal_total}</span>
        <hr />
      </Body>
    </Container>
  );
}

Cartorio.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
