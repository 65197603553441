/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';

import { Form, Input } from '@rocketseat/unform';
import validarCpf from 'validar-cpf';
import ReCAPTCHA from 'react-google-recaptcha';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Loader from '../../components/Loader';

import logo from '../../assets/logo.png';

import api from '../../services/api';
import history from '../../services/history';

import { Container, Content, Background, AnimatedContainer } from './styles';

/* validação de campos */
const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido!')
    .required('O e-mail é obrigatório!'),
  name: Yup.string().required('O Nome é obrigatório!'),
  cpf: Yup.string().required('O CPF é obrigatório!'),
  password: Yup.string()
    .min(6, 'No mínimo 6 caracteres!')
    .required('A senha é obrigatória!'),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required()
          .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
      : field
  ),
});

export default function SignUp() {
  const [captcha, setCaptcha] = useState(false);
  const [nome, setNome] = useState('');
  const [mobile, setMobile] = useState('');
  const [checked, setChecked] = useState(false);
  const [termo, setTermo] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [openCreci, setOpenCreci] = useState(false);
  const [loading, setLoading] = useState(false);

  const label = (
    <span style={{ color: '#fa923f', fontSize: '14px', fontWeight: 'bold' }}>
      <a href="#" onClick={() => setTermo(true)}>
        Eu li e concordo com os &apos;Termos de Uso&apos; e &apos;Política de
        Privacidade&apos; do Portal Avalie Facil
      </a>
    </span>
  );

  async function handleSubmit({ cpf, name, email, password }) {
    if (!name) {
      toast.error('O nome é obrigatório!');
      return;
    }

    if (mobile.indexOf('_') !== -1) {
      toast.error('Existem caracteres inválidos no campo celular, verifique!');
      return;
    }

    const cpf_valid = cpf.replace(/\./g, '').replace(/\-/g, '');

    try {
      if (checked) {
        if (captcha) {
          setLoading(true);
          await api.post('/users', {
            cpf: cpf_valid,
            name,
            mobile,
            email: email.toLowerCase(),
            password,
          });

          toast.success(
            'Cadastro realizado com sucesso ! Um TOKEN foi enviado por SMS !'
          );
          setLoading(false);

          history.push('/validmail');
        } else {
          setLoading(false);
          toast.error('Validação do CAPTCHA é obrigatória!');
        }
      } else {
        setLoading(false);
        toast.error('O Aceite dos termos de uso é obrigatório!');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data?.error);
    }
  }

  async function validacaoCpf(CPF) {
    const cpf = document.getElementById('cpf');

    if (!validarCpf(CPF)) {
      toast.error('CPF inválido');
      cpf.value = '';
      cpf.focus();
      setNome('');
    }
  }

  function handleCaptcha() {
    setCaptcha(true);
  }

  function handleTermo(opc) {
    if (opc === 1) {
      setChecked(true);
      setTermo(false);
    } else {
      setChecked(false);
      setTermo(false);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      <Background />
      <Content>
        <AnimatedContainer>
          <Dialog
            open={openMobile}
            onClose={() => setOpenMobile(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Porque precisamos do seu celular?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para garantir a segurança dos seus dados e das informações que
                você irá inserir no portal Avalie Fácil nós utilizamos o
                conceito de validação via TOKEN que consiste na geração de um
                numero aleatório, envio do mesmo via SMS e confirmação desse
                numero em uma tela que será exibida assim que concluído seu
                cadastro!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenMobile(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openCreci}
            onClose={() => setOpenCreci(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Porque precisamos do seu CRECI?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                O portal Avalie Fácil foi criado para ser utilizado
                exclusivamente por corretores e/ou avaliadores de imóveis
                devidamente registrados nos conselhos CRECI e/ou COFECI/CNAI. Se
                você não é corretor ou avaliador mas precisa de uma avaliação
                para seu imóvel clique no link abaixo, consulte nossa lista de
                avaliadores cadastrados por estado/cidade e entre em contato com
                um deles!
                <br />
                <br />
                <a
                  href="https://avaliefacil-app.netlify.app/brokers"
                  target="_blank"
                  rel="noreferrer"
                >
                  Encontre um Avaliador
                </a>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCreci(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={termo}
            onClose={() => setTermo(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4 style={{ color: '#000' }}>Termos de Uso</h4>
                <br />
                <p>
                  Os presentes Termos de Serviço (“Termos”) regulam a utilização
                  por você (doravante “Usuário”) do Avalie Fácil, software
                  disponibilizado online, no endereço www.avaliefacil.com.br, de
                  titularidade de Fácil Sistemas LTDA, pessoa jurídica de
                  direito privado, inscrita no CNPJ sob o nº 35.741.535/0001-06,
                  com endereço à Av. dos Expedicionários, 123 – Sala 7,
                  Paulínia/SP. Os presentes Termos estão disponíveis para acesso
                  pelo Usuário, a qualquer momento, no endereço:{' '}
                  <a
                    href="https://avaliefacil.com.br/termos-de-uso"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://avaliefacil.com.br/termos-de-uso
                  </a>{' '}
                  e devem ser aceitos pelo Usuário antes que este comece a
                  utilizar o Avalie Fácil.{' '}
                </p>
                <p>
                  <b>1. ACEITAÇÃO E ALTERAÇÃO:</b> <br />
                  <br />A utilização do Avalie Fácil e dos Serviços dependerá de
                  prévio aceite do Usuário a estes Termos, o que se dará quando
                  de seu clique no botão “Aceito os Termos de Serviço”, no seu
                  primeiro acesso ao Avalie Fácil. Deste modo, ao clicar em tal
                  botão, o Usuário declara ter lido, compreendido e aceitado as
                  condições destes Termos, manifestando consentimento livre,
                  expresso e informado, constituindo este instrumento o acordo
                  integral entre as partes. Caso o Usuário não concorde com
                  qualquer condição destes Termos, deverá abster-se de utilizar
                  o Avalie Fácil. Os presentes Termos poderão ser atualizados a
                  qualquer momento, sem prévio aviso, salvo se houver expressa
                  obrigação legal em sentido contrário, de modo que é
                  recomendável que o Usuário verifique, a cada novo acesso, a
                  data de atualização destes Termos, conforme informação
                  disponibilizada ao final do documento, lendo-os novamente, no
                  caso de atualização. O aceite eletrônico do presente Termo
                  será considerado válido para todos os fins de direito.
                </p>
                <p>
                  <b>2. CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO AVALIE FÁCIL:</b>{' '}
                  <br />
                  <br /> <b>2.1 Cadastro</b> <br />O acesso e a utilização do
                  Avalie Fácil pelo Usuário estão sujeitos ao preenchimento do
                  cadastro, com todas as informações requeridas pela Fácil
                  Sistemas como obrigatórias. A Fácil Sistema não se
                  responsabiliza por informações incorretas ou imprecisas
                  fornecidas pelos Usuários, no momento de seu cadastro no
                  Avalie Fácil, sendo os Usuários os únicos responsáveis pela
                  veracidade das informações apresentadas. O Usuário desde já
                  declara para os devidos fins de direito que as informações
                  contidas no cadastro realizado são verdadeiras e assume o
                  compromisso de apresentar, quando e se solicitado, os seus
                  documentos originais. Declara, ainda, ter ciência que a
                  inserção de qualquer declaração falsa ou diversa daquela que
                  deveria ser prestada é considerada crime, nos termos do artigo
                  299 do Código Penal, sujeitando o Usuário às suas penas,
                  independentemente de outras sanções cabíveis.
                  <br />
                  <br />
                  <b>2.2 Público-alvo</b> <br />O Avalie Fácil é destinado á
                  Avaliadores Imobiliários, devidamente registrados no
                  CRECI/CAU/CREA de seu estado valido em todo território
                  nacional. Dessa forma, a Fácil Sistemas poderá não autorizar o
                  cadastro de pessoas que não se enquadrem no público-alvo
                  objeto do Avalie Fácil, visando que os serviços prestados e as
                  informações disponibilizadas sejam exclusivamente utilizados
                  para as finalidades previstas nos presentes Termos. O Usuário
                  será o único responsável por eventual uso inadequado de
                  quaisquer informações ou pela utilização indevida dos recursos
                  disponibilizados. <br />
                  <br />
                  <b>
                    2.3 Obrigações do Usuário e Utilização do Avalie Fácil
                  </b>{' '}
                  <br />
                  Em razão do acesso e utilização do Avalie Fácil, o Usuário
                  obriga-se a: <br />
                  a) Inserir, quando de seu cadastro e utilização do Avalie
                  Fácil, apenas informações verdadeiras referentes a dados
                  pessoais, imóveis à venda, perfis de interesse, propostas
                  comerciais, entre outras, sendo o Usuário o único responsável
                  por eventuais informações inverídicas inseridas; <br />
                  b) Possuir as necessárias autorizações de seus clientes para a
                  inserção de dados a eles referentes, bem como dos imóveis de
                  sua propriedade, no Avalie Fácil, sendo o Usuário o único
                  responsável pela inserção de tais informações; <br />
                  c) Não inserir no Avalie Fácil qualquer conteúdo ilícito ou de
                  qualquer modo contrário à moral e aos bons costumes ou que
                  viole direitos de terceiros, como, por exemplo, mas não se
                  limitando a, conteúdo pornográfico ou difamatório; <br />
                  d) Não inserir no Avalie Fácil qualquer conteúdo que: <br />
                  &emsp;(i) constitua publicidade ilícita, enganosa ou desleal
                  e/ou concorrência desleal; <br />
                  &emsp;(ii) incorpore vírus ou outros elementos físicos ou
                  eletrônicos que possam causar danos ou impedir o normal
                  funcionamento da rede, do sistema ou de equipamentos
                  informáticos, da Fácil Sistemas, ou de terceiros; <br />
                  &emsp;(iii) provoque, por suas características (tais como
                  forma, extensão etc.) dificuldades no normal funcionamento do
                  Avalie Fácil ou dos Serviços; <br />
                  e) Pagar regularmente os valores estipulados quando da
                  contratação do Avalie Fácil; <br />
                  f) Manter segura a senha fornecida e não compartilhá-la com
                  terceiros, sendo o Usuário o único responsável por acessos
                  indevidos decorrentes do uso inadequado de senhas; <br />
                  g) Manter endereço de e-mail, válido e frequentemente lido,
                  nos cadastros da Fácil Sistemas para que ele possa se
                  comunicar com o Usuário de forma oficial; <br />
                  h) Manter seus cadastros devidamente atualizados, comunicando
                  à Fácil Sistemas sobre qualquer mudança de endereço, telefone
                  ou e-mail; <br />
                  i) Manter serviço de conexão banda larga à Internet, bem como
                  utilizar os browsers recomendados pela Fácil Sistemas quando
                  da contratação, de modo a obter os melhores resultados
                  provenientes da utilização do Avalie Fácil; <br />
                  j) Relatar de forma clara a ocorrência de qualquer problema de
                  natureza técnica e, caso necessário, prover relatórios, acesso
                  ao Avalie Fácil e Serviços e informações necessárias para a
                  Fácil Sistemas oferecer suporte técnico; <br />
                  k) Não utilizar o Avalie Fácil ou os Serviços para o envio de
                  mensagens não autorizadas (SPAM). Em qualquer circunstância, o
                  Usuário é o único responsável pelo uso que fizer do Avalie
                  Fácil, bem como pelo conteúdo inserido no software e nos
                  Serviços. Os Usuários são os únicos responsáveis pela
                  utilização que os seus funcionários, prepostos e/ou
                  colaboradores fizerem do Avalie Fácil. Dessa forma, os
                  Usuários não poderão se eximir da responsabilidade por
                  qualquer dano ou prejuízo decorrente da utilização do Avalie
                  Fácil. <br />
                  <br />
                  <b>2.4 Obrigações da Fácil Sistemas</b> <br />
                  Ao disponibilizar o acesso ao Avalie Fácil, a Fácil Sistemas
                  se obriga a: <br />
                  a) Manter o Avalie Fácil funcionando regularmente, desde que o
                  Usuário siga as orientações da Fácil Sistemas sobre o uso do
                  software e ressalvadas situações de mau funcionamento que
                  fujam da responsabilidade e do controle razoável da Fácil
                  Sistemas; <br />
                  b) Aplicar melhorias contínuas no Avalie Fácil, alterando,
                  quando necessário, especificações e orientações anteriores;{' '}
                  <br />
                  c) Suspender ou cancelar o acesso do Usuário ao Avalie Fácil
                  caso haja violação destes Termos ou de qualquer norma da
                  legislação brasileira pelo Usuário; <br />
                  d) Prestar suporte técnico do portal Avalie Fácil ao Usuário,
                  através de sistema de comunicação por texto (chat) disponível
                  na área logada do serviço ou email
                  (contato@avaliefacil.com.br), em horário comercial (das 09h00
                  às 17h00, de segunda a sexta-feira, exceto feriados), que
                  poderá ocorrer em até 24 horas a contar da abertura do chamado
                  feita pelo usuário, dentro dos horários estabelecidos neste
                  item, aqui considerando como base a cidade de Paulínia, Estado
                  de São Paulo; <br />
                  e) Manter sigilo sobre as informações que lhe forem confiadas
                  pelo Usuário, ressalvadas as previsões de compartilhamento
                  constante destes Termos. <br />
                  <br />
                  <b>
                    2.5 Propriedade Intelectual e Utilização Correta do Avalie
                    Fácil
                  </b>{' '}
                  <br />O Usuário não adquire, pelo presente instrumento, nenhum
                  direito de propriedade intelectual ou sobre o software Avalie
                  Fácil, ressalvado o direito de utilização do mesmo, da forma
                  como disponibilizado pela Fácil Sistemas e descrita nestes
                  Termos. Desse modo, o acesso ao Avalie Fácil não concede ao
                  Usuário, em nenhuma hipótese, o direito de acesso ao
                  código-fonte do Software. É terminantemente proibido ao
                  Usuário realizar engenharia reversa ou tentar obter o código
                  fonte, por qualquer meio, ou ainda, incorporar o software a
                  outros programas, sendo autorizado o uso do Avalie Fácil
                  apenas da forma como disponibilizada pela Fácil Sistemas. O
                  Usuário se compromete, ainda, a utilizar o Avalie Fácil, os
                  Serviços conexos e seu conteúdo, ou seja, os textos,
                  fotografias, gráficos, imagens, ícones, tecnologia, links e
                  demais conteúdos audiovisuais ou sonoros, layouts, desenhos
                  gráficos e códigos fonte, abrangendo-se também as informações
                  inseridas por outros Usuários (em conjunto, “Conteúdo(s)”), de
                  acordo com todo o ordenamento jurídico, com a moral e os bons
                  costumes geralmente aceitos, com os presentes Termos e as
                  demais instruções existentes no Avalie Fácil e nos Serviços,
                  sendo vedado o uso da imagem ou logotipo do sistema Avalie
                  Fácil em qualquer tipo de documento ou mídia, seja digital ou
                  impressa, sem a prévia autorização expressa da Fácil Sistemas
                  Ltda. O Conteúdo é de propriedade exclusiva da Fácil Sistemas,
                  e está protegido por leis e tratados internacionais, sendo
                  vedada sua cópia, reprodução, ou qualquer outro tipo de
                  utilização, ficando os infratores sujeitos às sanções civis e
                  criminais correspondentes, nos termos das Leis 9.610/98,
                  9.609/98 e 9.279/96. O Usuário deverá se abster de obter, ou
                  de tentar obter, os Conteúdos por meios distintos daqueles
                  que, em cada caso, tenham sido colocados à disposição para
                  tais propósitos.{' '}
                </p>
                <p>
                  <b>3. REMUNERAÇÃO:</b> <br />
                  <br />O Usuário pagará à Fácil Sistemas os valores
                  especificados no plano escolhido no ato da contratação
                  (“Plano”), respeitando a forma de pagamento indicada na mesma
                  ocasião. Caso o Usuário, no decorrer da vigência do presente
                  instrumento, deseje mudar o Plano adquirido, deverá pagar os
                  valores de acordo com o novo Plano selecionado e, caso haja
                  taxa de adesão, pagar a diferença entre a adesão previamente
                  paga e a adesão para o novo Plano. Não haverá devolução, pela
                  Fácil Sistemas ao Usuário, da taxa de adesão anteriormente
                  paga, caso a mudança seja para Plano de menor valor, em
                  relação ao anteriormente contratado. Se a taxa de adesão tiver
                  sido parcelada, o Usuário deverá, para cancelamento do
                  Contrato, quitar os valores das parcelas vencidas.
                  Adicionalmente, em caso de rescisão imotivada pelo Usuário,
                  não haverá devolução do valor da assinatura, se pago de uma só
                  vez, tampouco das taxas de implementação, migração de dados e
                  treinamento ou qualquer outra taxa para início da utilização
                  dos Serviços, conforme o caso. Os Planos poderão, a exclusivo
                  critério da Fácil Sistemas, ser comercializados em Combos,
                  aplicando-se aos referidos combos as mesmas regras e
                  características dos Planos, como se fossem comercializados
                  individualmente. A Fácil Sistemas se reserva o direito de
                  mudar automaticamente o Plano inicialmente escolhido, pelo
                  Usuário, oportunidade em que o Usuário deverá confirmar se
                  deseja continuar utilizando o respectivo serviço. Os valores
                  estabelecidos quando da contratação dos Produtos no Avalie
                  Fácil serão atualizados anualmente, pela variação positiva do
                  IGP-M acumulado no período de 12 (doze) meses anteriores ao
                  reajuste. Além da atualização pelo IGP-M, a Fácil Sistemas
                  poderá, a seu exclusivo critério, alterar os valores dos
                  Planos, sempre que entender necessário, oportunidade em que o
                  Usuário deverá confirmar se deseja continuar utilizando. No
                  caso de atraso ou inadimplemento dos pagamentos devidos à
                  Fácil Sistemas, conforme data estipulada quando da contratação
                  dos Produtos, os valores devidos serão acrescidos de juros de
                  mora de 1% (um por cento) ao mês, pro-rata-die, multa de 10%
                  sobre o valor do débito e correção monetária pelo IGP-M/FGV. A
                  falta de pagamento na data determinada para seu vencimento
                  acarretará a suspensão de acesso ao Avalie Fácil e a Serviços,
                  até que a pendência financeira seja regularizada. Em caso de
                  inadimplemento do Usuário por período igual ou superior a 45
                  (quarenta e cinco) dias, o presente contrato será
                  automaticamente rescindido e será necessário novo pagamento da
                  taxa de adesão, para que o Usuário obtenha novo acesso ao
                  Avalie Fácil. O Usuário está ciente e concorda desde já que,
                  caso o débito persista por mais de 45 (quarenta e cinco) dias,
                  poderá ter seu nome inscrito pela Fácil Sistemas nos órgãos de
                  proteção ao crédito. A Fácil Sistemas poderá, ainda,
                  disponibilizar ao Usuário uma avaliação gratuita a nível de
                  teste, durante período indeterminado, para que o Usuário teste
                  o Avalie Fácil, verificando se o software atende às suas
                  necessidades (“Período de Teste”). Encerrado o Período de
                  Teste, o Usuário receberá convite da Fácil Sistemas para
                  aderir ao Avalie Fácil, mediante as condições de pagamento por
                  ela apresentadas. Caso, após o encerramento do Período de
                  Teste, o Usuário não efetue o pagamento solicitado pela Fácil
                  Sistemas, seu acesso ao Avalie Fácil estará automaticamente
                  cancelado.{' '}
                </p>
                <p>
                  <b>4. NÍVEL DE SERVIÇO (SLA):</b> <br />
                  <br />A Fácil Sistemas empreenderá todos os esforços técnicos
                  e comercialmente razoáveis para garantir a disponibilidade do
                  acesso ao Avalie Fácil durante todo o período abrangido pelo
                  plano de assinatura contratado pelo Usuário. Na hipótese de a
                  Fácil Sistemas não disponibilizar acesso pelo menos em 80%
                  (oitenta por cento) do tempo contratado, o Usuário terá
                  direito a prorrogação do acesso ao Avalie Fácil pelo período
                  comprovado de indisponibilidade do serviço, limitando está
                  prorrogação a prazo correspondente ao plano de assinatura
                  contratado, não podendo tal crédito ser convertido em
                  dinheiro. Após a disponibilização pela Fácil Sistemas do
                  crédito referente à reivindicação de indisponibilidade, o
                  período será considerado 100% (cem por cento) disponível para
                  efeito de futuras reivindicações. Não serão consideradas
                  indisponibilidades, nos termos deste instrumento: <br />
                  a) Manutenções programadas, informadas aos Usuários; <br />
                  b) Manutenções emergenciais que não ultrapassem 08 (oito)
                  horas de duração; <br />
                  c) Indisponibilidades decorrentes de quaisquer atos ou
                  omissões de Usuários ou de terceiros, que fujam ao controle da
                  Fácil Sistemas;
                  <br />
                  d) Falhas e problemas relacionados à conectividade da
                  Internet; <br />
                  e) Equipamentos, softwares e outras tecnologias que por sua
                  falha impeçam o acesso do Usuário ao Avalie Fácil e que não
                  estejam na esfera de responsabilidade da Fácil Sistemas;{' '}
                  <br />
                  f) Falhas de instâncias individuais não relacionadas com
                  indisponibilidade do serviço, mas sim com a instância do
                  Usuário.{' '}
                </p>
                <p>
                  <b>5. ISENÇÕES DE GARANTIAS E DE RESPONSABILIDADE:</b> <br />
                  <br />
                  Pelo estágio tecnológico, não é possível à Fácil Sistemas
                  garantir que o Avalie Fácil não apresentará erros,
                  incorreções, vícios ou defeitos, ou que a operação ocorrerá de
                  forma ininterrupta. Por ter sido, o Avalie Fácil, desenvolvido
                  para atender as mais diversas demandas dos mais diversos
                  usuários, a Fácil Sistemas não garante que o mesmo atenda a
                  uma necessidade específica do Usuário. A Fácil Sistemas não
                  garante a compatibilidade e funcionamento do Avalie Fácil em
                  conjunto com qualquer software ou plataforma desenvolvidos ou
                  fornecidos por terceiros. A Fácil Sistemas não será, em
                  hipótese alguma, responsável por quaisquer danos decorrentes
                  da interrupção do acesso ao Avalie Fácil ou aos Serviços, ou
                  por falhas em seu funcionamento. Do mesmo modo, a Fácil
                  Sistemas não se responsabiliza pela defraudação da utilidade
                  que os Usuários possam ter atribuído ao Avalie Fácil ou aos
                  Serviços, pela falibilidade dos mesmos, nem por qualquer
                  dificuldade de acesso. A Fácil Sistemas se isenta
                  expressamente de qualquer responsabilidade por indenizações,
                  perdas e danos, lucros cessantes, prejuízos de qualquer
                  natureza ou sob quaisquer outros danos diretos, porventura
                  causado ao Usuário ou a terceiros, decorrentes da má
                  utilização do Avalie Fácil pelo Usuário. A Fácil Sistemas
                  utiliza as melhores práticas recomendadas de mercado para
                  manter seguros todos os dados inseridos pelo Usuário no Avalie
                  Fácil e nos Serviços. No entanto, a Fácil Sistemas se exime de
                  responsabilidade pelos danos e prejuízos de toda natureza que
                  possam decorrer do conhecimento que terceiros não autorizados
                  possam ter de quaisquer das informações repassadas, em
                  decorrência de falha exclusivamente atribuível aos Usuários. A
                  Fácil Sistemas não se responsabiliza por eventuais falhas de
                  funcionamento ou inoperância dos equipamentos em virtude de
                  conflitos entre o Avalie Fácil e outros programas instalados
                  ou em processamento no mesmo computador ou servidor em que o
                  Avalie Fácil é utilizado. A Fácil Sistemas não se
                  responsabiliza por falhas no Avalie Fácil ou na prestação dos
                  Serviços, nem por informações não recebidas ou recebidas de
                  forma incompleta, de modo que não sejam corretamente
                  incorporadas ao Avalie Fácil ou aos Serviços, caso tais erros
                  decorram de problemas técnicos do Usuário. A Fácil Sistemas
                  poderá, sem anuência ou concordância do Usuário, realizar
                  quaisquer alterações que julgar necessárias no Avalie Fácil e
                  nos Serviços, sem que qualquer valor ou indenização seja
                  devido ao Usuário em razão disso. A Fácil Sistemas não será
                  responsabilizada por atos de omissão, ou ainda pelas
                  providências não tomadas tempestivamente por instituições e/ou
                  demais empresas que detenham a responsabilidade de
                  executá-las. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEGISLAÇÃO, O
                  VALOR TOTAL A QUE A FÁCIL SISTEMAS SE RESPONSABILIZA,
                  INDEPENDENTEMENTE DO MOTIVO QUE ORIGINOU O PEDIDO ESTÁ
                  LIMITADO AO MONTANTE PAGO PELO USUÁRIO À FÁCIL SISTEMAS, NOS
                  ÚLTIMOS 30 (TRINTA) DIAS, DESDE QUE INEQUIVOCAMENTE COMPROVADO
                  O PREJUÍZO ALEGADO.{' '}
                </p>
                <p>
                  <b>6. DISPOSIÇÕES GERAIS:</b> <br />
                  <br />
                  As alterações que, a critério da Fácil Sistemas, sejam
                  realizadas nestes Termos serão consideradas aplicáveis a
                  partir da data de veiculação do documento na página{' '}
                  <a
                    href="https://avaliefacil.com.br/termos-de-uso"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://avaliefacil.com.br/termos-de-uso
                  </a>{' '}
                  , sendo, portanto, de responsabilidade do Usuário, manter-se
                  atualizado com relação ao disposto neste documento. Se
                  qualquer parte deste Termo for considerada inválida ou
                  inexequível, tal trecho deve ser interpretado de forma
                  consistente com a lei aplicável, para refletir, na medida do
                  possível, a intenção original das partes, sendo que as demais
                  disposições permanecerão em pleno vigor e efeito. Qualquer
                  tolerância das partes com relação ao descumprimento de alguma
                  previsão destes Termos não implicará novação ou renúncia de
                  direitos, de modo que a parte tolerante poderá, a qualquer
                  momento, exigir da outra o fiel cumprimento das disposições
                  deste documento. Os presentes Termos obrigam as partes, seus
                  herdeiros e sucessores ao fiel cumprimento de todas as
                  condições ora aceitas. O presente acordo de vontades não
                  confere caráter exclusivo e não estabelece vínculo
                  empregatício entre as partes ou qualquer relação de
                  subordinação pessoal entre seus administradores, empregados,
                  prepostos e/ou terceiros sob a responsabilidade das partes.
                  Qualquer comunicação do Usuário à Fácil Sistemas será
                  considerada válida quando realizada por meio do e-mail
                  contato@avaliefacil.com.br. Toda comunicação da Fácil Sistemas
                  ao Usuário será considerada válida quando encaminhada por meio
                  de qualquer das informações de contato fornecidas quando do
                  cadastro do Usuário no Avalie Fácil. Os PTAMs terão validade
                  de 30 dias para edição contados a partir da data de sua
                  criação. Após 30 dias eles passarão ao status de expirados e
                  ficarão disponíveis apenas para download, se estiverem
                  concluídos. Para usuários com plano de assinatura e PTAMs
                  concluídos também ficará disponivel a opção de ´clonar´ os
                  PTAMs expirados.
                </p>
                <p>
                  <b>7. LEGISLAÇÃO E FORO:</b> <br />
                  <br />
                  Estes Termos serão regidos, interpretados e executados de
                  acordo com as leis da República Federativa do Brasil,
                  independentemente dos conflitos dessas leis com leis de outros
                  estados ou países, sendo competente o Foro da Comarca de
                  Paulínia, estado de São Paulo, no Brasil. O Usuário consente,
                  expressamente, com a competência desse juízo, e renúncia,
                  neste ato, à competência de qualquer outro foro, por mais
                  privilegiado que seja ou venha a ser.{' '}
                </p>
                <br />
                <h4 style={{ color: '#000' }}>Política de Privacidade</h4>
                <br />
                <p>
                  Para que os Usuários do Avalie Fácil possam aproveitar das
                  funcionalidades do sistema com alto índice de satisfação e
                  tenham uma boa experiência, alguns dados pessoais entre outras
                  informações são coletados e armazenados pela Fácil Sistemas
                  por ocasião de seu cadastramento, bem como a cada novo acesso.
                </p>
                <p>
                  Importante esclarecer que a Fácil Sistemas, ao coletar os
                  dados pessoais e outras informações de seus Usuários, observa
                  estritamente os termos da Lei nº 13.709/2018 (“Lei Geral de
                  Proteção de Dados”).
                </p>
                <p>
                  Para fins de cadastramento, os seguintes dados pessoais do
                  Usuário serão coletados e armazenados: <br /> <br /> • Nome
                  Completo <br /> • CPF/ME <br /> • E-mail <br /> • Número de
                  Celular <br /> • Endereço <br /> • Formação Profissional{' '}
                  <br /> • Número do CRECI/CNAI/CAU/CREA <br /> <br />
                  Além disso, a Fácil Sistemas poderá coletar algumas
                  informações automaticamente, a cada acesso do Usuário ao
                  Avalie Fácil e aos Serviços, incluindo características do
                  dispositivo utilizado, do navegador, número do IP (com data e
                  hora) e origem do IP.
                </p>
                <p>
                  Os dados pessoais, além das informações coletados serão
                  utilizados para prestação dos serviços fornecidos por meio do
                  Avalie Fácil e dos Serviços conexos, bem como para operar,
                  manter, prover e aprimorar suas funcionalidades, no intuito de
                  melhorar a experiência do Usuário e personalizar o software e
                  os Serviços oferecidos.
                </p>
                <p>
                  Além disso, os dados pessoais do Usuário serão divulgados no
                  site do Avalie Fácil (www.avaliefacil.com.br), no menu
                  “Encontre um Avaliador”, possibilitando que terceiros façam
                  uma pesquisa de avaliadores cadastrados em sua região. Nessa
                  hipótese, exclusivamente, a qualquer momento o usuário poderá
                  suspender a divulgação de seus dados no site do Avalie Fácil,
                  bastando desabilitar essa opção em seu perfil.
                </p>
                <p>
                  Desse modo, ao aceitar eletronicamente com os termos da
                  presente Política de Privacidade, o Usuário dá o consentimento
                  livre, expresso para tratamento de seus dados pessoais e
                  demais informações, nos termos da Lei 13.709/2018 ( “Lei Geral
                  de Proteção de Dados”), para as seguintes finalidades: <br />{' '}
                  <br /> (i) gerar os laudos/ pareceres técnicos do PTAM; <br />{' '}
                  (ii) recebimento de conteúdo de marketing do Avalie Fácil,
                  inclusive, novidades, promoções, pesquisa de satisfação;{' '}
                  <br />
                  (iii) necessidade de compartilhamento dos dados com parceiros,
                  na medida em que forem necessárias para a adequada prestação
                  dos serviços objeto das atividades da Fácil Sistemas; <br />{' '}
                  (iv) para proteção de interesses da Fácil Sistemas em qualquer
                  tipo de conflito; <br /> (v) no caso de transações e
                  alterações societárias envolvendo a Fácil Sistemas, hipótese
                  em que a transferência das informações será necessária para a
                  continuidade dos serviços; e <br /> (vi) mediante ordem
                  judicial ou pelo requerimento de autoridades administrativas
                  que detenham competência legal para sua requisição.
                </p>
                <p>
                  Os dados pessoais e demais informações são armazenados e
                  utilizados pelo tempo necessário para atingir as finalidades
                  elencadas nesta Política de Privacidade. Uma vez expirados os
                  períodos de armazenamento dos dados pessoais e demais
                  informações, eles são removidos de nossas bases de dados ou
                  anonimizados, salvo nos casos em que houver a possibilidade ou
                  a necessidade de armazenamento em virtude de disposição legal
                  ou regulatória.
                </p>
                <p>
                  De acordo com a Lei 13.709/2018 ( “Lei Geral de Proteção de
                  Dados”), o Usuário do Avalie Fácil possui os seguintes
                  direitos: <br /> <br /> • confirmação da existência de
                  tratamento; <br /> • correção de dados incompletos, inexatos
                  ou desatualizados; <br /> • anonimização, bloqueio ou
                  eliminação de dados desnecessários, excessivos ou tratados em
                  desconformidade com o disposto na lei; <br /> • portabilidade
                  dos dados a outro fornecedor de serviço ou produto, mediante
                  requisição expressa, de acordo com a regulamentação da
                  autoridade nacional, observados os segredos comercial e
                  industrial; <br /> • eliminação dos dados pessoais tratados
                  com o consentimento do titular, exceto nos casos previstos em
                  lei; <br /> • informação das entidades públicas e privadas com
                  as quais o controlador realizou uso compartilhado de dados;{' '}
                  <br /> • informação sobre a possibilidade de não fornecer
                  consentimento e sobre as consequências da negativa; <br /> •
                  revogação do consentimento, nos casos em que o tratamento de
                  seus dados pessoais é realizado com base no consentimento.
                  Nessa hipótese os dados pessoais poderão ainda ser tratados
                  com base em outras hipóteses legais.
                </p>
                <p>
                  Lembrando que o exercício de qualquer desses direitos não
                  implicará na ilegalidade de qualquer tratamento de dados
                  realizado anteriormente a esta opção.
                </p>
                <p>
                  Nos reservamos o direito de modificar, a qualquer momento, a
                  presente Política de Privacidade, especialmente para
                  adaptá-las às eventuais alterações feitas em nosso site, seja
                  pela disponibilização de novas funcionalidades ou pela
                  modificação daquelas já existentes.
                </p>
                <p>
                  Sempre que houver uma modificação, nossos usuários serão
                  notificados acerca da mudança.
                </p>
                <p>
                  Para esclarecer quaisquer dúvidas sobre esta Política de
                  Privacidade ou para exercer qualquer dos direitos acima
                  expostos, encaminhe sua solicitação para o e-mail:
                  contato@avaliefacil.com.br
                </p>
                <br />
                <br />
                <strong>
                  Termos de Uso e Política de Privacidade atualizados em:
                  28/10/2020
                </strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleTermo(2)}
                color="primary"
                style={{ outline: 'none' }}
              >
                Recusar
              </Button>
              <Button
                onClick={() => handleTermo(1)}
                color="primary"
                style={{ outline: 'none' }}
              >
                Aceitar
              </Button>
            </DialogActions>
          </Dialog>
          <img src={logo} alt="Avalie Fácil" />
          <Form schema={schema} onSubmit={handleSubmit}>
            <h3>Crie sua conta</h3>
            <Input
              id="cpf"
              name="cpf"
              type="text"
              placeholder="Digite seu CPF"
              onBlur={e =>
                validacaoCpf(
                  e.target.value.replace(/\./g, '').replace(/\-/g, '')
                )
              }
              required
            />

            <Input
              name="name"
              type="text"
              placeholder="Digite seu nome completo"
              value={nome}
              required
              onChange={e => setNome(e.target.value)}
              style={{ marginTop: '10px' }}
            />

            <div className="group-column">
              <MaskedInput
                required
                id="celular"
                type="text"
                placeholder="Digite seu celular com DDD"
                value={mobile}
                onChange={e => setMobile(e.target.value)}
                mask={[
                  '(',
                  /[0-9]/,
                  /[0-9]/,
                  ')',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  '-',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                style={{ marginTop: '10px', marginRight: '5px' }}
              />
              <a href="#" onClick={() => setOpenMobile(true)} tabIndex="-1">
                Porque precisamos do seu celular?
              </a>
            </div>

            {/* <div className="group-column">
                <Input
                  name="creci"
                  type="number"
                  placeholder="Digite seu CRECI"
                  required
                  style={{ marginTop: '10px', marginLeft: '5px' }}
                />
                <a href="#" onClick={() => setOpenCreci(true)} tabIndex="-1">
                  Porque precisamos do seu CRECI?
                </a>
              </div> */}

            <Input
              name="email"
              type="email"
              placeholder="Digite seu e-mail"
              required
            />

            <div className="group">
              <div className="group-column">
                <Input
                  name="password"
                  type="password"
                  placeholder="Digite sua Senha"
                  required
                  style={{ marginTop: '20px', marginRight: '5px' }}
                />
              </div>
              <div className="group-column">
                <Input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirme sua Senha"
                  required
                  style={{ marginTop: '20px', marginLeft: '5px' }}
                />
              </div>
            </div>

            <div align="center" style={{ marginTop: '16px' }}>
              <ReCAPTCHA
                sitekey="6Ldci9MUAAAAADjSWcQSB7fh_uD72kvcOTNZfHQH"
                onChange={handleCaptcha}
              />
            </div>

            <div className="Container">
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      color="primary"
                      onChange={() => setTermo(true)}
                    />
                  }
                  label={label}
                />
              </div>
            </div>
            <button type="submit">Cadastrar</button>
            <Link to="/">Já Possuo uma Conta</Link>
          </Form>
        </AnimatedContainer>
      </Content>
    </Container>
  );
}
