/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO, isAfter } from 'date-fns';

import LogoBranco from '../../assets/logobranco.png';
import pagarmeLogo from '../../assets/pagarme.svg';
import Loader from '../../components/Loader';
import api from '../../services/api';

import {
  Container,
  Header,
  Plans,
  Card,
  PlanDescription,
  PtamValues,
  Payments,
  PaymentOptions,
  PaymentItem,
} from './styles';

const Signatures = () => {
  const user = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [payments, setPayments] = useState([]);

  const loadData = async () => {
    const response = await api.get('/signatures');

    const newSignatures = response.data.map(sig => ({
      ...sig,
      vlr_liq: parseFloat(
        (sig.valor_total * (1 - sig.desconto / 100)).toFixed(1)
      ),
    }));

    setSignatures(newSignatures);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    loadData();
  }, []);

  const loadPayments = async () => {
    const response = await api.get(`/transactions/${user.cpf}`);

    setPayments(response.data);
  };

  useEffect(() => {
    loadPayments();
  }, []);

  return (
    <Container>
      {loading && <Loader />}

      <Header>
        <h2>Escolha o melhor plano que se encaixa a você!</h2>
      </Header>

      <Plans>
        {signatures.map(signature => (
          <Card key={signature.sku}>
            <div className="card_side card_side--front">
              <h2 style={{ textAlign: 'center' }}>{signature.titulo}</h2>

              <hr />

              <PlanDescription type>
                <p>{signature.descricao}</p>

                <PtamValues>
                  {signature.desconto !== 0 ? (
                    <h3>Desconto {signature.desconto}%</h3>
                  ) : null}

                  <small>Valor Total</small>

                  <strong>
                    {signature.vlr_liq.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </strong>

                  {signature.parcelas > 1 ? (
                    <small>
                      Em até {signature.parcelas} parcelas de{' '}
                      {signature.vlr_parcela.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </small>
                  ) : (
                    <span>Pagamento á vista</span>
                  )}
                </PtamValues>
              </PlanDescription>
            </div>

            <div className="card_side card_side--back">
              <img src={LogoBranco} alt="Logo do avalie fácil" />

              <hr style={{ backgroundColor: '#fff' }} />

              <PlanDescription type={false}>
                <h2>{signature.titulo}</h2>
                <PaymentOptions>
                  {isAfter(new Date(), parseISO(user.expire_at)) ? (
                    <a href={signature.botao} target="_blanck">
                      <img src={pagarmeLogo} alt="Logo de Pagamento" />
                      Escolha como pagar
                    </a>
                  ) : (
                    <>
                      <h1>
                        {isAfter(parseISO(user.expire_at), new Date())
                          ? 'Assinatura ativa'
                          : ' Assinatura expirada'}
                      </h1>
                      <span>
                        Assinatura válida até:{' '}
                        {format(parseISO(user.expire_at), 'dd/MM/yyyy')}
                      </span>
                      <br />
                      <span style={{ textAlign: 'center' }}>
                        Aguarde a expiração do plano para aquisição de um novo.
                      </span>
                    </>
                  )}
                </PaymentOptions>
              </PlanDescription>
            </div>
          </Card>
        ))}
      </Plans>

      <Payments>
        <h1>Suas Transações</h1>

        <ul>
          {payments.map(payment => (
            <PaymentItem key={payment.id}>
              <label htmlFor="orderNumber">
                Nº pedido
                <p id="orderNumber">{payment.order_number}</p>
              </label>

              <label htmlFor="purchaseDate">
                Data da Compra
                <p id="purchaseDate">{payment.created_date}</p>
              </label>

              <label htmlFor="value">
                Valor
                <p id="value">
                  {payment.amount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              </label>

              <label htmlFor="planType">
                Tipo do Plano
                <p id="planType">{payment.product_sku}</p>
              </label>

              <label htmlFor="description">
                Descrição
                <p id="description">{payment.product_description}</p>
              </label>

              <label htmlFor="status">
                Status
                <p id="status">
                  {payment.payment_status === 1
                    ? 'Pendente'
                    : payment.payment_status === 2
                    ? 'Pago'
                    : payment.payment_status === 3
                    ? 'Negado'
                    : payment.payment_status === 4
                    ? 'Expirado'
                    : payment.payment_status === 5
                    ? 'Cancelado'
                    : payment.payment_status === 6
                    ? 'Não Finalizado'
                    : payment.payment_status === 7
                    ? 'Autorizado (Aguarde)'
                    : payment.payment_status === 8
                    ? 'Chargeback'
                    : 'Não Identificado'}
                </p>
              </label>
            </PaymentItem>
          ))}
        </ul>
      </Payments>
    </Container>
  );
};

export default Signatures;
