import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import avalieBackgroundSignUp from '../../assets/avalieBackgroundSignUp.jpg';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  background: #fff;
  outline: none;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  @media only screen and (max-width: 37.5em) {
    width: 90%;
    font-size: 1em;
  }

  animation: ${appearFromRight} 1s;

  img {
    width: 250px;

    @media only screen and (max-width: 37.5em) {
      width: 200px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    align-items: center;

    h3 {
      color: #333;
      margin: 16px 0;
      font-size: 18px;
    }

    input {
      width: 100%;
      color: #0d2c5b;

      &::placeholder {
        color: #2e2e2e;
      }
    }

    span {
      display: flex;
      align-items: center;

      margin: 4px 0;
      font-size: 14px;
      color: #333;

      svg {
        margin-right: 8px;
      }
    }

    a {
      color: #333;
      display: block;
      margin: 10px 0;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fa923f')};
      }
    }

    .group {
      display: flex;
      align-items: center;

      width: 100%;
    }

    .group-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;

      a {
        color: #333;
        display: block;
        text-decoration: none;
        transition: color 0.2s;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: ${shade(0.2, '#fa923f')};
        }
      }
    }

    @media only screen and (max-width: 37.5em) {
      font-size: 1em;

      input {
        width: 100%;

        &::placeholder {
          font-size: 0.7em;
        }
      }

      a {
        margin: 0 8px;
      }
    }
  }

  > a {
    color: #fa923f;
    display: block;

    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#fa923f')};
    }
  }

  button {
    margin-top: 4px;
    width: 90%;
    height: 40px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${avalieBackgroundSignUp}) no-repeat center;
  background-size: cover;
`;
