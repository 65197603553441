import React from 'react';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Container } from './styles';

export default function ImageView({ file, setView, view }) {
  const img = `data:image/jpeg;base64,${file.base64}` || '';

  return (
    <Container>
      <div>
        <Dialog
          open={view}
          onClose={() => setView(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Imagem</DialogTitle>
          <DialogContent>
            <img src={img} alt="" width="100%" height="100%" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setView(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

ImageView.propTypes = {
  file: PropTypes.objectOf(PropTypes.object).isRequired,
  setView: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
};
