/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';

import apiCep from '../../../../services/cep';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setOpen,
  view,
  edit,
  requester,
  setRequester,
  setRefresh,
}) {
  const [cpf_cnpj, setCPF] = useState(requester.tipo);

  async function buscaCep(e) {
    const CEPvalue = e.target.value;

    const response = await apiCep.get(`${CEPvalue}/json`);

    setRequester({
      ...requester,
      cep: CEPvalue,
      endereco: response.data.logradouro,
      bairro: response.data.bairro,
      estado: response.data.uf,
      cidade: response.data.localidade,
      complemento: response.data.complemento,
    });
  }

  async function handleSave() {
    if (!requester.nome) {
      toast.error('O nome do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.tipo) {
      toast.error('O tipo do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.documento) {
      toast.error('O documento do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.cep) {
      toast.error('O CEP do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.endereco) {
      toast.error('O endereço do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.numero) {
      toast.error('O numero do imóvel do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.estado) {
      toast.error('O estado do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.cidade) {
      toast.error('A cidade do solicitante precisa ser preenchido !');
      return;
    }

    if (!requester.bairro) {
      toast.error('O bairro do solicitante precisa ser preenchido !');
      return;
    }

    try {
      if (edit) {
        await api.put('requesters', requester);
        toast.success('Solicitante atualizado com sucesso!');
      } else {
        await api.post('requesters', requester);
        toast.success('Solicitante criado com sucesso!');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOpen(false);
      setRefresh(true);
    }
  }

  function handleCPF(e) {
    if (e === 'Pessoa Física') {
      setCPF('Pessoa Física');
    } else if (e === 'Advogado') {
      setCPF('Pessoa Física');
    } else {
      setCPF('Pessoa Jurídica');
    }
    setRequester({ ...requester, tipo: e, documento: '' });
  }

  return (
    <Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          Dados do Solicitante &nbsp;&nbsp;
          <HtmlTooltip
            interactive
            title={
              <>
                <Typography color="inherit">
                  Ajuda com preenchimento desta tela?
                </Typography>
                <em>Veja o vídeo de </em>{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtu.be/DmxiH96aAtc"
                >
                  como preencher
                </a>
                <em> corretamente os campos desta tela!</em>{' '}
              </>
            }
          >
            <HelpOutlineIcon />
          </HtmlTooltip>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para gravação ou edição dos dados do
            Solicitante da avaliação. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
            OBRIGATÓRIOS !!
          </DialogContentText>
          <Form>
            <div className="side1">
              <h1>Dados do Solicitante</h1>
              <label htmlFor="nome">
                Nome
                <input
                  id="nome"
                  type="text"
                  value={requester.nome}
                  onChange={e =>
                    setRequester({ ...requester, nome: e.target.value })
                  }
                  required
                />
              </label>

              <label htmlFor="tipoSolicitante">
                Tipo do Solicitante
                <select
                  id="tipoSolicitante"
                  select
                  value={requester.tipo}
                  onChange={e => handleCPF(e.target.value)}
                  required
                >
                  <option value="" />
                  <option value="Pessoa Física">Pessoa Física</option>
                  <option value="Pessoa Jurídica">Pessoa Jurídica</option>
                  <option value="Advogado">Advogado</option>
                </select>
              </label>

              <label htmlFor="documento">
                Nº do Documento
                <MaskedInput
                  id="documento"
                  type="text"
                  value={requester.documento}
                  onChange={e =>
                    setRequester({ ...requester, documento: e.target.value })
                  }
                  required
                  mask={
                    requester.tipo === 'Pessoa Jurídica'
                      ? [
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                        ]
                      : [
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                        ]
                  }
                />
              </label>

              <label htmlFor="email">
                E-mail
                <input
                  id="email"
                  type="email"
                  value={requester.email}
                  onChange={e =>
                    setRequester({ ...requester, email: e.target.value })
                  }
                />
              </label>

              <label htmlFor="oab">
                Nº da OAB
                <input
                  id="oab"
                  type="text"
                  value={requester.oab}
                  onChange={e =>
                    setRequester({ ...requester, oab: e.target.value })
                  }
                />
              </label>

              <label htmlFor="seccional">
                Seccional
                <input
                  id="seccional"
                  type="text"
                  value={requester.seccional}
                  onChange={e =>
                    setRequester({ ...requester, seccional: e.target.value })
                  }
                />
              </label>

              <label htmlFor="inventariado">
                Nome do Inventariado
                <input
                  id="inventariado"
                  type="text"
                  value={requester.inventariado}
                  onChange={e =>
                    setRequester({ ...requester, inventariado: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="middle" />
            <div className="side2">
              <h1>Endereço do Solicitante</h1>
              <label htmlFor="cep">
                Digite o CEP
                <MaskedInput
                  required
                  id="cep"
                  value={requester.cep}
                  onChange={e =>
                    setRequester({ ...requester, cep: e.target.value })
                  }
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '-',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  onBlur={e => buscaCep(e)}
                />
              </label>

              <label htmlFor="endereco">
                Endereço
                <input
                  required
                  id="endereco"
                  type="text"
                  value={requester.endereco}
                  onChange={e =>
                    setRequester({ ...requester, endereco: e.target.value })
                  }
                />
              </label>

              <label htmlFor="numero">
                Nº do imóvel
                <input
                  required
                  id="numero"
                  type="text"
                  value={requester.numero}
                  onChange={e =>
                    setRequester({ ...requester, numero: e.target.value })
                  }
                />
              </label>

              <label htmlFor="complemento">
                Complemento
                <input
                  id="complemento"
                  type="text"
                  value={requester.complemento}
                  onChange={e =>
                    setRequester({ ...requester, complemento: e.target.value })
                  }
                />
              </label>

              <label htmlFor="bairro">
                Bairro
                <input
                  required
                  id="bairro"
                  type="text"
                  value={requester.bairro}
                  onChange={e =>
                    setRequester({ ...requester, bairro: e.target.value })
                  }
                />
              </label>

              <label htmlFor="estado">
                Estado
                <select
                  required
                  value={requester.estado}
                  onChange={e =>
                    setRequester({ ...requester, estado: e.target.value })
                  }
                >
                  <option value="" />
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernanbuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                  <option value="**">Exterior</option>
                </select>
              </label>

              <label htmlFor="cidade">
                Cidade
                <input
                  required
                  id="cidade"
                  type="text"
                  value={requester.cidade}
                  onChange={e =>
                    setRequester({ ...requester, cidade: e.target.value })
                  }
                />
              </label>
            </div>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleSave()} color="primary" disabled={view}>
            {view ? '' : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setOpen: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  requester: PropTypes.objectOf(PropTypes.object),
  setRequester: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

Handle.defaultProps = {
  requester: null,
};
