import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-top: 10px;
    color: #fff;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  margin: 5px;
  color: #fff;

  hr {
    color: #fa923f;
    background-color: #fa923f;
    height: 1px;
    width: 100%;
    border-radius: 4px;
  }
`;

export const PlanList = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;

  hr {
    color: #fa923f;
    background-color: #fa923f;
    height: 1px;
    border-radius: 4px;
  }

  li {
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
    width: 95%;
    height: 350px;
    flex-direction: column;
    border: 1px solid #fa923f;
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);

    div {
      height: 95%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 20px;
        color: #fa923f;
        font-weight: bold;
      }

      strong {
        margin-top: 20px;
        color: #fa923f;
      }

      span {
        margin-top: 15px;
        color: #fa923f;
      }

      .titulo {
        height: 15%;
        background: #0d2c5b;
        color: #fff;
        padding: 10px;
        border-radius: 4px;

        h1 {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }
      }

      .valor {
        height: 20%;
        font-size: 26px;
      }

      .detail {
        font-size: 11px;
        color: #fa923f;
      }

      .descri {
        height: 15%;
        font-size: 18px;
        color: #fa923f;
        margin-bottom: 10px;
      }

      .vista {
        height: 15%;
        font-size: 14px;
        color: #fa923f;
      }

      .btn-cielo {
        * {
          border: 0;
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          outline: none;
        }
        input {
          max-width: 100%;
        }
      }
    }
  }
`;

export const TermsDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #fa923f;
    margin-bottom: 5px;
  }
`;
