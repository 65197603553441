import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Loader from '../../../components/Loader';
import api from '../../../services/api';

import { Container, Content, Close } from './styles';

const SendRequest = ({ setSendRequest }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      await api.post('/validation/retry', {
        email,
      });

      setLoading(false);

      toast.success('Foi enviado um token para seu email e celular');

      setSendRequest(false);
    } catch (err) {
      toast.error('token não enviado, verifique seus dados');
      setLoading(false);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      <Content>
        <Close>
          <button type="button" onClick={() => setSendRequest(false)}>
            <FiX size={22} color="#c53030" />
          </button>
        </Close>
        <Form>
          <h1>Digite seu e-mail cadastrado para reenvio do token</h1>
          <input
            name="email"
            type="text"
            placeholder="Digite seu email"
            onChange={e => setEmail(e.target.value)}
          />

          <button type="button" onClick={() => handleSubmit()}>
            Reenviar
          </button>
        </Form>
      </Content>
    </Container>
  );
};

export default SendRequest;

SendRequest.propTypes = {
  setSendRequest: PropTypes.func.isRequired,
};
