import produce from 'immer';

const INITIAL_STATE = {
  offer: null,
};

export default function offer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@oferta/OFERTA_SUCCESS': {
        draft.offer = action.payload.oferta;
        break;
      }

      case '@oferta/OFERTA_LOAD_SUCCESS': {
        draft.offer = action.payload.offer;
        break;
      }

      case '@amostra/AMOSTRAS_REQUEST': {
        draft.offer = null;
        break;
      }

      case '@ptam/PTAM_CLEAR': {
        draft.offer = null;
        break;
      }

      case '@oferta/OFERTA_DELETE': {
        draft.offer = null;
        break;
      }

      default:
    }
  });
}
