import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DatePicker from 'react-datepicker';

import details from '../../../../../assets/estados_de_conservacao.pdf';

import api from '../../../../../services/api';
import { edificacaoSuccess } from '../../../../../store/modules/edificacao/actions';

import { Container, Form } from './styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  edificacao,
  setEdificacao,
  setEdit,
}) {
  const dispatch = useDispatch();
  const [dataFim, setDataFim] = useState(null);

  useEffect(() => {
    if (dataFim) {
      const dateConverted = new Date(format(dataFim, 'MM/dd/yyyy'));
      setEdificacao({
        ...edificacao,
        data_fim_contrato: dateConverted.toISOString(),
      });
    }
  }, [dataFim]);

  async function handleSave() {
    try {
      const response = edit
        ? await api.put('/buildings', edificacao)
        : await api.post('/buildings', edificacao);

      const { id } = response.data;

      dispatch(edificacaoSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setModal(false);
    setEdit(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados da Edificação de um Imóvel &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a target="_blank" href="https://youtu.be/PniamusAZCo">
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados da
              Edificação do imóvel.
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="padrao">
                  Padrão Construtivo
                  <select
                    id="padrao"
                    label="Padrão Construtivo"
                    value={edificacao.padrao}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        padrao: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Baixo">Baixo</option>
                    <option value="Medio">Médio</option>
                    <option value="Alto">Alto</option>
                  </select>
                </label>

                <label htmlFor="estado_conservacao">
                  <a href={details} target="_blank" rel="noreferrer">
                    Estado de Conservação (clique p/ detalhes)
                  </a>
                  <select
                    id="estado_conservacao"
                    label="Estado de Conservação"
                    value={edificacao.estado_conservacao}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        estado_conservacao: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="a">(A) Nova</option>
                    <option value="b">(B) Entre nova e regular</option>
                    <option value="c">(C) Regular</option>
                    <option value="d">
                      (D) Entre regular e reparos simples
                    </option>
                    <option value="e">(E) Reparos simples</option>
                    <option value="f">
                      (F) Entre reparos simples e importantes
                    </option>
                    <option value="g">(G) Reparos importantes</option>
                    <option value="h">
                      (H) Entre reparos importantes e sem valor
                    </option>
                  </select>
                </label>

                <label htmlFor="orientacao">
                  Orientação
                  <select
                    id="orientacao"
                    label="Orientação"
                    value={edificacao.orientacao}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        orientacao: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Nascente">Nascente</option>
                    <option value="Poente">Poente</option>
                    <option value="Outros">Outros</option>
                  </select>
                </label>

                <label HtmFor="orientacao_outros">
                  Orientação Outras (se houver)
                  <input
                    id="orientacao_outros"
                    label="Outros se houver"
                    type="text"
                    value={edificacao.orientacao_outras}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        orientacao_outras: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="edificacao">
                  Edificação no Terreno
                  <select
                    id="edificacao"
                    label="Edificação no Terreno"
                    value={edificacao.edificacao}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        edificacao: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Centro">Centro</option>
                    <option value="Frente">Frente</option>
                    <option value="Fundos">Fundos</option>
                    <option value="Lateral">Lateral</option>
                    <option value="Esquerda">Esquerda</option>
                    <option value="Direita">Direita</option>
                    <option value="Outros">Outros (Especificar abaixo)</option>
                  </select>
                </label>

                <label HtmFor="edificacao_outros">
                  Edificação Outros (se houver)
                  <input
                    id="edificacao_outros"
                    label="Outros se houver"
                    type="text"
                    value={edificacao.edificacao_outros}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        edificacao_outros: e.target.value,
                      })
                    }
                  />
                </label>

                <label HtmFor="pavimentos">
                  Nº de Pavimentos (quantos andares)
                  <input
                    id="pavimentos"
                    label="Numero de Pavimentos"
                    type="number"
                    value={edificacao.pavimentos}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        pavimentos: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="andar">
                  Nº do Andar (andar do imóvel)
                  <input
                    id="andar"
                    label="Numero de Andares"
                    type="number"
                    value={edificacao.andar}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        andar: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="elevador">
                  Possui Elevador(es)
                  <select
                    id="elevador"
                    label="Possui Elevador(es)"
                    value={edificacao.elevador}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        elevador: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="numero_elevador">
                  Numero de Elevadores
                  <input
                    id="numero_elevador"
                    label="Numero de Elevadores"
                    type="number"
                    value={edificacao.numero_elevador}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        numero_elevador: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="estacionamento_coberto">
                  Estacionamento Coberto
                  <select
                    id="estacionamento_coberto"
                    label="Estacionamento Coberto"
                    value={edificacao.estacionamento_coberto}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        estacionamento_coberto: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="numero_vagas_coberto">
                  Numero de Vagas (Cobertas)
                  <input
                    id="numero_vagas_coberto"
                    label="Numero de Vagas (Cobertas)"
                    type="number"
                    value={edificacao.numero_vagas_coberto}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        numero_vagas_coberto: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="estacionamento_descoberto">
                  Estacionamento Descoberto
                  <select
                    id="estacionamento_descoberto"
                    label="Estacionamento Descoberto"
                    value={edificacao.estacionamento_descoberto}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        estacionamento_descoberto: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="numero_vagas_descoberto">
                  Numero de Vagas (Descobertas)
                  <input
                    id="numero_vagas_descoberto"
                    label="Numero de Vagas (Descobertas)"
                    type="number"
                    value={edificacao.numero_vagas_descoberto}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        numero_vagas_descoberto: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="playground">
                  Playground
                  <select
                    id="playground"
                    label="Playground"
                    value={edificacao.playground}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        playground: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="churrasqueira">
                  Churrasqueira
                  <select
                    id="churrasqueira"
                    label="Churrasqueira"
                    value={edificacao.churrasqueira}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        churrasqueira: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="piscina">
                  Piscina
                  <select
                    id="piscina"
                    label="Piscina"
                    value={edificacao.piscina}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        piscina: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="academia">
                  Academia
                  <select
                    id="academia"
                    label="Academia"
                    value={edificacao.academia}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        academia: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="clube">
                  Clube
                  <select
                    id="clube"
                    label="Clube"
                    value={edificacao.clube}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        clube: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="quadra">
                  Quadra
                  <select
                    id="quadra"
                    label="Quadra"
                    value={edificacao.quadra}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        quadra: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="sauna">
                  Sauna
                  <select
                    id="sauna"
                    label="Sauna"
                    value={edificacao.sauna}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        sauna: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="salao_festas">
                  Salão de Festas
                  <select
                    id="salao_festas"
                    label="Salão de Festas"
                    value={edificacao.salao_festas}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        salao_festas: e.target.value,
                      })
                    }
                  >
                    <option value>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </label>

                <label htmlFor="outras_caracteristicas">
                  Outras Características do Imóvel
                  <textarea
                    id="outras_caracteristicas"
                    label="Outras Características do Imóvel"
                    type="text"
                    value={edificacao.outras_caracteristicas}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        outras_caracteristicas: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="tipo_taxa_condominio">
                  Tipo da Taxa de Condomínio
                  <select
                    id="tipo_taxa_condominio"
                    label="Tipo da Taxa de Condomínio"
                    value={edificacao.tipo_taxa_condominio}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        tipo_taxa_condominio: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Mensal">Mensal</option>
                    <option value="Trimestral">Trimestral</option>
                    <option value="Semestral">Semestral</option>
                    <option value="Não Possui">Não Possui</option>
                  </select>
                </label>

                <label htmlFor="valor_condominio">
                  Valor do Condomínio
                  <NumberFormat
                    id="valor_condominio"
                    label="Valor do Condomínio"
                    value={edificacao.valor_condominio}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        valor_condominio: parseFloat(
                          e.target.value
                            .replace(/\./g, '')
                            .replace(',', '.')
                            .replace('R$ ', '')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="ocupacao_atual">
                  Ocupação Atual
                  <select
                    id="ocupacao_atual"
                    label="Ocupação Atual"
                    value={edificacao.ocupacao_atual}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        ocupacao_atual: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="Vazio">Vazio</option>
                    <option value="Proprietario">Proprietário</option>
                    <option value="Inquilino">Inquilino</option>
                    <option value="Emprestimo">Emprestimo</option>
                    <option value="Usufruto">Usufruto</option>
                    <option value="Outros">Outras (Especificar abaixo)</option>
                  </select>
                </label>

                <label htmlFor="ocupacao_outras">
                  Outras Ocupações (se houver)
                  <input
                    id="ocupacao_outras"
                    label="Outras se houver"
                    type="text"
                    value={edificacao.ocupacao_outras}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        ocupacao_outras: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="valor_aluguel">
                  Valor do Aluguel
                  <NumberFormat
                    id="valor_aluguel"
                    label="Valor do Aluguel"
                    value={edificacao.valor_aluguel}
                    onChange={e =>
                      setEdificacao({
                        ...edificacao,
                        valor_aluguel: parseFloat(
                          e.target.value
                            .replace(/\./g, '')
                            .replace(',', '.')
                            .replace('R$ ', '')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="data">
                  Data Fim do Contrato
                  <DatePicker
                    id="data"
                    showPopperArrow={false}
                    dateFormat="dd/MM/yyyy"
                    selected={dataFim}
                    onChange={date => setDataFim(date)}
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  edificacao: PropTypes.objectOf(PropTypes.object),
  setEdificacao: PropTypes.func,
  setEdit: PropTypes.func,
};

Handle.defaultProps = {
  edificacao: null,
  setEdificacao: null,
  setEdit: null,
};
