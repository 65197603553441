import React from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { FaInfoCircle } from 'react-icons/fa';

import { Abert, Header } from './styles';

export default function Abertura() {
  const ptam = useSelector(state => state.ptam.ptam);
  const profile = useSelector(state => state.user.profile);
  const date = format(parseISO(ptam.createdAt), 'dd/MM/yyyy', {
    locale: pt,
  });
  const hour = format(parseISO(ptam.createdAt), 'hh:mm', {
    locale: pt,
  });
  const expire = format(parseISO(ptam.expire_at), 'dd/MM/yyyy', {
    locale: pt,
  });
  return (
    <Abert>
      <div className="AbLd1">
        <Header>
          <div>
            <FaInfoCircle size={22} color="#fa923f" />
            <h1>Orientações diversas</h1>
          </div>
        </Header>
        <br />
        <br />
        <p>
          Para iniciar a inclusão das informações utilize o menu à esquerda da
          tela. Para cada etapa concluída utilize o botão gravar para incluir as
          informações. Caso necessite pausar a inclusão não se preocupe, seus
          dados estarão salvos até o ponto da ultima gravação.
        </p>
      </div>
      <div className="Middle" />
      <div className="AbLd2">
        <Header>
          <div>
            <FaInfoCircle size={22} color="#fa923f" />
            <h1>Informações sobre o PTAM</h1>
          </div>
        </Header>
        <ul>
          <br />
          <li>Código: {ptam.id}</li>
          <li>Data Criação: {date}</li>
          <li>Hora Criação: {hour}</li>
          <li>Expira em: {expire}</li>
          <li>Criado por: {profile.name}</li>
        </ul>
      </div>
    </Abert>
  );
}
