import styled from 'styled-components';

export const Container = styled.div`
  div {
    img {
      width: 10px;
      height: 10px;
      border: 0;
      border-radius: 4px;
    }
  }
`;
