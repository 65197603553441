import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  margin: 10px;

  .search {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    h1 {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 20px;
    }

    button {
      width: 180px;
      height: 40px;
      align-self: flex-end;
      background: #5781c0;
      border: 0;
      border-radius: 4px;
      transition: 0.2s;
      outline: none;

      &:hover {
        transform: translateY(-3px);
        background: ${darken(0.2, '#5781c0')};
      }

      div {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #fff;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
`;

export const BtnStyle = styled.div`
  display: flex;

  button {
    border: 0;
    background: transparent;
    margin-left: 20px;
    transition: 0.2s;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export const PageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;

  div {
    display: flex;
    justify-content: space-between;
    width: 400px;
  }

  button {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    width: 110px;
    height: 30px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: auto;
    transition: background 0.2s;

    &:hover {
      span {
        color: #fff;
      }
      transform: translateY(-3px);
    }

    &:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
`;
