import React, { useState } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Loader from '../Loader';

import api from '../../services/api';

import { Container, Form } from './styles';

export default function Users({
  open,
  setOpen,
  view,
  user,
  setRefresh,
  setUser,
  edit,
}) {
  const [loading, setLoading] = useState(false);

  async function handleUser() {
    if (edit) {
      try {
        const { id, cnai } = user;

        setLoading(true);

        await api.put(`/users/avalie/${id}`, {
          cnai,
        });

        setLoading(false);
        toast.success('CNAI atualizado com sucesso!');

        setRefresh(true);
        setOpen(false);
      } catch (err) {
        setLoading(false);
        toast.error('Erro na atualização do CNAI!');
      }
    } else {
      setRefresh(true);
      setOpen(false);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Dados do Usuario</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para edição dos dados de um usuario!
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="name">
                  Nome
                  <input id="name" type="text" value={user.name} disabled />
                </label>

                <label htmlFor="cpf">
                  CPF
                  <input id="cpf" type="text" value={user.cpf} disabled />
                </label>

                <label htmlFor="email">
                  Email
                  <input id="email" type="text" value={user.email} disabled />
                </label>

                <label htmlFor="expired">
                  Expirado
                  <input
                    id="expired"
                    type="text"
                    value={user.expired ? 'Sim' : 'Nao'}
                    disabled
                  />
                </label>

                <label htmlFor="expire_at">
                  Data Expiração Assinatura
                  <input
                    id="expire_at"
                    type="text"
                    value={`${user.expire_at.substring(
                      8,
                      10
                    )}/${user.expire_at.substring(
                      5,
                      7
                    )}/${user.expire_at.substring(0, 4)}`}
                    disabled
                  />
                </label>

                <label htmlFor="show">
                  Encontre um Avaliador
                  <input
                    id="show"
                    type="text"
                    value={user.show_data}
                    disabled
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="password_token">
                  Token Reset Senha
                  <input
                    id="password_token"
                    type="text"
                    value={user.password_reset_token}
                    disabled
                  />
                </label>

                <label htmlFor="password_token_expires">
                  Expiração Token Senha
                  <input
                    id="password_token_expires"
                    type="text"
                    value={
                      user.password_reset_expires
                        ? `${user.password_reset_expires.substring(
                            8,
                            10
                          )}/${user.password_reset_expires.substring(
                            5,
                            7
                          )}/${user.password_reset_expires.substring(
                            0,
                            4
                          )}-${user.password_reset_expires.substring(11, 19)}
                    `
                        : ''
                    }
                    disabled
                  />
                </label>

                <label htmlFor="valid_mail_token">
                  Token Validação Email
                  <input
                    id="valid_mail_token"
                    type="text"
                    value={user.valid_mail_token}
                    disabled
                  />
                </label>

                <label htmlFor="ptam_quantity">
                  Saldo de PTAM (grátis/unitários)
                  <input
                    id="ptam_quantity"
                    type="number"
                    value={user.ptam_quantity}
                    disabled
                  />
                </label>

                <label htmlFor="cnai">
                  CNAI
                  <input
                    id="cnai"
                    type="number"
                    value={user.cnai}
                    disabled={view}
                    onChange={e => setUser({ ...user, cnai: e.target.value })}
                  />
                </label>

                <label htmlFor="mobile">
                  Celular
                  <input id="mobile" type="text" value={user.mobile} disabled />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              {edit ? 'Cancelar' : 'Fechar'}
            </Button>
            {!view ? (
              <Button
                onClick={() => handleUser()}
                color="primary"
                disabled={view}
              >
                {edit ? 'Salvar' : 'Fechar'}
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Users.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.object),
  setRefresh: PropTypes.func,
  setUser: PropTypes.func.isRequired,
};

Users.defaultProps = {
  user: null,
  setRefresh: false,
};
