export function inputImageRequest(id, image) {
  return {
    type: '@user/INPUT_IMAGE_REQUEST',
    payload: { id, image },
  };
}

export function inputImageSuccess(image) {
  return {
    type: '@user/INPUT_IMAGE_SUCCESS',
    payload: { image },
  };
}

export function inputImageFailure() {
  return {
    type: '@user/INPUT_FAILURE',
  };
}
