import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 0 30px;
  display: block;
  top: 0;
`;

export const Content = styled.div`
  height: 65px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    flex: 1;
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;

      img {
        width: 150px;
        height: 35px;

        padding-right: 20px;
        border-right: 1px solid #eef;
      }

      a {
        margin: 0 30px;
        width: 150px;
      }
    }

    strong {
      color: #fff;
    }

    div {
      width: 100%;

      > a {
        margin-top: 10px;
        margin-left: 20px;
        font-weight: bold;
        color: #fa923f;
        text-decoration: none;

        &:hover {
          transform: translateY(-3px);
          color: #0d2c5b;
        }
      }

      button {
        outline: none;
        margin-left: 30px;
        background: transparent;
        border: 0.5px solid #fa923f;
        color: #fa923f;
        width: 220px;
        height: 35px;
        border-radius: 24px;
        transition: background 0.2s;
        box-shadow: 0 6px 6px -2px #00000029;

        strong {
          color: #fa923f;
        }

        &:hover {
          strong {
            color: #fff;
          }
          /* transform: translateY(-3px); */
          background: ${darken(0.02, '#fa923f')};
        }
      }
    }
  }

  aside {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      outline: none;
      margin-left: 30px;
      background: transparent;
      border: 0.5px solid #fa923f;
      color: #fa923f;
      width: 32px;
      height: 32px;
      font-size: 11px;
      border-radius: 50%;
      transition: background 0.2s;
      box-shadow: 0 6px 6px -2px #00000029;

      &:hover {
        color: #fff;
        background: ${darken(0.02, '#fa923f')};

        img {
          color: #fff;
        }
      }
    }
  }

  button {
    border: none;
    background: none;
    font-size: 16px;
    font-weight: bold;
    color: #fa923f;
  }
`;

export const AccessButton = styled.button`
  outline: none;
  margin-left: 30px;
  border: 0.5px solid #12a454 !important;
  color: #12a454 !important;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border-radius: 50%;
  transition: background 0.2s;
  box-shadow: 0 6px 6px -2px #00000029;

  > span {
    color: #12a454 !important;
  }

  &:hover {
    background: ${darken(0.02, 'rgba(18, 164, 84, 0.2)')} !important;
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #eef;

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: #333;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
      text-decoration: none;

      &:hover {
        transform: translateY(-3px);
        color: #333;
      }
    }
  }

  > img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 5px;
  }
`;
