import React from 'react';
import { FaCircle } from 'react-icons/fa';

import { Container } from './styles';

export default function Expired() {
  return (
    <>
      <Container>
        <FaCircle size={14} color="#FF0000" />
        <strong>Expirado</strong>
      </Container>
    </>
  );
}
