import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;

  a {
    outline: none;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
    }
  }

  .btnView {
    display: flex;

    button {
      padding: 0;
      border: none;
      background: none;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-size: 12px;
        color: #999;

        margin-left: 5px;
      }
    }

    input {
      width: 400px;
    }
  }
`;

export const ButtonDelete = styled.button`
  border: 0;
  background: transparent;
  color: #f54a4a;
  margin-left: 15px;
  cursor: pointer;
  outline: none;
`;

export const ButtonEdit = styled.button`
  border: 0;
  background: transparent;
  color: #0d2c5b;
  margin-left: 15px;
  cursor: pointer;
  outline: none;
`;

export const Preview = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
