import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MdDvr } from 'react-icons/md';
import { FaMarker, FaFastBackward, FaFileInvoice } from 'react-icons/fa';

import { FiHome, FiUser } from 'react-icons/fi';
import { GiMoneyStack } from 'react-icons/gi';

import history from '../../services/history';
import Assinaturas from './Assinaturas';
import Usuarios from './Usuarios';
import Main from './Main';
import Ross from './Ross';
import TpImovel from './TipoImovel';
import Transaction from './Transaction';

import { Container, MenuList } from './styles';

export default function Admin() {
  const profile = useSelector(state => state.user.profile);
  const [menu, setMenu] = useState('');

  const selectedMenu = useMemo(() => {
    switch (menu) {
      case 'home':
        return <Main />;

      case 'usuarios':
        return <Usuarios />;

      case 'assinaturas':
        return <Assinaturas />;

      case 'ross':
        return <Ross />;

      case 'tpimovel':
        return <TpImovel />;

      case 'transacoes':
        return <Transaction />;

      default:
        return profile.permission === 'administrador' ? <Main /> : <Usuarios />;
    }
  }, [menu]);

  function handleBack() {
    history.push('/dashboard');
  }

  return (
    <Container>
      <aside>
        <div>
          <MdDvr size={30} />
          <h2>Painel Administrativo</h2>
        </div>
        <hr />
        <div>
          <img
            src={
              profile.avatar
                ? `data:image/jpeg;base64,${profile.avatar.base64}`
                : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=${profile.name}`
            }
            alt=""
          />
          <div>
            <h1>Bem Vindo</h1>
            <span>{profile.name}</span>
          </div>
        </div>
        <hr />

        <MenuList>
          {profile.permission === 'administrador' ? (
            <>
              <li>
                <button type="button" onClick={() => setMenu('home')}>
                  <div>
                    <FiHome size={24} color="#fff" />
                    <span>Dashboard</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('usuarios')}>
                  <div>
                    <FiUser size={24} color="#fff" />
                    <span>Lista de Usuários</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('transacoes')}>
                  <div>
                    <GiMoneyStack size={24} color="#fff" />
                    <span>Transações</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('assinaturas')}>
                  <div>
                    <FaMarker size={24} color="#fff" />
                    <span>Assinaturas</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('ross')}>
                  <div>
                    <FaFileInvoice size={24} color="#fff" />
                    <span>Tabela Ross</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('tpimovel')}>
                  <div>
                    <FaFileInvoice size={24} color="#fff" />
                    <span>Tipo de Imóveis</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={handleBack}>
                  <div>
                    <FaFastBackward size={24} color="#fff" />
                    <span>Voltar para o Ptam</span>
                  </div>
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <button type="button" onClick={() => setMenu('usuarios')}>
                  <div>
                    <FiUser size={24} color="#fff" />
                    <span>Lista de Usuários</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={() => setMenu('transacoes')}>
                  <div>
                    <GiMoneyStack size={24} color="#fff" />
                    <span>Transações</span>
                  </div>
                </button>
              </li>

              <li>
                <button type="button" onClick={handleBack}>
                  <div>
                    <FaFastBackward size={24} color="#fff" />
                    <span>Voltar para o Ptam</span>
                  </div>
                </button>
              </li>
            </>
          )}
        </MenuList>
      </aside>

      <main>{selectedMenu}</main>
    </Container>
  );
}
