import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FaCalculator, FaSave, FaCheckCircle } from 'react-icons/fa';

import { MdCancel } from 'react-icons/md';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { FiDownloadCloud } from 'react-icons/fi';
import history from '../../../services/history';

import { ofertaRequest } from '../../../store/modules/oferta/actions';
import { amostrasChange } from '../../../store/modules/amostras/actions';
import { ptamStatus } from '../../../store/modules/ptam/actions';

import api from '../../../services/api';

import { Container, Header, Body, Texto } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 11,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // height: '200px',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function handleConvertCalc(value, percValue) {
  const valorConverted = parseFloat(
    value
      .replace(/\./g, '')
      .replace(',', '.')
      .replace('R$ ', '')
  );
  return valorConverted - valorConverted * percValue;
}

export default function Oferta({ setFlag }) {
  const classes = useStyles();

  const ptam = useSelector(state => state.ptam.ptam);
  const offer = useSelector(state => state.offer.offer);

  const dispatch = useDispatch();

  const [oferta, setOferta] = useState({
    id: offer ? offer.id : null,
    somatorio_valores: offer ? offer.somatorio_valores : 0,
    quantidade_imoveis: offer ? offer.quantidade_imoveis : 0,
    media: offer ? offer.media : 0,
    media_ajustada: offer ? offer.media_ajustada : 0,
    fator_oferta: offer ? offer.fator_oferta : 0,
    fator_ponderacao: offer ? offer.fator_ponderacao : 0,
    media_ponderada: offer ? offer.media_ponderada : 0,
    media_ponderada_menos: offer ? offer.media_ponderada_menos : 0,
    media_ponderada_mais: offer ? offer.media_ponderada_mais : 0,
    id_ptam: ptam.id,
  });

  const [samples, setSamples] = useState([]);
  const [open, setOpen] = useState(false);
  const [vlrFinal, setVlrFinal] = useState(0);
  const [ross_p, setRoss] = useState(0);
  const [area_total, setAreaTotal] = useState(0);
  const [erroArea, setErroArea] = useState(false);
  const [showError, setShowError] = useState(false);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [situation, setSituation] = useState({});
  const [area, setArea] = useState({});
  const [building, setBuilding] = useState({});

  function handleConvert(value) {
    const valorConverted = parseFloat(
      value
        .replace(/\./g, '')
        .replace(',', '.')
        .replace('R$ ', '')
    );
    return valorConverted;
  }

  /* busca os dados do situação */
  useEffect(() => {
    async function loadSituation() {
      if (ptam.id_situation) {
        const response = await api.get(`/situations/${ptam.id_situation}`);

        if (response.data) {
          setSituation(response.data);
        }
      }
    }

    loadSituation();
  }, []);

  /* busca os dados das áreas */
  useEffect(() => {
    async function loadAreas() {
      if (ptam.id_area) {
        const response = await api.get(`/areas/${ptam.id_area}`);

        if (response.data) {
          setArea(response.data);
        }
      }
    }

    loadAreas();
  }, []);

  /* busca os dados da edificacao */
  useEffect(() => {
    async function loadBuildings() {
      if (ptam.id_building) {
        const response = await api.get(`/buildings/${ptam.id_building}`);

        if (response.data) {
          setBuilding(response.data);
        }
      }
    }

    loadBuildings();
  }, []);

  useEffect(() => {
    async function loadSamples() {
      const response = await api.get(`/samples/${ptam.id}`);

      if (response.data.length > 0) {
        setHiddenButton(false);
        const total_ponderada = response.data.reduce((media, amostra) => {
          const valor = amostra.considera ? 1 : 0;
          return media + valor;
        }, 0);

        const vlr_media_ponderada = response.data.reduce((media, amostra) => {
          const valor = amostra.considera
            ? amostra.valor_reduzido / amostra.area_construida
            : 0;
          return media + valor;
        }, 0);

        const media_p =
          vlr_media_ponderada !== 0 && total_ponderada !== 0
            ? parseFloat(vlr_media_ponderada / total_ponderada)
            : 0;

        const total = response.data.reduce((Total, amostra) => {
          const valorConverted = handleConvert(amostra.valor);
          return Total + valorConverted;
        }, 0);

        const totalMedia = response.data.reduce((Total, amostra) => {
          const valorConverted = handleConvert(amostra.valor);
          return Total + valorConverted / amostra.area_construida;
        }, 0);

        const formatedData = response.data.map(data => ({
          ...data,
          newMetragem: data.metragem
            ? data.metragem.toLocaleString('pt-BR', {
                style: 'decimal',
              })
            : '0,00',
          newConstruido: data.area_construida
            ? data.area_construida.toLocaleString('pt-BR', {
                style: 'decimal',
              })
            : '0,00',
          newVlrOriginal: data.valor
            ? handleConvert(data.valor).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'R$ 0,00',
          newVlrM2: data.valor_por_m
            ? data.valor_por_m.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'R$ 0,00',
          newVlrReduzido: data.valor_reduzido
            ? data.valor_reduzido.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'R$ 0,00',
          newVlrM2Reduzido: data.valor_por_m_reduzido
            ? data.valor_por_m_reduzido.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'R$ 0,00',
          numVlrM2Reduzido: data.valor_por_m_reduzido,
        }));

        if (response.data.length > 0) {
          setOferta({
            ...oferta,
            somatorio_valores: total,
            quantidade_imoveis: response.data.length,
            media: totalMedia / response.data.length,
            media_ajustada:
              oferta.fator_oferta !== 0
                ? oferta.media_ajustada
                : totalMedia / response.data.length,
            fator_oferta: oferta.fator_oferta,
            fator_ponderacao: oferta.fator_ponderacao,
            media_ponderada:
              oferta.media_ponderada !== 0 ? oferta.media_ponderada : media_p,
            media_ponderada_mais:
              oferta.fator_ponderacao !== 0 ? oferta.media_ponderada_mais : 0,
            media_ponderada_menos:
              oferta.fator_ponderacao !== 0 ? oferta.media_ponderada_menos : 0,
          });
        }

        setSamples(formatedData);
      }
    }

    loadSamples();
  }, []);

  useEffect(() => {
    async function loadOfertas() {
      const total_ponderada = samples.reduce((media, amostra) => {
        const valor = amostra.considera ? 1 : 0;
        return media + valor;
      }, 0);

      const vlr_media_ponderada = samples.reduce((media, amostra) => {
        const valor = amostra.considera
          ? amostra.valor_reduzido / amostra.area_construida
          : 0;
        return media + valor;
      }, 0);

      const media_p =
        vlr_media_ponderada !== 0 && total_ponderada !== 0
          ? parseFloat(vlr_media_ponderada / total_ponderada)
          : 0;

      setOferta({
        ...oferta,
        media_ponderada: media_p,
      });
    }

    loadOfertas();
  }, [samples]);

  useEffect(() => {
    async function loadVlrFinal() {
      let ret = 0;
      let ross_h = '';

      if (area) {
        setAreaTotal(area.total);
      } else if (!showError) {
        setShowError(true);
        setErroArea(true);
      }

      const result = oferta.media_ponderada * area_total;

      ret = result;

      if (ptam.id_situation && building) {
        if (situation.use_ross === 'Sim') {
          if (situation.idade_imovel && building.estado_conservacao) {
            let idade = Math.ceil(
              (situation.idade_imovel / situation.vida_util) * 100
            );
            const build_estado = building.estado_conservacao;

            if (parseInt(idade, 0) % 2 === 0) {
              idade = parseInt(idade, 0);
            } else {
              idade = parseInt(idade, 0) + 1;
            }

            const ross = await api.get('ross-one', {
              params: {
                idade,
              },
            });

            if (ross) {
              const { a, b, c, d, e, f, g, h } = ross.data;
              if (build_estado === 'a') {
                ross_h = a;
              } else if (build_estado === 'b') {
                ross_h = b;
              } else if (build_estado === 'c') {
                ross_h = c;
              } else if (build_estado === 'd') {
                ross_h = d;
              } else if (build_estado === 'e') {
                ross_h = e;
              } else if (build_estado === 'f') {
                ross_h = f;
              } else if (build_estado === 'g') {
                ross_h = g;
              } else if (build_estado === 'h') {
                ross_h = h;
              }
              setRoss(ross_h * 100);
            }
          }
        }

        if (ross_h !== 0) {
          ret = result - result * ross_h;
        }
      }

      setVlrFinal(
        ret.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      );
    }

    loadVlrFinal();
  }, [oferta, area, building]);

  function handleSave() {
    const { id } = ptam;

    const print = !!(
      ptam.finalidade &&
      ptam.id_area &&
      ptam.id_context &&
      ptam.id_infrastructure &&
      ptam.id_inspection &&
      ptam.id_registration &&
      ptam.id_requester &&
      ptam.id_situation
    );

    if (print.finalidade) {
      toast.error('A finalidade do PTAM não foi preenchido!');
      return;
    }

    if (print.id_area) {
      toast.error('As áreas do imóvel não foi preenchidas!');
      return;
    }

    if (print.id_context) {
      toast.error('O contexto urbano não foi preenchido!');
      return;
    }

    if (print.id_infrastructure) {
      toast.error('Os dados de infraestrutura não foram preenchidos!');
      return;
    }

    if (print.id_inspection) {
      toast.error('Os dados da vistoria não foram preenchidos!');
      return;
    }

    if (print.id_registration) {
      toast.erro('Os dados do registro não foram preenchidos!');
      return;
    }

    if (print.id_requester) {
      toast.error('O solicitante não foi seleciondo!');
      return;
    }

    if (print.id_situation) {
      toast.error('Os dados da situação não foram preenchidos!');
      return;
    }

    if (print) {
      dispatch(ofertaRequest(oferta));
      dispatch(ptamStatus(id, true));
      setOpen(true);
      setFlag(true);
    } else {
      toast.error('Existem cadastros incompletos, verifique!');
      return;
    }

    /* faz a atualização das amostras */
    samples.map(sample => dispatch(amostrasChange(sample)));
  }

  function handleFatorPonderacao(value) {
    if (value !== 0) {
      if (value > 50) {
        toast.error(
          'Fator de ponderação não pode ser superior a 50%, verifique!'
        );
        setOferta({
          ...oferta,
          fator_ponderacao: 0,
        });
        return;
      }

      const divValue = value / 100 / 2;

      const valMais = oferta.media_ajustada + oferta.media_ajustada * divValue;
      const valMenos = oferta.media_ajustada - oferta.media_ajustada * divValue;

      setOferta({
        ...oferta,
        media_ponderada_mais: valMais,
        media_ponderada_menos: valMenos,
      });

      const newSamples = samples.map(data => ({
        ...data,
        considera: !!(
          data.numVlrM2Reduzido >= valMenos && data.numVlrM2Reduzido <= valMais
        ),
      }));

      setSamples(newSamples);
    } else {
      setOferta({
        ...oferta,
        media_ponderada: oferta.media_ajustada,
        media_ponderada_mais: 0,
        media_ponderada_menos: 0,
      });

      const newSamples = samples.map(data => ({
        ...data,
        considera: true,
      }));

      setSamples(newSamples);
    }
  }

  async function handleMediaAjustada(value) {
    if (value > 50) {
      toast.error('Fator de oferta não pode ser superior a 50%, verifique!');
      setOferta({
        ...oferta,
        fator_oferta: 0,
      });
      return;
    }

    const percValue = value / 100;

    setOferta({
      ...oferta,
      media_ajustada: oferta.media - oferta.media * percValue,
    });

    const newSamples = await samples.map(data => ({
      ...data,
      valor_por_m_reduzido: data.valor_por_m - data.valor_por_m * percValue,
      valor_reduzido: handleConvertCalc(data.valor, percValue),
      newVlrReduzido: handleConvertCalc(data.valor, percValue).toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        }
      ),
      newVlrM2Reduzido: (
        data.valor_por_m -
        data.valor_por_m * percValue
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      numVlrM2Reduzido: data.valor_por_m - data.valor_por_m * percValue,
    }));

    setSamples(newSamples);
  }

  function handleTypePTAM() {
    const { individual } = ptam;

    if (individual) {
      setOpenModal(true);
    } else {
      handleSave();
    }
  }

  function handleVoltar() {
    setOpen(false);
    history.push('/dashboard');
  }

  function handleConfirm(opc) {
    if (opc) {
      setOpen(false);
      handleSave();
    }
    setOpenModal(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            PTAM unitário ou PTAM grátis
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Os PTAMs do tipo unitário ou o PTAM grátis não poderão ser
              editados após sua conclusão. Antes de concluir o PTAM para gerar o
              relatório Word confira se todos os dados estão corretos! Caso o
              PTAM seja finalizado e exista necessidade de alterar algum dado o
              mesmo deve ser feito diretamente no arquivo Word gerado! PTAMS do
              tipo Assinatura podem ser editados normalmente enquando não
              estiverem expirados!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleConfirm(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleConfirm(true)} color="primary">
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {erroArea ? (
        <div>
          <Dialog
            open={erroArea}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setErroArea(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              Dados das Áreas!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Dados das Áreas do Imóvel não cadastrados, informe a área total
                para que o calculo possa ser efetuado!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErroArea(false)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {open ? (
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              PTAM Finalizado!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Parabéns, todos os dados necessarios para imprimir este PTAM
                foram gravados com sucesso! Clique no ícone Baixar Ptam{' '}
                <FiDownloadCloud size={16} color="#0d2c5b" /> na página
                principal para fazer o download do PTAM!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleVoltar()} color="primary">
                Ir ao Menu Principal
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      <Header>
        <div>
          <FaCalculator size={20} color="#fa923f" />
          <h1>Calculo da Oferta do Ptam &nbsp;&nbsp;</h1>
          <HtmlTooltip
            interactive
            title={
              <>
                <Typography color="inherit">
                  Ajuda com preenchimento desta tela?
                </Typography>
                <em>Veja o vídeo de </em>{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtu.be/Kh8nzlchDbw"
                >
                  como preencher
                </a>
                <em> corretamente os campos desta tela!</em>{' '}
              </>
            }
          >
            <HelpOutlineIcon />
          </HtmlTooltip>
        </div>
        <div>
          <button
            type="button"
            onClick={() => handleTypePTAM()}
            hidden={hiddenButton}
          >
            <div>
              <FaSave size={22} color="#fff" />
              <span>Gravar e finalizar PTAM</span>
            </div>
          </button>
        </div>
      </Header>
      <Body>
        <Paper className={classes.root}>
          <Table
            className={classes.table}
            size="small"
            aria-label="customized table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Cód. Amostra</StyledTableCell>
                <StyledTableCell align="center">Considera?</StyledTableCell>
                <StyledTableCell align="left">Tipo Imóvel</StyledTableCell>
                <StyledTableCell align="center">
                  Área Total (m²)*
                </StyledTableCell>
                <StyledTableCell align="center">Idade (anos)</StyledTableCell>
                <StyledTableCell align="right">Vlr. Original</StyledTableCell>
                <StyledTableCell align="right">Vlr. do m²</StyledTableCell>
                <StyledTableCell align="right">Vlr. Reduzido</StyledTableCell>
                <StyledTableCell align="right">
                  Vlr. do m² Reduzido
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {samples.map((sample, index) => (
                <StyledTableRow key={sample.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {sample.considera ? (
                      <FaCheckCircle size={20} color="#3BC549" />
                    ) : (
                      <MdCancel size={20} color="#F54A4A" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {sample.tipo_imovel}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {sample.newConstruido}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {sample.idade}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {sample.newVlrOriginal}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {sample.newVlrM2}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {sample.newVlrReduzido}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {sample.newVlrM2Reduzido}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <span>
          * Área Construída para Casas, Apartamentos, etc e metragem do terreno
          para Terrenos, Sítios, etc.
        </span>
        <br />
        <h1>Dados do Calculo da Oferta</h1>
        <Texto>
          <div className="side1">
            <strong>Valor Total das Amostras:</strong>
            <span>
              <b>
                {oferta.somatorio_valores.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </span>

            <strong>Quantidade de Amostras:</strong>
            <span>
              <b>{oferta.quantidade_imoveis}</b>
            </span>

            <strong>Média das Amostras (m²):</strong>
            <span>
              <b>
                {oferta.media.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </span>
          </div>
          <div className="middle">
            <div className="calcs">
              <label htmlFor="fator_oferta">
                % Oferta
                <input
                  id="fator_oferta"
                  type="number"
                  value={oferta.fator_oferta}
                  onChange={e =>
                    setOferta({ ...oferta, fator_oferta: e.target.value })
                  }
                  onBlur={e => handleMediaAjustada(e.target.value)}
                />
              </label>
              <span>*Digite valor e tecle TAB p/ calcular</span>
            </div>
            <strong>Média reduzida (m²):</strong>
            <span>
              <b>
                {oferta.media_ajustada.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </span>
            <hr />
            <div className="title">
              <strong>Valor Final do Imóvel Avaliado:</strong>
              <HtmlTooltip
                interactive
                title={
                  <>
                    <Typography color="inherit">
                      Calculo do Valor Final do Imóvel Avaliado
                    </Typography>
                    <em>Dados utilizados para o calculo: </em>
                    <br />
                    <br />
                    <em>
                      <b>Área total:</b> {area_total} m² (a)
                    </em>
                    <br />
                    <em>
                      <b>Média ponderada:</b>{' '}
                      {oferta.media_ponderada
                        ? oferta.media_ponderada.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : 'R$ 0,00'}{' '}
                      (b)
                    </em>
                    <br />
                    {ross_p !== 0 ? (
                      <em>
                        <b>Depreciação Ross Heidecke:</b> {ross_p}% (c) <br />
                        <br /> <b>Fórmula:</b> a * b - c
                      </em>
                    ) : (
                      <em>
                        <br />
                        <b>Fórmula:</b> a * b
                      </em>
                    )}
                  </>
                }
              >
                <InfoOutlined fontSize="small" />
              </HtmlTooltip>
            </div>
            <h4>
              <b>{vlrFinal}*</b>
            </h4>
            <div className="text">
              {ross_p !== 0 ? (
                <span>*Utilizando depreciação Ross Heidecke em {ross_p}%</span>
              ) : (
                <span>*Não foi configurada depreciação Ross Heidecke</span>
              )}
            </div>
          </div>
          <div className="side2">
            <div className="calcs">
              <label htmlFor="fator_ponderacao">
                % Ponderação
                <input
                  id="fator_ponderacao"
                  type="number"
                  value={oferta.fator_ponderacao}
                  onChange={e =>
                    setOferta({ ...oferta, fator_ponderacao: e.target.value })
                  }
                  onBlur={e => handleFatorPonderacao(e.target.value)}
                />
              </label>
              <span>*Digite valor e tecle TAB p/ calcular</span>
            </div>

            <strong>Média Ponderada (m²):</strong>
            <span>
              <b>
                {oferta.media_ponderada
                  ? oferta.media_ponderada.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'R$ 0,00'}
              </b>
            </span>

            <strong>Média ponderada menor (m²):</strong>
            <span>
              <b>
                {oferta.media_ponderada_menos.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </span>

            <strong>Média ponderada maior (m²):</strong>
            <span>
              <b>
                {oferta.media_ponderada_mais.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </span>
          </div>
        </Texto>
      </Body>
    </Container>
  );
}

Oferta.propTypes = {
  setFlag: PropTypes.func.isRequired,
};
