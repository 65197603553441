import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import filesize from 'filesize';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../services/api';

import UploadImageSample from '../UploadImagesSample';
import ImagesSampleList from '../ImagesSampleList';

import { Container, Content } from './styles';

class AmostraImage extends Component {
  state = {
    uploadedFiles: [],
    refresh: false,
    modalOpen: true,
  };

  async componentDidMount() {
    const { idAmostra } = this.props;

    const response = await api.get(`images-sample/${idAmostra}`);

    this.setState({
      uploadedFiles: response.data.map(file => ({
        id: file.id,
        name: file.name,
        readableSize: filesize(file.size),
        preview: file.url,
        uploaded: true,
        url: file.url,
        legenda: file.legenda,
        base64: file.base64,
      })),
    });
  }

  async componentDidUpdate(_, prevState) {
    const { idAmostra } = this.props;
    const { refresh } = this.state;

    if (prevState.refresh !== refresh) {
      const response = await api.get(`images-sample/${idAmostra}`);

      this.setState({
        uploadedFiles: response.data.map(file => ({
          id: file.id,
          name: file.name,
          readableSize: filesize(file.size),
          preview: file.url,
          uploaded: true,
          url: file.url,
          legenda: file.legenda,
          base64: file.base64,
        })),
        refresh: false,
      });
    }
  }

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      legenda: '',
      base64: '',
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  handleDelete = async id => {
    await api.delete(`images-sample/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
    });
  };

  handleRefresh = refresh => {
    this.setState({ refresh });
  };

  processUpload = uploadedFile => {
    const data = new FormData();
    const { idAmostra } = this.props;

    /* envia o arquivo primeiro */
    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('id_sample', idAmostra);

    /* faz a gravação no banco */
    api
      .post('images-sample', data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then(response => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          base64: response.data.base64,
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleClose = () => {
    const { setImagens, setRefresh } = this.props;

    this.setState({ modalOpen: false });
    setImagens(false);
    setRefresh(true);
  };

  render() {
    const { uploadedFiles, modalOpen } = this.state;

    return (
      <Container>
        <Content>
          <div>
            <Dialog
              open={modalOpen}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Imagens</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Clique abaixo e selecione a imagem que será adicionada a
                  amostra! <br />
                  <small style={{ color: '#F93636' }}>
                    Apenas 1 imagem por amostra!
                  </small>
                </DialogContentText>

                <UploadImageSample
                  onUpload={this.handleUpload}
                  totalFiles={uploadedFiles.length}
                />
                {!!uploadedFiles.length && (
                  <ImagesSampleList
                    files={uploadedFiles}
                    onDelete={this.handleDelete}
                    handleRefresh={this.handleRefresh}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Content>
      </Container>
    );
  }
}

export default AmostraImage;

AmostraImage.propTypes = {
  idAmostra: PropTypes.number.isRequired,
  setAddImg: PropTypes.func.isRequired,
};
