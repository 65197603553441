import React, { useEffect, useState, useCallback } from 'react';
import { MdFindInPage } from 'react-icons/md';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import api from '../../../services/api';
import View from './view';

import { Container, Header } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
}));

export default function Transaction() {
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState({});
  const [view, setView] = useState(false);

  useEffect(() => {
    async function loadTransactions() {
      const response = await api.get('/transactions');

      setTransactions(response.data);
    }

    loadTransactions();
  }, []);

  const handleView = useCallback(async transaction => {
    setView(true);
    setData(transaction);
  }, []);

  return (
    <Container>
      {view && <View data={data} setView={setView} />}
      <Header>
        <h1>Controle de Transações</h1>
      </Header>

      <hr />

      <Paper className={classes.root}>
        <Table
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Nome Cliente</StyledTableCell>
              <StyledTableCell align="right">CPF</StyledTableCell>
              <StyledTableCell align="left">E-mail</StyledTableCell>
              <StyledTableCell align="center">Data Compra</StyledTableCell>
              <StyledTableCell align="right">Produto</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="center">Ação</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(transaction => (
              <StyledTableRow key={transaction.id}>
                <StyledTableCell align="left" component="th" scope="row">
                  {transaction.customer_name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {transaction.customer_identity}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {transaction.customer_email}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {transaction.created_date}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {transaction.product_sku}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {transaction.amount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {transaction.payment_status === 1
                    ? 'Pendente'
                    : transaction.payment_status === 2
                    ? 'Pago'
                    : transaction.payment_status === 3
                    ? 'Negado'
                    : transaction.payment_status === 4
                    ? 'Expirado'
                    : transaction.payment_status === 5
                    ? 'Cancelado'
                    : transaction.payment_status === 6
                    ? 'Não Finalizado'
                    : transaction.payment_status === 7
                    ? 'Autorizado (Aguarde)'
                    : transaction.payment_status === 8
                    ? 'Chargeback'
                    : 'Não Identificado'}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button
                    type="button"
                    title="visualizar"
                    onClick={() => handleView(transaction)}
                  >
                    <MdFindInPage size={24} color="#5781c0" />
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}
