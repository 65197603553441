import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  tipoRequest,
  tipoUpdate,
} from '../../../../store/modules/tipoimovel/actions';

import { Container } from './styles';

export default function Handle({
  open,
  setOpen,
  view,
  edit,
  tipo,
  setTipo,
  setRefresh,
}) {
  const dispatch = useDispatch();

  function handleSave() {
    if (!edit) {
      dispatch(tipoRequest(tipo));
    } else {
      dispatch(tipoUpdate(tipo));
    }

    setRefresh(true);
    setOpen(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Dados Tipo Imóvel</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para gravação, edição ou visualização de
              um registro de Tipo do Imóvel. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
              OBRIGATÓRIOS!!
            </DialogContentText>

            <label htmlFor="idade">
              Tipo do Imóvel
              <input
                autoFocus
                required
                id="idade"
                value={tipo.descricao}
                onChange={e => setTipo({ ...tipo, descricao: e.target.value })}
              />
            </label>

            <label htmlFor="vida_util">
              Vida Útil (anos)
              <input
                required
                id="vida_util"
                type="number"
                value={tipo.vida_util}
                onChange={e => setTipo({ ...tipo, vida_util: e.target.value })}
              />
            </label>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => handleSave()}
              color="primary"
              disabled={view}
            >
              {view ? '' : 'Salvar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setOpen: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  tipo: PropTypes.objectOf(PropTypes.object),
  setTipo: PropTypes.func.isRequired,
  setRefresh: PropTypes.func,
};

Handle.defaultProps = {
  tipo: null,
  setRefresh: false,
};
