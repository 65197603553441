/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import { infraSuccess } from '../../../../store/modules/infraestrutura/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  infraestrutura,
  setInfraestrutura,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      const response = edit
        ? await api.put('infrastructures', infraestrutura)
        : await api.post('/infrastructures', infraestrutura);

      const { id } = response.data;

      dispatch(infraSuccess(id));

      setFlag({ ...flag, infraestrutura: true });
      setModal(false);
      setEdit(false);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados da Infraestrutura do imóvel &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a target="_blank" href="https://youtu.be/qZqYterMCsI">
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados da
              Infraestrutura disponivel na região do imóvel. Mesmo que o ímovel
              não possua infraestrutura clique em SALVAR !!
            </DialogContentText>
            <Form>
              <div className="side1">
                <h1>Infraestrutura da Região</h1>
                <FormGroup columnw>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.agua}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            agua: e.target.checked,
                          })
                        }
                        value={infraestrutura.agua}
                        color="primary"
                      />
                    }
                    label="Rede de Água?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.iluminacao}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            iluminacao: e.target.checked,
                          })
                        }
                        value={infraestrutura.iluminacao}
                        color="primary"
                      />
                    }
                    label="Iluminação Pública?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.lixo}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            lixo: e.target.checked,
                          })
                        }
                        value={infraestrutura.lixo}
                        color="primary"
                      />
                    }
                    label="Coleta de Lixo?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.telefone}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            telefone: e.target.checked,
                          })
                        }
                        value={infraestrutura.telefone}
                        color="primary"
                      />
                    }
                    label="Cabeamento de Telefonia?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.pavimentacao}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            pavimentacao: e.target.checked,
                          })
                        }
                        value={infraestrutura.pavimentacao}
                        color="primary"
                      />
                    }
                    label="Pavimentação?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.energia}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            energia: e.target.checked,
                          })
                        }
                        value={infraestrutura.energia}
                        color="primary"
                      />
                    }
                    label="Rede de Energia Elétrica?"
                  />
                </FormGroup>
              </div>
              <div className="middle" />
              <div className="side2">
                <br />
                <FormGroup columnw>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.esgoto}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            esgoto: e.target.checked,
                          })
                        }
                        value={infraestrutura.esgoto}
                        color="primary"
                      />
                    }
                    label="Rede de Esgoto?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.transporte}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            transporte: e.target.checked,
                          })
                        }
                        value={infraestrutura.transporte}
                        color="primary"
                      />
                    }
                    label="Linhas de Transporte Público?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={infraestrutura.tv}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            tv: e.target.checked,
                          })
                        }
                        value={infraestrutura.tv}
                        color="primary"
                      />
                    }
                    label="TV a cabo?"
                  />

                  <div className="obs">
                    <label htmlFor="outras">
                      Outras informações
                      <textarea
                        id="outras"
                        type="text"
                        value={infraestrutura.outras}
                        onChange={e =>
                          setInfraestrutura({
                            ...infraestrutura,
                            outras: e.target.value,
                          })
                        }
                      />
                    </label>
                  </div>
                </FormGroup>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  infraestrutura: PropTypes.objectOf(PropTypes.object),
  setInfraestrutura: PropTypes.func,
  setEdit: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  infraestrutura: null,
  setInfraestrutura: null,
  setEdit: null,
};
