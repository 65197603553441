import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 45% 10% 45%;
  grid-template-areas: 'side1 middle side2';

  input,
  select {
    margin-bottom: 3%;
  }

  label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3%;
  }

  .side1 {
    grid-area: side1;
  }

  .middle {
    grid-area: middle;
  }

  .side2 {
    grid-area: side2;
  }
`;
