import styled from 'styled-components';

export const Container = styled.div`
  div {
    img {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 4px;
    }
  }
`;
