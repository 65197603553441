import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Form, Input } from '@rocketseat/unform';
import history from '../../services/history';

import logo from '../../assets/logo.png';
// import logo from '../../assets/logo.svg';

import { signInRequest } from '../../store/modules/auth/actions';

import { Background, Container, Content, AnimatedContent } from './styles';

/* validação de campos */
const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido!')
    .required('O e-mail é obrigatório!'),
  password: Yup.string()
    .min(6, 'Senha deve conter, no mínimo, 6 digitos!')
    .required('A senha é obrigatória!'),
});

export default function SignIn() {
  const dispatch = useDispatch();

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  function handleSignUp() {
    history.push('/signup');
  }

  return (
    <Container>
      <Content>
        <AnimatedContent>
          <img src={logo} alt="Avalie Fácil" />

          <Form schema={schema} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>
            <Input
              name="email"
              type="email"
              placeholder="Digite seu email"
              required
            />

            <Input
              name="password"
              type="password"
              placeholder="Digite sua senha"
              required
            />

            <Link to="/forgot">Esqueci minha senha</Link>
            <button type="submit">Acessar</button>
            <button type="button" onClick={() => handleSignUp()}>
              Crie sua conta
            </button>
          </Form>
        </AnimatedContent>
      </Content>

      <Background />
    </Container>
  );
}
