export function rossRequest({ idade, a, b, c, d, e, f, g, h }) {
  return {
    type: '@ross/REQUEST',
    payload: { idade, a, b, c, d, e, f, g, h },
  };
}

export function rossUpdate({ id, idade, a, b, c, d, e, f, g, h }) {
  return {
    type: '@ross/UPDATE',
    payload: { id, idade, a, b, c, d, e, f, g, h },
  };
}

export function rossDelete(id) {
  return {
    type: '@ross/DELETE',
    payload: { id },
  };
}

export function rossFailure() {
  return {
    type: '@ross/FAILURE',
  };
}
