import { createGlobalStyle } from 'styled-components';

import 'react-circular-progressbar/dist/styles.css';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,body{
    height: 100%;
  }

  #root {
    min-height: 100%;
  }

  body {
    @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500&display=swap');

    font-family: 'Montserrat', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialised !important;
  }

  a {
    text-decoration: none;
    color: #0d2c5b;
  }

  ul {
    list-style: none;
  }

  strong {
    color: #0d2c5b;
  }

  span {
    color: #333;
  }

  label {
        font-size: 12px;
        color: #999;
        margin-bottom: 2px;
        margin-left: 5px;
      }

  button {
    outline: 0;
    cursor: pointer;
  }

  input, select {
    height: 6vh;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 0 2%;
    font-size: 14px;
    border-left: 4px solid;
    transition: border-color 0.5s ease-out;

    &::placeholder {
      color: #999;
    }
  }

  textarea {
    min-height: 140px;
    resize: vertical;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 2% 2%;
    font-size: 14px;
    border-left: 4px solid;
    transition: border-color 0.5s ease-out;
  }
  .input-disable-name {
    color: #fff;
    font-weight: bold;
  }

  input:focus {
    border: 1px solid #fa923f;
    border-left: 4px solid;
    border-left-color: #fa923f;
  }

  select:focus {
    border: 1px solid #fa923f;
    border-left: 4px solid;
    border-left-color: #fa923f;
  }

  textarea:focus {
    border: 1px solid #fa923f;
    border-left: 4px solid;
    border-left-color: #fa923f;
  }

  input:optional {
    border-left-color: #999;
  }

  input:required {
    border-left-color: palegreen;
  }

  input:invalid {
    border-left-color: red;
  }

  select:optional {
    border-left-color: #999;
  }

  select:required {
    border-left-color: palegreen;
  }

  select:invalid {
    border-left-color: red;
  }

  textarea:optional {
    border-left-color: #999;
  }

  textarea:required {
    border-left-color: palegreen;
  }

  textarea:invalid {
    border-left-color: red;
  }
`;
