import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { MdDvr } from 'react-icons/md';
import jwtDecode from 'jwt-decode';
import { pt } from 'date-fns/locale';
import { isBefore, format, parseISO, isAfter } from 'date-fns';
import { toast } from 'react-toastify';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';

import logo from '../../assets/logo.png';
import { signOut } from '../../store/modules/auth/actions';
import { ptamSuccess } from '../../store/modules/ptam/actions';
import { updateProfileSuccess } from '../../store/modules/user/actions';
import Loader from '../Loader';

import api from '../../services/api';
import history from '../../services/history';

import { Container, Content, Profile, AccessButton } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Header() {
  const [openModal, setOpenModal] = useState(false);
  const [expired, setExpired] = useState(false);
  const { signed, token } = useSelector(state => state.auth);
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const expiredSignature = useMemo(() => {
    if (
      profile.mobile &&
      profile.cep &&
      profile.address &&
      profile.number &&
      profile.city &&
      profile.state
    ) {
      if (
        isAfter(parseISO(profile.expire_at), new Date()) ||
        profile.ptam_quantity > 0
      ) {
        return true;
      }
      if (
        isAfter(parseISO(profile.expire_at), new Date()) &&
        profile.ptam_quantity <= 0
      ) {
        return false;
      }
    }
    return false;
  }, [profile]);

  async function handlePtam() {
    const { id: id_user } = profile;

    try {
      setLoading(true);

      const response = await api.post('/ptams', { id_user });
      dispatch(ptamSuccess(response.data));

      const userUpdate = await api.get('/users');
      dispatch(updateProfileSuccess(userUpdate.data));

      setLoading(false);
      setOpenModal(true);

      toast.success('PTAM criado com sucesso !');

      history.push('/ptam');
    } catch (err) {
      toast.error('Erro ao criar o PTAM');
      setLoading(false);
    }
  }

  function handleOut() {
    const { id } = profile;

    dispatch(signOut(id));
  }

  function handleAdmin() {
    history.push('/admin');
  }

  function handleExpired() {
    setExpired(false);
    history.push('/signatures');
  }

  function handleHomePage() {
    history.push('/');
  }

  function closeWindow() {
    if (signed) {
      const { exp } = jwtDecode(token);

      const miliseconds = exp * 1000;
      const dateExpiration = new Date(miliseconds);

      const dateValidation = isBefore(dateExpiration, new Date());
      if (dateValidation) {
        localStorage.removeItem('persist:AvalieFacil');

        dispatch(signOut(profile.id));
      }
    }
  }

  const handleReleaseAccess = () => {
    dispatch(signOut(profile.id));

    localStorage.removeItem('persist:AvalieFacil');

    return <Redirect to="/" />;
  };

  window.onbeforeunload = closeWindow;

  return (
    <Container>
      {loading && <Loader />}
      <Content>
        <div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              PTAM Unitário ou PTAM grátis
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Os PTAMs do tipo unitário ou o PTAM grátis não poderão ser
                editados após sua conclusão. Antes de concluir o PTAM para gerar
                o relatório Word confira se todos os dados estão corretos! Caso
                o PTAM seja finalizado e exista necessidade de alterar algum
                dado o mesmo deve ser feito diretamente no arquivo Word gerado!
                PTAMS do tipo Assinatura podem ser editados normalmente enquando
                não estiverem expirados!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Ok, estou ciente!
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={expired}
            onClose={() => setExpired(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Assinatura Expirada!
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Prezado(a) avaliador(a), sua assinatua está expirada! Você só
                poderá imprimir os PTAM´s que estão completos. PTAM´s
                incompletos não estarão disponíveis para edição! <br /> <br />
                <b>Regularize seu plano!</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setExpired(false)} color="primary">
                Fechar
              </Button>
              <Button onClick={() => handleExpired()} color="primary">
                Renovar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <nav>
          <div>
            <img src={logo} alt="Avalie Facil" />
            {/* <Link to="/">Meus Ptams</Link> */}
            <button type="button" onClick={() => handleHomePage()}>
              <strong>Meus PTAMS</strong>
            </button>

            {expiredSignature ? (
              <button type="button" onClick={() => handlePtam()}>
                <strong>Criar Novo PTAM</strong>
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: 'red',
                  fontSize: 12,
                }}
                type="button"
                onClick={() => setExpired(true)}
              >
                <strong>
                  Assinatura Expirada
                  <br />
                  Clique p/ informações
                </strong>
              </button>
            )}

            <button type="button" onClick={() => history.push('/signatures')}>
              <strong>Assinaturas</strong>
            </button>

            <AccessButton type="button" onClick={handleReleaseAccess}>
              <span>Liberar acesso</span>
            </AccessButton>
          </div>
        </nav>
        <aside>
          <HtmlTooltip
            interactive
            title={
              <>
                <Typography color="inherit">
                  <b>Veja aqui seus dados de acesso:</b>
                </Typography>
                <br />
                <em>Assinatura Expirada: {profile.expired ? 'Sim' : 'Não'}</em>
                <br />
                <em>
                  {profile.expired
                    ? 'Assinatura Expirou em: '
                    : 'Assinatura Expira em: '}
                  {format(
                    parseISO(profile.expire_at),
                    "dd 'de' MMMM 'de' yyyy",
                    {
                      locale: pt,
                    }
                  )}
                </em>{' '}
                <br />
                <em>
                  Saldo de Ptams Unitários:{' '}
                  {profile.ptam_quantity ? profile.ptam_quantity : 0}
                </em>
              </>
            }
          >
            <Info style={{ color: '#fa923f' }} />
          </HtmlTooltip>
          {profile.permission === 'administrador' ||
          profile.permission === 'funcionario' ? (
            <button
              type="button"
              onClick={() => handleAdmin()}
              title="Painel Administrativo"
            >
              <MdDvr size={16} color="#fa923f" />
            </button>
          ) : null}

          <Profile>
            <div>
              <strong>{profile.name}</strong>
              <Link to="/profile">Meu Perfil</Link>
            </div>
            <img
              src={
                profile.avatar
                  ? `data:image/jpeg;base64,${profile.avatar.base64}`
                  : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=${profile.name}`
              }
              alt="Meu Perfil"
            />
          </Profile>

          <button type="button" onClick={() => handleOut()}>
            Sair
          </button>
        </aside>
      </Content>
    </Container>
  );
}
