import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  z-index: 9999;
`;

export const Content = styled.div`
  position: relative;
  padding: 16px;
  margin: 36px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  height: 80vh;

  background: #fff;
  box-shadow: 0 0 8px #fa923f;
  border-radius: 8px;

  @media only screen and (max-width: 56.25em) {
    width: 80%;
  }

  > img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin: 16px 0;
    align-self: center;
    border: 3px solid #fa923f;

    @media only screen and (max-width: 56.25em) {
      width: 150px;
      height: 150px;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  button {
    background: transparent;
    border: 0;

    display: flex;
    align-items: center;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const Profile = styled.div`
  label {
    font-size: 14px;

    display: flex;
    flex-direction: column;
  }
`;
