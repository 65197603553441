import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { requesterFailure, requesterSuccess } from './actions';

export function* saveRequester({ payload }) {
  try {
    const response = yield call(api.post, 'requesters', payload);

    toast.success('Solicitante cadastrado com sucesso!');

    yield put(requesterSuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao cadastrar o solicitante, verifique seus dados!');

    yield put(requesterFailure());
  }
}

export function* updateRequester({ payload }) {
  try {
    const response = yield call(api.put, 'requesters', payload);

    toast.success('Solicitante atualizado com sucesso!');

    yield put(requesterSuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao atualizar o solicitante, verifique seus dados!');

    yield put(requesterFailure());
  }
}

export function* loadRequester({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `/requesters-one/${id}`);

    yield put(requesterSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao carregar o solicitante !');
    yield put(requesterFailure());
  }
}

export function* loadAllRequesters({ payload }) {
  try {
    const { id_user } = payload;
    const response = yield call(api.get, `/requesters/${id_user}`);

    yield put(requesterSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao carregar o solicitante !');
    yield put(requesterFailure());
  }
}

export function* deleteRequester({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/requesters/${id}`);
    toast.success('Solicitante excluído com sucesso !');
  } catch (err) {
    toast.error('Falha ao deletar o solicitante');
  }
}

export default all([
  takeLatest('@req/REQUESTER_REQUEST', saveRequester),
  takeLatest('@req/REQUESTER_UPDATE', updateRequester),
  takeLatest('@req/REQUESTER_LOAD', loadRequester),
  takeLatest('@req/REQUESTER_ALL_USER', loadAllRequesters),
  takeLatest('@req/REQUESTER_DELETE', deleteRequester),
]);
