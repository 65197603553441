import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FaEdit, FaUserTie } from 'react-icons/fa';

import Handle from './handle';

import { Container, Header, Body, Image, Grid } from './styles';

export default function Profile() {
  const user = useSelector(state => state.user.profile);

  const [modal, setModal] = useState(false);

  const userDocLabel = useMemo(() => {
    switch (user.usertype) {
      case 'Avaliador Imobiliário(a)':
        return 'CNAI';

      case 'Arquiteto(a)':
        return 'CAU';

      case 'Engenheiro(a)':
        return 'CREA';

      default:
        return '';
    }
  }, []);

  return (
    <Container>
      {modal ? <Handle open={modal} setModal={setModal} /> : null}
      <Header>
        <div>
          <FaUserTie size={20} color="#fa923f" />
          <h1>Atualização dos dados cadastrais</h1>
        </div>
        <div>
          <button type="button" onClick={() => setModal(true)}>
            <div>
              <FaEdit size={22} color="#fff" />
              <span>Editar Cadastro</span>
            </div>
          </button>
        </div>
      </Header>
      <Image>
        <div className="images">
          <img
            className="profile"
            src={
              user.avatar
                ? `data:image/jpeg;base64,${user.avatar.base64}`
                : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=${user.name}`
            }
            alt="Profile"
          />
          <br />
          <span>Foto do Perfil</span>
        </div>
        <div className="images">
          <img
            className="company"
            src={
              user.company
                ? `data:image/jpeg;base64,${user.company.base64}`
                : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=Empresa`
            }
            alt="Profile"
          />
          <br />
          <span>Logotipo da Empresa</span>
        </div>
      </Image>
      <Body>
        <div>
          <Grid>
            <div>
              <h5 style={{ color: '#fa923f' }}>Dados Pessoais</h5>

              <strong>Nome Completo</strong>
              <span>{user.name}</span>

              <strong>E-mail</strong>
              <span>{user.email}</span>

              <strong>Site</strong>
              <span>{user.site}</span>

              <strong>CPF</strong>
              <span>{user.cpf}</span>

              {userDocLabel && <strong>Tipo do Usuário</strong>}
              {userDocLabel && <span>{user.usertype}</span>}

              {userDocLabel && <strong>Nº do {userDocLabel}</strong>}
              {userDocLabel && <span>{user.cnai}</span>}

              <strong>Celular (com DDD)</strong>
              <span>{user.mobile}</span>

              <strong>Telefone (com DDD)</strong>
              <span>{user.phone}</span>

              <strong>Nº do Creci</strong>
              <span>{user.creci}</span>
            </div>
            <div>
              <h5 style={{ color: '#fa923f' }}>Endereço</h5>

              <strong>Nº CEP</strong>
              <span>{user.cep}</span>

              <strong>Endereço</strong>
              <span>
                {user.address}, {user.number}
              </span>

              <strong>Complemento</strong>
              <span>{user.complement}</span>

              <strong>Cidade / Estado</strong>
              <span>
                {user.city} / {user.state}
              </span>

              <strong>Bairro</strong>
              <span>{user.neighborhood}</span>

              <hr />

              <h5 style={{ color: '#fa923f' }}>Área do Avaliador </h5>
              <strong>Exibe seus Dados em Encontre um Avaliador?</strong>
              <span>{user.show_data}</span>

              <hr />

              <h5 style={{ color: '#fa923f' }}>
                Termos de uso / Politica de privacidade
              </h5>
              <a
                href="https://avaliefacil.com.br/termos-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                https://avaliefacil.com.br/termos-de-uso
              </a>
            </div>
            <div>
              <h5 style={{ color: '#fa923f' }}>Currículo do Avaliador</h5>

              <strong>Formação Profissional</strong>
              <span>{user.professional_qualification}</span>

              <strong>Experiência Profissional</strong>
              <span>{user.professional_experience}</span>
            </div>
          </Grid>
        </div>
      </Body>
    </Container>
  );
}
