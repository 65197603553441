import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import filesize from 'filesize';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { FaImages } from 'react-icons/fa';
import { MdSubtitles, MdDeleteSweep } from 'react-icons/md';
import { GoListOrdered } from 'react-icons/go';
import { Content, Headers } from './styles';
import Upload from '../../../components/Upload';
import ImageList from '../../../components/ImageList';
import api from '../../../services/api';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class Imagem extends Component {
  state = {
    uploadedFiles: [],
    refresh: false,
    excluirAll: false,
    idx: 0,
  };

  async componentDidMount() {
    const { ptam } = this.props;
    const response = await api.get(`images/${ptam}`);

    let max;
    if (response.data.length > 0) {
      max = response.data.reduce(function(prev, current) {
        return prev.index > current.index ? prev : current;
      });
    }

    this.setState({
      uploadedFiles: response.data.map(file => ({
        id: file.id,
        name: file.name,
        readableSize: filesize(file.size),
        preview: file.url,
        uploaded: true,
        url: file.url,
        legenda: file.legenda,
        base64: file.base64,
        index: file.index,
      })),
      refresh: false,
      idx: max ? max.index : 0,
    });
  }

  async componentDidUpdate(_, prevState) {
    const { ptam } = this.props;
    const { refresh } = this.state;

    if (prevState.refresh !== refresh) {
      const response = await api.get(`images/${ptam}`);

      const max = response.data.reduce(function(prev, current) {
        return prev.index > current.index ? prev : current;
      });

      this.setState({
        uploadedFiles: response.data.map(file => ({
          id: file.id,
          name: file.name,
          readableSize: filesize(file.size),
          preview: file.url,
          uploaded: true,
          url: file.url,
          legenda: file.legenda,
          base64: file.base64,
          index: file.index,
        })),
        refresh: false,
        idx: max.index,
      });
    }
  }

  handleUpload = files => {
    const { idx } = this.state;
    let indx = idx;

    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      legenda: '',
      base64: '',
      index: ++indx,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
      idx: indx,
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  handleDelete = async id => {
    await api.delete(`images/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
    });
  };

  handleRefresh = refresh => {
    this.setState({ refresh });
  };

  handleExcAll = async () => {
    const { ptam } = this.props;

    await api.delete(`images-all/${ptam}`);

    this.setState({
      uploadedFiles: [],
      excluirAll: false,
      idx: 0,
    });
  };

  handleOpen = () => {
    this.setState({ excluirAll: true });
  };

  handleClose = () => {
    this.setState({ excluirAll: false });
  };

  processUpload = async uploadedFile => {
    const data = new FormData();
    const { ptam } = this.props;

    /* envia o arquivo primeiro */
    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('id_ptam', ptam);
    data.append('index', uploadedFile.index);

    /* faz a gravação no banco */
    await api
      .post('images', data, {
        onUploadProgress: async e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          await this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then(response => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          base64: response.data.base64,
          legenda: response.data.legenda,
          index: response.data.index,
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  render() {
    const { uploadedFiles, excluirAll } = this.state;
    const { ptam } = this.props;

    return (
      <Content>
        <div>
          <Dialog
            open={excluirAll}
            onClose={() => this.handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Tem certeza de que deseja excluir TODAS as imagens deste PTAM?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Após a exclusão, as imagens não estarão mais disponíveis para
                uso!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleClose()}
                color="primary"
                style={{ outline: 'none' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleExcAll()}
                color="primary"
                autoFocus
                style={{ outline: 'none' }}
              >
                Excluir
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Headers>
          <ul>
            <li>
              <FaImages size={20} color="#fa923f" />
            </li>
            <li>
              <h1>Upload de Fotos do Imóvel &nbsp;</h1>
            </li>
            <li>
              <HtmlTooltip
                interactive
                title={
                  <>
                    <Typography color="inherit">
                      Ajuda com preenchimento desta tela?
                    </Typography>
                    <em>{'Veja o vídeo de '}</em>{' '}
                    <a
                      target="_blank"
                      href="https://youtu.be/AF_L4Ln3jJc"
                      rel="noreferrer"
                    >
                      como preencher
                    </a>
                    <em>{' corretamente os campos desta tela!'}</em>{' '}
                  </>
                }
              >
                <HelpOutlineIcon />
              </HtmlTooltip>
            </li>
          </ul>
          <button type="button" onClick={() => this.handleOpen()}>
            <div>
              <MdDeleteSweep size={22} color="#fff" />
              <span>Excluir TODAS</span>
            </div>
          </button>
        </Headers>
        <small style={{ color: '#F93636' }}>
          Tamanho máximo das imagens: 2MB cada
        </small>
        <Upload onUpload={this.handleUpload} />
        <br />
        <span>
          * Utilize o icone{' '}
          <MdSubtitles
            style={{ marginRight: 5, marginLeft: 5 }}
            size={18}
            color="#353839"
          />{' '}
          para informar a legenda de cada imagem!
        </span>
        <br />
        <span>
          * Utilize o icone{' '}
          <GoListOrdered
            style={{ marginRight: 5, marginLeft: 5 }}
            size={18}
            color="#fa923f"
          />{' '}
          para reordenar a posição que deseja imprimir cada imagem no relatório!
        </span>
        {!!uploadedFiles.length && (
          <ImageList
            ptam={ptam}
            files={uploadedFiles}
            onDelete={this.handleDelete}
            handleRefresh={this.handleRefresh}
          />
        )}
      </Content>
    );
  }
}

export default Imagem;

Imagem.propTypes = {
  ptam: PropTypes.number.isRequired,
};
