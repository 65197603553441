import styled from 'styled-components';
import avalieBackgroundSignIn from '../../assets/avalie-background-SignIn.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: #0d2c5b;
  outline: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  place-content: center;

  width: 100%;
  max-width: 700px;

  img {
    width: 400px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
    align-items: center;
    text-align: center;

    h2 {
      color: #fff;
      margin-bottom: 30px;
    }

    p {
      margin: 15px 60px;
      color: #fff;
    }

    input {
      width: 350px;
      margin-top: 20px;
      color: #0d2c5b;

      &::placeholder {
        color: #666;
      }
    }
  }

  button {
    width: 350px;
    height: 40px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${avalieBackgroundSignIn}) no-repeat center;
  background-size: cover;
`;
