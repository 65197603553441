/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { FaSearchLocation } from 'react-icons/fa';
import { FiArrowLeft, FiAward } from 'react-icons/fi';

import Detail from './Details';
import Loader from '../../components/Loader';
import logoAvalie from '../../assets/logo.svg';
import api from '../../services/api';

import { Container, Content, ListItems } from './styles';

export default function Brokers() {
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [detail, setDetail] = useState(false);

  useEffect(() => {
    async function loadBrokers() {
      const response = await api.get('/brokers', {
        params: {
          state,
          city,
        },
      });

      setBrokers(response.data);
      setLoading(false);
    }

    setLoading(true);
    loadBrokers();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (state === 'XX') {
      setState('');
    }

    const response = await api.get('/brokers', {
      params: {
        state,
        city,
      },
    });

    setBrokers(response.data);
    setLoading(false);
  }

  function handleDetails(broker) {
    setData(broker);
    setDetail(true);
  }

  return (
    <Container>
      {loading && <Loader />}
      {detail && <Detail broker={data} setDetail={setDetail} />}
      <Content>
        <header>
          <img src={logoAvalie} alt="Avalie Fácil" />

          <form onSubmit={handleSubmit}>
            <h2>Encontre seu avaliador </h2>
            <select value={state} onChange={e => setState(e.target.value)}>
              <option value="XX">Escolha o estado</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernanbuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="**">Exterior</option>
            </select>

            <input
              type="text"
              placeholder="Digite a cidade"
              onChange={e => setCity(e.target.value)}
            />

            <button type="submit">
              <FaSearchLocation />
              Buscar
            </button>
          </form>
          <a href="https://avaliefacil.com.br/">
            <FiArrowLeft size={18} />
            Voltar
          </a>
        </header>
        <ul>
          {brokers.map(broker => (
            <ListItems key={broker.id}>
              <img
                src={
                  broker.avatar
                    ? `data:image/jpeg;base64,${broker.avatar.base64}`
                    : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=fff&name=${broker.name}`
                }
                alt={broker.name}
              />
              <label htmlFor="name">
                Nome
                <strong id="name">{broker.name}</strong>
              </label>
              <label htmlFor="email">
                E-mail
                <strong id="name">{broker.email}</strong>
              </label>

              {broker.mobile && (
                <label htmlFor="phone">
                  Telefone de Contato
                  <strong id="phone">{broker.mobile}</strong>
                </label>
              )}
              {broker.usertype === 'Engenheiro(a)' ||
              broker.usertype === 'Arquiteto(a)' ? (
                <>
                  <label htmlFor="creci">
                    {broker.usertype === 'Engenheiro(a)' ? 'CREA' : 'CAU'}
                    <strong id="creci">
                      {broker.cnai ? broker.cnai : 'não informado'}
                    </strong>
                  </label>

                  <label htmlFor="estado">
                    Estado
                    <strong>
                      {broker.state ? broker.state : 'não informado'}
                    </strong>
                  </label>
                </>
              ) : (
                <>
                  <label htmlFor="creci">
                    Nº creci / CNAI
                    <strong id="creci">
                      {broker.creci ? broker.creci : 'não informado'} /{' '}
                      {broker.cnai ? broker.cnai : 'não informado'}
                    </strong>
                  </label>

                  <label htmlFor="estado">
                    Região CRECI
                    <strong>
                      {broker.regiao_creci
                        ? broker.regiao_creci
                        : 'não informado'}
                    </strong>
                  </label>
                </>
              )}

              <button type="button" onClick={() => handleDetails(broker)}>
                <FiAward size={16} color="#0d2c5b" />
                Detalhes
              </button>
            </ListItems>
          ))}
        </ul>
      </Content>
    </Container>
  );
}
