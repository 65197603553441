import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      id,
      avatar_id,
      company_id,
      name,
      email,
      site,
      creci,
      regiao_creci,
      cnai,
      cpf,
      usertype,
      mobile,
      phone,
      cep,
      address,
      complement,
      number,
      city,
      state,
      neighborhood,
      professional_qualification,
      professional_experience,
      show_data,
      ...rest
    } = payload.profile;

    const profile = {
      id,
      avatar_id,
      company_id,
      name,
      email,
      site,
      creci,
      regiao_creci,
      cnai,
      cpf,
      usertype,
      mobile,
      phone,
      cep,
      address,
      complement,
      number,
      city,
      state,
      neighborhood,
      professional_qualification,
      professional_experience,
      show_data,
      ...(rest.password ? rest : {}),
    };

    const response = yield call(api.put, 'profile', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    toast.error('Erro na atualização do perfil, confira seus dados!');
    yield put(updateProfileFailure());
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
