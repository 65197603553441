import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  rossRequest,
  rossUpdate,
} from '../../../../store/modules/ross/actions';

import { Container, Form } from './styles';

export default function Handle({
  open,
  setOpen,
  view,
  edit,
  ross,
  setRoss,
  setRefresh,
}) {
  const dispatch = useDispatch();

  function handleSave() {
    if (!edit) {
      dispatch(rossRequest(ross));
    } else {
      dispatch(rossUpdate(ross));
    }

    setRefresh(true);
    setOpen(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Dados Ross Heidecke</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para gravação, edição ou visualização de
              uma tabela Ross Heidecke. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
              OBRIGATÓRIOS !!
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="idade">
                  Idade do imóvel
                  <input
                    autoFocus
                    required
                    id="idade"
                    type="number"
                    value={ross.idade}
                    onChange={e => setRoss({ ...ross, idade: e.target.value })}
                  />
                </label>

                <label htmlFor="a">
                  Depreciação para A (Nova)
                  <input
                    required
                    id="a"
                    type="number"
                    value={ross.a}
                    onChange={e => setRoss({ ...ross, a: e.target.value })}
                  />
                </label>

                <label htmlFor="b">
                  Depreciação para B (Nova - Regular)
                  <input
                    required
                    id="b"
                    type="number"
                    value={ross.b}
                    onChange={e => setRoss({ ...ross, b: e.target.value })}
                  />
                </label>

                <label htmlFor="c">
                  Depreciação para C (Regular - Reparos Simples)
                  <input
                    required
                    id="c"
                    type="number"
                    value={ross.c}
                    onChange={e => setRoss({ ...ross, c: e.target.value })}
                  />
                </label>

                <label htmlFor="d">
                  Depreciação para D (Reparos Simples)
                  <input
                    required
                    id="d"
                    type="number"
                    value={ross.d}
                    onChange={e => setRoss({ ...ross, d: e.target.value })}
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="e">
                  Depreciação para E (Reparos Simples - Reparos Importantes)
                  <input
                    required
                    id="e"
                    type="number"
                    value={ross.e}
                    onChange={e => setRoss({ ...ross, e: e.target.value })}
                  />
                </label>

                <label htmlFor="f">
                  Depreciação para F (Reparos Importantes)
                  <input
                    required
                    id="f"
                    label="Depreciação para F (Reparos Importantes)"
                    type="number"
                    value={ross.f}
                    onChange={e => setRoss({ ...ross, f: e.target.value })}
                  />
                </label>

                <label htmlFor="g">
                  Depreciação para G (Reparos Importantes - Sem Valor)
                  <input
                    required
                    id="g"
                    type="number"
                    value={ross.g}
                    onChange={e => setRoss({ ...ross, g: e.target.value })}
                  />
                </label>

                <label htmlFor="h">
                  Depreciação para H (Sem Valor)
                  <input
                    required
                    id="h"
                    type="number"
                    value={ross.h}
                    onChange={e => setRoss({ ...ross, h: e.target.value })}
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => handleSave()}
              color="primary"
              disabled={view}
            >
              {view ? '' : 'Salvar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setOpen: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  ross: PropTypes.objectOf(PropTypes.object),
  setRoss: PropTypes.func.isRequired,
  setRefresh: PropTypes.func,
};

Handle.defaultProps = {
  ross: null,
  setRefresh: false,
};
