import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 10vh 90vh;
  grid-template-areas:
    'a m'
    'a m';

  color: #fff;

  header {
    grid-area: h;
    height: 100%;
  }

  aside {
    display: flex;
    flex-direction: column;
    grid-area: a;
    height: 100%;
    background: #5781c0;

    > div {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      > h2 {
        display: flex;
        font-size: 24px;
        margin-left: 20px;
        margin-top: 6px;
      }
    }

    hr {
      size: 2px;
      margin: 15px 10px;
    }

    img {
      width: 100px;
      height: 100px;
      margin: 0 30px;
      border-radius: 50%;
      border: 4px solid #fff;
    }

    > div {
      display: flex;
      align-items: center;

      h1 {
        font-size: 20px;
      }

      span {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  main {
    grid-area: m;
    height: 100%;

    color: #333;
  }
`;

export const MenuList = styled.ul`
  flex: 0 0 20%;
  margin-top: 50px;
  height: 100%;

  li {
    display: flex;
    margin-top: 10px;

    button {
      width: 90%;
      height: 45px;
      padding: 0 10px;
      border: transparent;
      border-radius: 4px;
      background: none;
      color: #fff;
      margin-left: 20px;
      transition: background 0.2s;
      outline: none;

      &:hover {
        transform: translateY(-3px);
        background: ${darken(0.08, '#5781c0')};
      }

      > div {
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
`;

export const MainPage = styled.div``;
