import React, { useEffect, useState } from 'react';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';

import { Container, Users, Transactions } from './styles';

export default function Main() {
  const [users, setUsers] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [expireds, setExpireds] = useState([]);
  const [daySubscribers, setDaySubscribers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [valTransactions, setValTransactions] = useState(0);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('/users/all');

      setUsers(response.data);

      const usersSubscribed = response.data.filter(
        subscribed => subscribed.expired === false
      );
      setSubscribers(usersSubscribed);

      const usersExpireds = response.data.filter(
        expired => expired.expired === true
      );
      setExpireds(usersExpireds);

      const currentDate = format(new Date(), 'dd-MM-yyyy');

      const daily = response.data.filter(
        day => format(parseISO(day.createdAt), 'dd-MM-yyyy') === currentDate
      );

      setDaySubscribers(daily);
    }

    loadUsers();
  }, []);

  useEffect(() => {
    async function loadTransactions() {
      const response = await api.get('transactions');

      setTransactions(response.data);

      const value = response.data.reduce((total, transaction) => {
        return total + transaction.amount;
      }, 0);
      setValTransactions(
        value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      );
    }

    loadTransactions();
  }, []);

  return (
    <Container>
      <header>
        <h1>Dados gerais do Avalie Fácil</h1>
      </header>

      <hr />

      <main>
        <Users>
          <ul>
            <li>
              <strong>Usuários cadastrados</strong>

              <div>
                <span>{users.length}</span>
                <p>Usuários</p>
              </div>
            </li>
            <li>
              <strong>Usuários assinantes</strong>

              <div>
                <span>{subscribers.length}</span>
                <p>assinantes</p>
              </div>
            </li>

            <li>
              <strong>Usuários expirados</strong>

              <div>
                <span style={{ color: '#c53030' }}>{expireds.length}</span>
                <p>expirados</p>
              </div>
            </li>

            <li>
              <strong>Usuários do dia</strong>

              <div>
                <span style={{ color: '#0A1CBD' }}>
                  {daySubscribers.length}
                </span>
                <p>usuário</p>
              </div>
            </li>
          </ul>
        </Users>

        <hr />

        <Transactions>
          <h2>Transações</h2>

          <ul>
            <li>
              <strong>Total de Compras do mês (Qtde)</strong>

              <div>
                <span style={{ color: '#0A1CBD' }}>{transactions.length}</span>
                <p>compras</p>
              </div>
            </li>

            <li>
              <strong>Total de Compras do mês(R$)</strong>

              <div>
                <span style={{ color: '#26ce46' }}>{valTransactions}</span>
              </div>
            </li>
          </ul>
        </Transactions>
      </main>
    </Container>
  );
}
