import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    border-bottom: 2px solid #fa923f;
  }
`;

export const Plans = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 1.5rem;
  margin: 0.8rem auto;
`;

export const Card = styled.li`
  width: 20rem;
  height: 25rem;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  cursor: pointer;

  perspective: 150rem;
  --moz-perspective: 150rem;

  .card_side {
    font-size: 2rem;
    height: 25rem;
    width: 100%;
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;

    transition: all 0.8s ease;

    display: flex;
    flex-direction: column;
    align-items: column;

    padding: 8px;

    &--front {
      //background-image: linear-gradient(to right bottom, #fff, #fa923f);
      background: #fff;

      > img {
        margin-top: 8px;
        height: 2rem;
      }
    }

    &--back {
      background-image: linear-gradient(to right bottom, #0d2c5b, #333);
      transform: rotateY(180deg);

      > img {
        width: 50%;
        align-self: center;
      }
    }
  }

  &:hover .card_side--front {
    transform: rotateY(-180deg);
  }

  &:hover .card_side--back {
    transform: rotateY(0);
  }

  hr {
    background-color: #fa923f;
    height: 2px;
    opacity: 0.5;
  }
`;

export const PlanDescription = styled.div`
  margin-top: 8px;
  height: 100%;
  color: #333;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
    height: 100%;
  }

  ${props =>
    !props.type &&
    css`
      color: #fff;
    `}
`;

export const PtamValues = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 16px;
  }

  small {
    font-size: 14px;
  }

  strong {
    font-size: 24px;
  }

  span {
    font-size: 12px;
  }
`;

export const PaymentOptions = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 24px;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  a {
    width: 15rem;
    height: 45px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: space-around;

    transition: all 0.2s;
    color: #333;

    img {
      width: 3.5rem;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  h1 {
    font-size: 18px;
  }

  span {
    color: #fff;
    font-size: 14px;
  }

  button {
    width: 15rem;
    height: 45px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 22px;
    border: none;
    color: #333;

    transition: all 0.3s;

    &:hover {
      transform: scale(1.08);
    }
  }
`;

export const Payments = styled.section`
  width: 100%;
  text-align: center;

  ul {
    width: 100%;
  }
`;

export const PaymentItem = styled.li`
  background: rgba(250, 146, 63, 0.08);
  border-radius: 4px;
  height: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & + li {
    margin-top: 16px;
  }

  label {
    width: 100%;
    height: 100%;
    margin: 0 !important;

    p {
      color: #333;
      margin-bottom: 0 !important;
    }
  }
`;
