import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  } catch (err) {
    const { email } = payload;

    toast.error(err.response.data.error);

    if (err.response.data.error === 'Email não validado, solicitar token!') {
      history.push('/validmail', { email });
    }

    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { cpf, name, creci, email, mobile, password } = payload;

    yield call(api.post, 'users', {
      cpf,
      name,
      creci,
      email,
      mobile,
      password,
    });

    toast.success(
      'Usuário cadastrado com sucesso, você será redirecionado para a tela de validação do email!'
    );

    history.push('/validmail');
  } catch (err) {
    toast.error(err.response.data.error);

    yield put(signFailure());
    throw new Error('Erro ao cadastrar novo usuário!');
  }
}

/* funcao que seta o token de autorização de rota */
export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* signOut({ payload }) {
  const { id } = payload;

  yield api.post('/login-ctrl', {
    id,
  });

  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
