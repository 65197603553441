import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdOpenWith } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../services/api';

import { areasSuccess } from '../../../store/modules/areas/actions';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, Body } from './styles';

export default function Areas({ setFlag, flag }) {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [area, setArea] = useState({
    id: null,
    terreno: 0,
    construido: 0,
    privado: 0,
    comum: 0,
    total: 0,
    fracao: 0,
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadAreas() {
      const response = await api.get(`/areas/${ptam.id_area}`);

      if (response.data) {
        setArea(response.data);
        setLoading(false);
      }
      setRefresh(false);
    }

    if (ptam.id_area) {
      setLoading(true);
      loadAreas();
    }
  }, [refresh]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/areas/${area.id}`);

      setArea({
        id: null,
        terreno: 0,
        construido: 0,
        privado: 0,
        comum: 0,
        total: 0,
        fracao: 0,
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(areasSuccess(null));

      setFlag({ ...flag, areas: false });
      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          area={area}
          setArea={setArea}
          setEdit={setEdit}
          setRefresh={setRefresh}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir as Áreas do Imóvel?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão das Áreas atual ela não estará mais disponível
              para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdOpenWith size={20} color="#fa923f" />
          <h1>Áreas do Imóvel</h1>
        </div>
        <div>
          {!ptam.id_area ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Áreas</span>
              </div>
            </button>
          ) : null}

          {ptam.id_area && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Áreas</span>
              </div>
            </button>
          )}

          {ptam.id_area && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Áreas</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados das Áreas do Imóvel</h1>

        <strong>Área do Terreno</strong>
        <span>{area.terreno} m²</span>

        <strong>Área Construída</strong>
        <span>{area.construido} m²</span>

        <strong>Área Privativa</strong>
        <span>{area.privado} m²</span>

        <strong>Área Comum</strong>
        <span>{area.comum} m²</span>

        <strong>Área Fracionada</strong>
        <span>{area.fracao} m²</span>

        <strong>Área Total</strong>
        <span>{area.total} m²</span>

        <hr />
      </Body>
    </Container>
  );
}

Areas.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
