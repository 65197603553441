/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import image2base64 from 'image-to-base64';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import apiCep from '../../../../services/cep';

import { situationSuccess } from '../../../../store/modules/situation/actions';

import { Container, Form } from './styles';

import details from '../../../../assets/ross_detalhes.pdf';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

let address = '';
let url = '';

const APIKey = 'AIzaSyDiC6rQoj3-ilf8xvYr-DGa5ftrUF9AqgU';
const zoom = 16;

export default function Handle({
  open,
  setModal,
  edit,
  situacao,
  setRefresh,
  setSituacao,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();
  const [tipos, setTipos] = useState([]);
  const [disable, setDisable] = useState(true);
  const [label, setLabel] = useState('');

  /* seleção dos tipos de imoveis */
  useEffect(() => {
    async function SelectTipos() {
      const response = await api.get('tipo');

      if (response) {
        const data = response.data.map(tipo => ({
          ...tipo,
          descricao: tipo.descricao,
        }));

        setTipos(data);
      }
    }
    SelectTipos();
  }, []);

  async function buscaCep(e, tipo) {
    const CEPvalue = e.target.value;

    const response = await apiCep.get(`${CEPvalue}/json`);

    /* Proprietário */
    if (tipo === 'P') {
      setSituacao({
        ...situacao,
        proprietario_cep: CEPvalue,
        proprietario_endereco: response.data.logradouro,
        proprietario_complemento: response.data.complemento,
        proprietario_bairro: response.data.bairro,
        proprietario_estado: response.data.uf,
        proprietario_cidade: response.data.localidade,
      });
    }

    /* Imovel */
    if (tipo === 'I') {
      setSituacao({
        ...situacao,
        cep_imovel: CEPvalue,
        endereco_imovel: response.data.logradouro,
        complemento_imovel: response.data.complemento,
        bairro_imovel: response.data.bairro,
        estado_imovel: response.data.uf,
        cidade_imovel: response.data.localidade,
      });
    }
  }

  function TextMaskCEPP(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        onBlur={e => buscaCep(e, 'P')}
      />
    );
  }

  TextMaskCEPP.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  function TextMaskCEPI(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        onBlur={e => buscaCep(e, 'I')}
      />
    );
  }

  TextMaskCEPI.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  async function handleSave() {
    if (situacao.idade_imovel === '') {
      toast.error(
        'A IDADE DO IMÓVEL não pode estar em branco, informe um valor ou 0!'
      );
      return;
    }

    if (!situacao.proprietario_nome) {
      toast.error('O NOME do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.proprietario_cep) {
      toast.error('O CEP do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.proprietario_endereco) {
      toast.error('O ENDEREÇO do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.proprietario_numero) {
      toast.error(
        'O NUMERO do endereço do proprietário precisa ser preenchido !'
      );
      return;
    }

    if (!situacao.proprietario_bairro) {
      toast.error('O BAIRRO do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.proprietario_estado) {
      toast.error('O ESTADO do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.proprietario_cidade) {
      toast.error('A CIDADE do proprietário precisa ser preenchido !');
      return;
    }

    if (!situacao.tipo_imovel) {
      toast.error('O TIPO do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.utilizacao) {
      toast.error('A UTILIZAÇÃO precisa ser preenchido !');
      return;
    }

    if (!situacao.cep_imovel) {
      toast.error('O CEP do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.endereco_imovel) {
      toast.error('O ENDEREÇO do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.numero_imovel) {
      toast.error('O NUMERO do endereço do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.bairro_imovel) {
      toast.error('O BAIRRO do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.estado_imovel) {
      toast.error('O ESTADO do imóvel precisa ser preenchido !');
      return;
    }

    if (!situacao.cidade_imovel) {
      toast.error('A CIDADE do imóvel precisa ser preenchido !');
      return;
    }

    if (situacao.use_ross === 'Sim') {
      if (situacao.idade_imovel === 0) {
        toast.error(
          'Para uso da depreciação ROSS HEIDECKE é obrigatório o preenchimento do campo IDADE DO IMÓVEL!'
        );
        return;
      }
    }

    try {
      const response = edit
        ? await api.put('/situations', situacao)
        : await api.post('/situations', situacao);

      const { id } = response.data;

      dispatch(situationSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setModal(false);
    setRefresh(true);
    setFlag({ ...flag, situacao: true });
  }

  function handleTipo(tipo_imovel) {
    if (tipo_imovel !== '') {
      const data = tipos.filter(obj => obj.descricao === tipo_imovel);

      setSituacao({ ...situacao, vida_util: data[0].vida_util });

      if (tipo_imovel === 'Outros') {
        setDisable(false);
        setLabel(
          'Se deseja utilizar a depreciação da tabela Ross Heidecke neste PTAM preencha os campos abaixo (ambos são necessários). Caso não necessite utilizar, informe 0 nestes campos.'
        );
      } else {
        setLabel(
          'Se deseja utilizar a depreciação da tabela Ross Heidecke neste PTAM preencha o campo "Idade Aparente" abaixo. Caso não necessite utilizar, informe 0 neste campo.'
        );
      }
    }
  }

  /*
    fiz a inclusao no state nesse ponto, considerando que o endereço já esteja colocado (veio do cep),
    depois que usuário digitar o numero ai ele vai entrar nessa função criar a variavel "address"
    o setSituacao precisa estar dentro da promisse .then pois é nesse ponto que ele possui o valor do base64
    se deu certo ele atualiza o state.
  */
  async function handleURL(opc, valor) {
    if (opc === 1) {
      address = `${valor},${situacao.numero_imovel} ${situacao.bairro_imovel} ${situacao.cidade_imovel}-${situacao.estado_imovel}`;
    } else {
      address = `${situacao.endereco_imovel},${valor} ${situacao.bairro_imovel} ${situacao.cidade_imovel}-${situacao.estado_imovel}`;
    }

    url = `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=${zoom}&size=600x300&maptype=roadmap&markers=color:red%7Clabel:%7C${address}&key=${APIKey}`;

    await image2base64(url)
      .then(response => {
        setSituacao({
          ...situacao,
          mapa_localizacao: response,
        });
      })
      .catch(error => {
        toast.error(error);
      });
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados da Situação &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://youtu.be/7go8NUlm4p8"
                  >
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados da
              Situação do imóvel. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
              OBRIGATÓRIOS!!
            </DialogContentText>
            <Form>
              <div className="side1">
                <h1>Dados do Proprietário</h1>
                <label htmlFor="proprietario_nome">
                  Nome
                  <input
                    required
                    id="proprietario_nome"
                    type="text"
                    value={situacao.proprietario_nome}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_nome: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="proprietario_cep">
                  CEP
                  <MaskedInput
                    required
                    id="proprietario_cep"
                    value={situacao.proprietario_cep}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_cep: e.target.value,
                      })
                    }
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    onBlur={e => buscaCep(e, 'P')}
                  />
                </label>
                <label htmlFor="proprietario_endereco">
                  Endereço
                  <input
                    required
                    id="proprietario_endereco"
                    label="Endereço"
                    type="text"
                    value={situacao.proprietario_endereco}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_endereco: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="proprietario_numero">
                  Numero
                  <input
                    required
                    id="proprietario_numero"
                    type="text"
                    value={situacao.proprietario_numero}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_numero: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="proprietario_complemento">
                  Complemento
                  <input
                    id="proprietario_complemento"
                    type="text"
                    value={situacao.proprietario_complemento}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_complemento: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="proprietario_bairro">
                  Bairro
                  <input
                    required
                    id="proprietario_bairro"
                    type="text"
                    value={situacao.proprietario_bairro}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_bairro: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="proprietario_cidade">
                  Cidade
                  <input
                    required
                    id="proprietario_cidade"
                    type="text"
                    value={situacao.proprietario_cidade}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_cidade: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="estado">
                  Estado
                  <select
                    required
                    id="estado"
                    value={situacao.proprietario_estado}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        proprietario_estado: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernanbuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="**">Exterior</option>
                  </select>
                </label>
                <br />
                <br />
                <h1>Especificação do Imóvel</h1>
                <label htmlFor="tipo_imovel">
                  Tipo Imóvel
                  <select
                    required
                    id="tipo_imovel"
                    value={situacao.tipo_imovel}
                    onChange={e =>
                      setSituacao({ ...situacao, tipo_imovel: e.target.value })
                    }
                    onBlur={e => handleTipo(e.target.value)}
                  >
                    <option value="" />
                    {tipos.map(tipo => (
                      <option key={tipo.id} value={tipo.descricao}>
                        {tipo.descricao}
                      </option>
                    ))}
                  </select>
                </label>
                <label htmlFor="tipo_imovel_outro">
                  Outros Tipos (Especificar)
                  <input
                    id="tipo_imovel_outro"
                    type="text"
                    value={situacao.tipo_imovel_outro}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        tipo_imovel_outro: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="agrupamento">
                  Agrupamento
                  <select
                    id="agrupamento"
                    value={situacao.agrupamento}
                    onChange={e =>
                      setSituacao({ ...situacao, agrupamento: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="Complexo Industrial">
                      Complexo Industrial
                    </option>
                    <option value="Condomínio de Casas">
                      Condomínio de Casas
                    </option>
                    <option value="Conjunto de Prédios Comerciais">
                      Conjunto de Prédios Comerciais
                    </option>
                    <option value="Conjunto de Salas Comerciais">
                      Conjunto de Salas Comerciais
                    </option>
                    <option value="Conjunto de Unidades Comerciais">
                      Conjunto de Unidades Comerciais
                    </option>
                    <option value="Conjunto Habitacional (Casas)">
                      Conjunto Habitacional (Casas)
                    </option>
                    <option value="Conjunto Habitacional (Casas e Prédios)">
                      Conjunto Habitacional (Casas e Prédios)
                    </option>
                    <option value="Conjunto Habitacional (Prédios)">
                      Conjunto Habitacional (Prédios)
                    </option>
                    <option value="Loteamento">Loteamento</option>
                    <option value="Prédio Comercial">Prédio Comercial</option>
                    <option value="Prédio de Apartamentos">
                      Prédio de Apartamentos
                    </option>
                    <option value="Outros">Outros (Especificar Abaixo)</option>
                  </select>
                </label>
                <label htmlFor="agrupamento_outro">
                  Outros Agrupamentos (Especificar)
                  <input
                    id="agrupamento_outro"
                    type="text"
                    value={situacao.agrupamento_outro}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        agrupamento_outro: e.target.value,
                      })
                    }
                  />
                </label>
                <label htmlFor="utilizacao">
                  Utilização
                  <select
                    required
                    id="utilizacao"
                    value={situacao.utilizacao}
                    onChange={e =>
                      setSituacao({ ...situacao, utilizacao: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="Residencial">Residêncial</option>
                    <option value="Comercial">Comercial</option>
                    <option value="Industrial">Industrial</option>
                    <option value="Institucional">Institucional</option>
                    <option value="Misto">Misto (Especificar Abaixo)</option>
                  </select>
                </label>
                <label htmlFor="utilizacao_outro">
                  Outra Utilização (Especificar)
                  <input
                    id="utilizacao_outro"
                    type="text"
                    value={situacao.utilizacao_outro}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        utilizacao_outro: e.target.value,
                      })
                    }
                  />
                </label>
                <a href={details} target="_blank" rel="noreferrer">
                  <strong>Ross Heidecke (clique p/ detalhes)</strong>
                </a>
                &nbsp;&nbsp;
                <HtmlTooltip
                  interactive
                  title={
                    <>
                      <Typography color="inherit">
                        Depreciação Ross Heidecke?
                      </Typography>
                      <em>{label}</em> <br /> Mais detalhes{' '}
                      <a
                        href="https://avaliefacil.blogspot.com/2020/08/tabela-ross-heidecke-onde-como-e-quando.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        clique aqui
                      </a>
                    </>
                  }
                >
                  <HelpOutlineIcon />
                </HtmlTooltip>
                <label htmlFor="ross">
                  Utilizar Depreciação Ross Heidecke
                  <select
                    id="ross"
                    value={situacao.use_ross}
                    onChange={e =>
                      setSituacao({ ...situacao, use_ross: e.target.value })
                    }
                  >
                    <option value="Nao">Não</option>
                    <option value="Sim">Sim</option>
                  </select>
                </label>
                <label htmlFor="idade_imovel">
                  Idade Imóvel (Aparente)
                  <input
                    id="idade_imovel"
                    type="number"
                    value={situacao.idade_imovel}
                    onChange={e =>
                      setSituacao({ ...situacao, idade_imovel: e.target.value })
                    }
                  />
                </label>
                <label htmlFor="vida_util">
                  Vida Útil do Imóvel
                  <input
                    id="vida_util"
                    type="number"
                    value={situacao.vida_util}
                    disabled={disable}
                    onChange={e =>
                      setSituacao({ ...situacao, vida_util: e.target.value })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <h1>Endereço do Imóvel Avaliado</h1>

                <label htmlFor="cep_imovel">
                  CEP
                  <MaskedInput
                    required
                    id="cep_imovel"
                    value={situacao.cep_imovel}
                    onChange={e =>
                      setSituacao({ ...situacao, cep_imovel: e.target.value })
                    }
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    onBlur={e => buscaCep(e, 'I')}
                  />
                </label>

                <label htmlFor="endereco_imovel">
                  Endereço
                  <input
                    required
                    id="endereco_imovel"
                    type="text"
                    value={situacao.endereco_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        endereco_imovel: e.target.value,
                      })
                    }
                    onBlur={e => handleURL(1, e.target.value)}
                  />
                </label>

                <label htmlFor="numero_imovel">
                  Número
                  <input
                    required
                    id="numero_imovel"
                    type="text"
                    value={situacao.numero_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        numero_imovel: e.target.value,
                      })
                    }
                    onBlur={e => handleURL(2, e.target.value)}
                  />
                </label>

                <label htmlFor="complemento_imovel">
                  Complemento
                  <input
                    id="complemento_imovel"
                    type="text"
                    value={situacao.complemento_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        complemento_imovel: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="bairro_imovel">
                  Bairro
                  <input
                    required
                    id="bairro_imovel"
                    type="text"
                    value={situacao.bairro_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        bairro_imovel: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="cidade_imovel">
                  Cidade
                  <input
                    required
                    id="cidade_imovel"
                    type="text"
                    value={situacao.cidade_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        cidade_imovel: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="estado_imovel">
                  Estado
                  <select
                    required
                    id="estado_imovel"
                    value={situacao.estado_imovel}
                    onChange={e =>
                      setSituacao({
                        ...situacao,
                        estado_imovel: e.target.value,
                      })
                    }
                  >
                    <option value="" />
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernanbuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="**">Exterior</option>
                  </select>
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  situacao: PropTypes.objectOf(PropTypes.object),
  setRefresh: PropTypes.func,
  setSituacao: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  situacao: null,
  setRefresh: false,
  setSituacao: null,
};
