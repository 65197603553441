import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../services/api';

import { Container, PlanList, Header, TermsDescription } from './styles';

export default function Plans() {
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    async function loadPlans() {
      const response = await api.get('signatures');

      const data = response.data.map(plan => ({
        ...plan,
        formatedValue: plan.valor_total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        formatedParc: plan.vlr_parcela.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        formatedLiq: (plan.vlr_parcela * plan.parcelas)
          .toFixed(2)
          .replace('.', ','),

        formatedDesc: plan.desconto.toLocaleString('pt-BR'),
      }));

      setSignatures(data);
    }

    loadPlans();
  }, []);

  return (
    <Container>
      <Header>
        <h1>Nossos planos de assinatura</h1>
        <hr />
      </Header>
      <TermsDescription>
        <span>
          Temos diversos planos, escolha um que se encaixa em suas necessidades,
          ganhe descontos progressivos que podem chegar em até 10%. Escolha o
          seu e assine já, venha fazer parte do seleto grupo de avaliadores que
          fazem a diferença no mercado imobiliário.
        </span>
      </TermsDescription>
      <PlanList>
        <li>
          <div>
            <div className="titulo">
              <h1>Individual</h1>
            </div>
            <div className="valor">
              <strong>R$ 19,90</strong>
            </div>
            <hr />
            <div className="descri">1 PTAM Completo</div>
            <div className="desconto">
              <br />
            </div>
            <div className="vista">
              Valor a vista: <b>R$ 19,90</b>
              <br />
            </div>
            <div className="detail" />
            <div className="btn-cielo">
              <form
                method="post"
                action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
                target="blank"
              >
                <input
                  type="hidden"
                  name="id"
                  value="a63d9e75-24a2-4089-b8bb-d08bf5aac725"
                />
                <input
                  type="image"
                  name="submit"
                  alt="Comprar"
                  src="https://cieloecommerce.cielo.com.br/backoffice/Content/img/buttons/button-5-1.png"
                />
              </form>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="titulo">
              <h1>Mensal</h1>
            </div>
            <div className="valor">
              <strong>R$ 49,90</strong>
            </div>
            <hr />
            <div className="descri">PTAMS Ilimitados</div>
            <div className="desconto">
              <br />
            </div>
            <div className="vista">
              Valor a vista: <b>R$ 49,90</b>
              <br />
            </div>
            <div className="detail" />
            <div className="btn-cielo">
              <form
                method="post"
                action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
                target="blank"
              >
                <input
                  type="hidden"
                  name="id"
                  value="a63d9e75-24a2-4089-b8bb-d08bf5aac725"
                />
                <input
                  type="image"
                  name="submit"
                  alt="Comprar"
                  src="https://cieloecommerce.cielo.com.br/backoffice/Content/img/buttons/button-5-1.png"
                />
              </form>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="titulo">
              <h1>Trimestral</h1>
            </div>
            <div className="valor">
              <strong>R$ 149,70</strong>
            </div>
            <hr />
            <div className="descri">PTAMS Ilimitados</div>
            <div className="descri">
              <b>Desconto de 5%</b>
            </div>
            <div className="vista">
              Valor a vista: <b>R$ 142,20</b>
            </div>
            <div className="detail">ou em até 3 parcelas de R$ 47,40</div>
            <div className="btn-cielo">
              <form
                method="post"
                action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
                target="blank"
              >
                <input
                  type="hidden"
                  name="id"
                  value="d9036349-8674-45d6-813d-111942cfa145"
                />
                <input
                  type="image"
                  name="submit"
                  alt="Comprar"
                  src="https://cieloecommerce.cielo.com.br/backoffice/Content/img/buttons/button-5-1.png"
                />
              </form>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="titulo">
              <h1>Semestral</h1>
            </div>
            <div className="valor">
              <strong>R$ 299,40</strong>
            </div>
            <hr />
            <div className="descri">PTAMS Ilimitados</div>
            <div className="descri">
              <b>Desconto de 7,5%</b>
            </div>
            <div className="vista">
              Valor a vista: <b>R$ 276,90</b>
            </div>
            <div className="detail">ou em até 6 parcelas de R$ 46,15</div>
            <div className="btn-cielo">
              <form
                method="post"
                action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
                target="blank"
              >
                <input
                  type="hidden"
                  name="id"
                  value="a96ef2de-2c4e-4602-a66c-ddab162f586a"
                />
                <input
                  type="image"
                  name="submit"
                  alt="Comprar"
                  src="https://cieloecommerce.cielo.com.br/backoffice/Content/img/buttons/button-5-1.png"
                />
              </form>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="titulo">
              <h1>Anual</h1>
            </div>
            <div className="valor">
              <strong>R$ 598,80</strong>
            </div>
            <hr />
            <div className="descri">PTAMS Ilimitados</div>
            <div className="descri">
              <b>Desconto de 10%</b>
            </div>
            <div className="vista">
              Valor a vista: <b>R$ 538,90</b>
            </div>
            <div className="detail">ou em até 12 parcelas de R$ 44,90</div>
            <div className="btn-cielo">
              <form
                method="post"
                action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
                target="blank"
              >
                <input
                  type="hidden"
                  name="id"
                  value="b05ef3e9-6459-4849-998c-251d5deec839"
                />
                <input
                  type="image"
                  name="submit"
                  alt="Comprar"
                  src="https://cieloecommerce.cielo.com.br/backoffice/Content/img/buttons/button-5-1.png"
                />
              </form>
            </div>
          </div>
        </li>
      </PlanList>
      <Link to="/">Voltar a página principal</Link>
    </Container>
  );
}
