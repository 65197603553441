export function improvementRequest({
  caseiro,
  banheiro_auxiliar,
  bar,
  churrasqueira,
  cozinha,
  lavabo,
  quartos,
  quiosque,
  sala_estar,
  sala_tv,
  area_servico,
  banheiros,
  canil,
  copa,
  home_teather,
  piscina,
  quarto_empregada,
  varanda,
  sala_jantar,
  suites,
  descricao_imovel,
  id_user,
  id_ptam,
}) {
  return {
    type: '@improvement/IMPROVEMENT_REQUEST',
    payload: {
      caseiro,
      banheiro_auxiliar,
      bar,
      churrasqueira,
      cozinha,
      lavabo,
      quartos,
      quiosque,
      sala_estar,
      sala_tv,
      area_servico,
      banheiros,
      canil,
      copa,
      home_teather,
      piscina,
      quarto_empregada,
      varanda,
      sala_jantar,
      suites,
      descricao_imovel,
      id_user,
      id_ptam,
    },
  };
}

export function improvementUpdate({
  id,
  caseiro,
  banheiro_auxiliar,
  bar,
  churrasqueira,
  cozinha,
  lavabo,
  quartos,
  quiosque,
  sala_estar,
  sala_tv,
  area_servico,
  banheiros,
  canil,
  copa,
  home_teather,
  piscina,
  quarto_empregada,
  varanda,
  sala_jantar,
  suites,
  descricao_imovel,
  id_user,
  id_ptam,
}) {
  return {
    type: '@improvement/IMPROVEMENT_UPDATE',
    payload: {
      id,
      caseiro,
      banheiro_auxiliar,
      bar,
      churrasqueira,
      cozinha,
      lavabo,
      quartos,
      quiosque,
      sala_estar,
      sala_tv,
      area_servico,
      banheiros,
      canil,
      copa,
      home_teather,
      piscina,
      quarto_empregada,
      varanda,
      sala_jantar,
      suites,
      descricao_imovel,
      id_user,
      id_ptam,
    },
  };
}

export function improvementDelete(id) {
  return {
    type: '@improvement/IMPROVEMENT_DELETE',
    payload: { id },
  };
}

export function improvementLoad(id) {
  return {
    type: '@improvement/IMPROVEMENT_LOAD',
    payload: { id },
  };
}

export function improvementSuccess(improvement_id) {
  return {
    type: '@improvement/IMPROVEMENT_SUCCESS',
    payload: { improvement_id },
  };
}

export function improvementFailure() {
  return {
    type: '@improvement/IMPROVEMENT_FAILURE',
  };
}
