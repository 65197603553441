import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../../services/api';
import { improvementSuccess } from '../../../../../store/modules/benfeitorias/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  benfeitoria,
  setBenfeitoria,
  setEdit,
}) {
  const dispatch = useDispatch();

  async function handleSave() {
    try {
      const response = edit
        ? await api.put('/improvements', benfeitoria)
        : await api.post('/improvements', benfeitoria);

      const { id } = response.data;

      dispatch(improvementSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setModal(false);
    setEdit(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados das Benfeitorias do Imóvel &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a target="_blank" href="https://youtu.be/UgR_yGVMhoI">
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados das
              Benfeitorias do imóvel.
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="quartos">
                  Nº de Quartos
                  <input
                    autoFocus
                    id="quartos"
                    type="number"
                    value={benfeitoria.quartos}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        quartos: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="suites">
                  Nº de Suítes
                  <input
                    id="suites"
                    type="number"
                    value={benfeitoria.suites}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        suites: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="banheiro">
                  Nº de Banheiros
                  <input
                    id="banheiro"
                    type="number"
                    value={benfeitoria.banheiros}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        banheiros: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="lavabo">
                  Nº de Lavabos
                  <input
                    id="lavabo"
                    type="number"
                    value={benfeitoria.lavabo}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        lavabo: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="cozinha">
                  Nº de Cozinhas
                  <input
                    id="cozinha"
                    type="number"
                    value={benfeitoria.cozinha}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        cozinha: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="copa">
                  Nº de Copas
                  <input
                    id="copa"
                    type="number"
                    value={benfeitoria.copa}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        copa: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="area_servico">
                  Nº de Áreas de Serviço
                  <input
                    id="area_servico"
                    type="number"
                    value={benfeitoria.area_servico}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        area_servico: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="sala_estar">
                  Nº de Salas de Estar
                  <input
                    id="sala_estar"
                    type="number"
                    value={benfeitoria.sala_estar}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        sala_estar: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="sala_tv">
                  Nº de Salas de TV
                  <input
                    id="sala_tv"
                    type="number"
                    value={benfeitoria.sala_tv}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        sala_tv: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="sala_jantar">
                  Nº de Salas de Jantar
                  <input
                    id="sala_jantar"
                    type="number"
                    value={benfeitoria.sala_jantar}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,

                        sala_jantar: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="varanda">
                  Nº de Varandas
                  <input
                    id="varanda"
                    type="number"
                    value={benfeitoria.varanda}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        varanda: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="caseiro">
                  Nº de Acomodações para Caseiro
                  <input
                    id="caseiro"
                    type="number"
                    value={benfeitoria.caseiro}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        caseiro: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="banheiro_auxiliar">
                  Nº de Banheiros Auxiliares
                  <input
                    id="banheiro_auxiliar"
                    type="number"
                    value={benfeitoria.banheiro_auxiliar}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        banheiro_auxiliar: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="bar">
                  Nº de Bares
                  <input
                    id="bar"
                    type="number"
                    value={benfeitoria.bar}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        bar: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="churrasqueira">
                  Nº de Churrasqueira
                  <input
                    id="churrasqueira"
                    type="number"
                    value={benfeitoria.churrasqueira}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        churrasqueira: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="quiosque">
                  Nº de Quiosques
                  <input
                    id="quiosque"
                    type="number"
                    value={benfeitoria.quiosque}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        quiosque: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="canil">
                  Nº de Canís
                  <input
                    id="canil"
                    type="number"
                    value={benfeitoria.canil}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        canil: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="home_teather">
                  Nº de Home Teathers
                  <input
                    id="home_teather"
                    type="number"
                    value={benfeitoria.home_teather}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        home_teather: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="piscina">
                  Nº de Piscinas
                  <input
                    id="piscina"
                    type="number"
                    value={benfeitoria.piscina}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        piscina: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="quarto_empregada">
                  Nº de Quartos de Empregada
                  <input
                    id="quarto_empregada"
                    type="number"
                    value={benfeitoria.quarto_empregada}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        quarto_empregada: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="descricao_imovel">
                  Descrição do Imóvel
                  <textarea
                    id="descricao_imovel"
                    type="text"
                    value={benfeitoria.descricao_imovel}
                    onChange={e =>
                      setBenfeitoria({
                        ...benfeitoria,
                        descricao_imovel: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  benfeitoria: PropTypes.objectOf(PropTypes.object),
  setBenfeitoria: PropTypes.func,
  setEdit: PropTypes.func,
};

Handle.defaultProps = {
  benfeitoria: null,
  setBenfeitoria: null,
  setEdit: null,
};
