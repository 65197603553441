import produce from 'immer';

const INITIAL_STATE = {
  image: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/INPUT_IMAGE_SUCCESS': {
        draft.image = action.payload.image.id;
        break;
      }

      case '@ptam/PTAM_CLEAR': {
        draft.image = null;
        break;
      }

      default:
    }
  });
}
