import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import api from '../../../services/api';
import { Container } from './styles';

import avatar from '../../../assets/avatar.png';

export default function AvatarInput({ profile, setProfile }) {
  const { defaultValue, registerField } = useField('avatar');
  const ref = useRef();

  const [file, setFile] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.base64);

  const user = useSelector(state => state.user.profile);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }

    if (profile.avatar_id) {
      setPreview(user.avatar ? user.avatar.base64 : '');
    }
  }, [ref]);

  async function handleChange(e) {
    const data = new FormData();

    /* criação do req.file */
    data.append('file', e.target.files[0]);

    /* envio do arquivo para a rota files */
    const response = await api.post('files', data);

    const { id, base64 } = response.data;

    setFile(id);
    setProfile({ ...profile, avatar_id: id });
    setPreview(base64);
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <img src={`data:image/jpeg;base64,${preview}` || avatar} alt="Name" />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}

AvatarInput.propTypes = {
  setProfile: PropTypes.func,
  profile: PropTypes.oneOfType([PropTypes.object]),
};

AvatarInput.defaultProps = {
  setProfile: null,
  profile: null,
};
