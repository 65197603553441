import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(to right bottom, #0d2c5b, #333);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 90%;
  border-radius: 4px;
  background: #fff;

  header {
    display: flex;
    align-items: center;

    border-bottom: 2px solid #fa923f;
    height: 80px;

    @media only screen and (max-width: 62.5em) {
      height: 25vh;
    }

    img {
      width: 150px;
      height: 150px;
      margin-left: 16px;

      @media only screen and (max-width: 62.5em) {
        display: none;
      }
    }

    h2 {
      margin-bottom: 0 !important;
      font-size: 24px;
      margin-right: 24px;

      @media only screen and (max-width: 106.25em) {
        display: none;
      }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      @media only screen and (max-width: 75em) {
        width: 80%;
      }

      @media only screen and (max-width: 62.5em) {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        align-self: center;
      }

      input,
      select {
        margin-right: 16px;

        @media only screen and (max-width: 62.5em) {
          margin-top: 8px;
          width: 90%;
          margin-right: 0;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 120px;
        height: 40px;
        color: #fff;

        background: #fa923f;
        border: 0;
        border-radius: 4px;
        transition: 0.2s;
        margin-left: 10px;
        outline: none;
        transition: all 0.2s !important;

        @media only screen and (max-width: 62.5em) {
          width: 90%;
          margin: 0;
          margin-top: 8px;
        }

        &:hover {
          transform: scale(1.08);
        }

        svg {
          margin-right: 16px;
        }
      }
    }

    > a {
      text-decoration: none;
      margin-right: 24px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  ul {
    margin: 24px auto;

    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    align-items: center;

    padding: 0 16px;
    width: 80%;
  }
`;

export const ListItems = styled.li`
  display: flex;
  justify-self: center;
  flex-direction: column;

  background: rgba(13, 44, 91, 0.06);
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 0 4px #0d2c5b;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 16px 0;
    align-self: center;
    border: 3px solid #fa923f;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  button {
    width: 90%;
    height: 35px;
    margin: 8px 0;
    color: #0d2c5b;

    background: #fa923f;
    border: 0;
    border-radius: 4px;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    align-self: center;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    svg {
      margin-right: 8px;
    }
  }
`;
