/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FiX } from 'react-icons/fi';

import PropTypes from 'prop-types';

import { Scroll } from '../../../components/Scroll';
import { Container, Content, Close, Profile } from './styles';

export default function Details({ broker, setDetail }) {
  return (
    <Container>
      <Content>
        <Close>
          <button type="button" onClick={() => setDetail(false)}>
            <FiX size={22} color="#c53030" />
          </button>
        </Close>

        <img
          src={
            broker.avatar
              ? `data:image/jpeg;base64,${broker.avatar.base64}`
              : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=fff&name=${broker.name}`
          }
          alt={broker.name}
        />

        <Scroll>
          <Profile>
            <label htmlFor="name">
              Nome
              <strong id="name">{broker.name}</strong>
            </label>

            <label htmlFor="email">
              E-mail
              <strong id="email">{broker.email}</strong>
            </label>

            <label htmlFor="mobile">
              Telefone de Contato
              <strong id="mobile">{broker.mobile}</strong>
            </label>

            <label htmlFor="state">
              Cidade / Estado
              <strong id="state">
                {broker.city} / {broker.state}
              </strong>
            </label>
            {broker.usertype === 'Engenheiro(a)' ||
            broker.usertype === 'Arquiteto(a)' ? (
              <label htmlFor="creci">
                {broker.usertype === 'Engenheiro(a)' ? 'Nº CREA' : 'Nº CAU'}
                <strong id="creci">{broker.cnai}</strong>
              </label>
            ) : (
              <>
                <label htmlFor="regiao">
                  Região Creci
                  <strong id="regiao">{broker.regiao_creci}</strong>
                </label>

                <label htmlFor="creci">
                  Nº Creci
                  <strong id="creci">{broker.creci}</strong>
                </label>

                <label htmlFor="cnai">
                  Nº CNAI
                  <strong id="cnai">{broker.cnai}</strong>
                </label>
              </>
            )}

            <br />

            <label htmlFor="qualification">
              Qualificação Profissional
              <strong id="name">{broker.professional_qualification}</strong>
            </label>

            <br />
            <hr />
            <br />

            <label htmlFor="professional">
              Experiência Profissional
              <strong id="professional">
                {broker.professional_experience}
              </strong>
            </label>
          </Profile>
        </Scroll>
      </Content>
    </Container>
  );
}

Details.propTypes = {
  broker: PropTypes.objectOf(PropTypes.object).isRequired,
  setDetail: PropTypes.func.isRequired,
};
