import React from 'react';
import { FaCircle } from 'react-icons/fa';

import { Container } from './styles';

export default function Mobile() {
  return (
    <>
      <Container>
        <FaCircle size={14} color="#DEC600" />
        <strong>Aplicativo</strong>
      </Container>
    </>
  );
}
