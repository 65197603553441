import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  background: #fff;

  button {
    outline: none;
  }
`;
