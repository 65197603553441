import styled from 'styled-components';

export const Abert = styled.div`
  display: grid;
  grid-template-columns: 45% 10% 45%;
  grid-template-areas: 'AbLd1 Middle AbLd2';
  border-radius: 4px;
  padding: 5px;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialised !important;

  .AbLd1 {
    grid-area: AbLd1;
    max-height: 100%;
    margin-left: 10px;
  }

  .Middle {
    grid-area: Middle;
  }

  .AbLd2 {
    grid-area: AbLd2;
    max-height: 100%;
  }

  strong {
    margin-bottom: 20px;
    font-size: min(20px, 2vw);
  }

  li {
    font-size: min(16px, 1.4vw);
    margin-bottom: 10px;
    margin-left: 20px;
    list-style: square;
  }

  p {
    font-size: min(16px, 2vw);
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    h1 {
      margin-top: 10px;
      margin-left: 10px;
      font-size: min(20px, 2vw);
      text-decoration: none;
      color: #0d2c5b;
    }
  }
`;
