import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { tipoFailure } from './actions';

export function* saveTipo({ payload }) {
  try {
    yield call(api.post, 'tipo', payload);

    toast.success('Registro de Tipo Imóvel adicionada com sucesso!');
  } catch (err) {
    toast.error('Erro ao incluir um registro Tipo Imóvel!');
    yield put(tipoFailure());
  }
}

export function* updateTipo({ payload }) {
  try {
    yield call(api.put, 'tipo', payload);

    toast.success('Registro de Tipo Imóvel atualizado com sucesso!');
  } catch (err) {
    toast.error('Erro ao atualizar um registro Tipo Imóvel!');
    yield put(tipoFailure());
  }
}

export function* deleteTipo({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/tipo/${id}`);

    toast.success('Registro de Tipo Imóvel excluído com sucesso!');
  } catch (err) {
    toast.error('Erro ao excluir registro Tipo Imóvel!');
    yield put(tipoFailure());
  }
}

export default all([
  takeLatest('@tipo/REQUEST', saveTipo),
  takeLatest('@tipo/UPDATE', updateTipo),
  takeLatest('@tipo/DELETE', deleteTipo),
]);
