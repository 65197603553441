import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { isBefore } from 'date-fns';

import AuthLayout from '../pages/_layouts/auth';
import NoAuthLayout from '../pages/_layouts/noAuth';
import AdminLayout from '../pages/_layouts/admin';
import NoAuthSignature from '../pages/_layouts/noAuthSignature';
import { signOut } from '../store/modules/auth/actions';

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAdmin,
  isPlans,
  ...rest
}) {
  const dispatch = useDispatch();
  const { signed, token } = store.getState().auth;
  const admin = useSelector(state => state.user.profile);

  /* se a pagina for administrativa e o usuario não for um administrador encaminha para pagina
      de logon */
  if (
    isAdmin &&
    admin.permission !== 'administrador' &&
    admin.permission !== 'funcionario'
  ) {
    toast.error(
      'Você não é um administrador para acessar rotinas administrativas!'
    );
    return <Redirect to="/" />;
  }

  /* se o usuario não estiver logado e tentar acessar uma rota privada
  ele é redirecionado para a tela de login */
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  /* se ele estiver logado e tentar acessar uma rota não privada
  ele é direcionado para o dashboard */
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  /* verifica a validação do token, se estiver expirado limpa os reducers e
    força o usuario a relovar no sistema */
  if (signed) {
    const { exp } = jwtDecode(token);

    const miliseconds = exp * 1000;
    const dateExpiration = new Date(miliseconds);

    const dateValidation = isBefore(new Date().now, dateExpiration);
    if (dateValidation) {
      dispatch(signOut(admin.id));
      localStorage.removeItem('persist:AvalieFacil');

      return <Redirect to="/" />;
    }
  }

  let Layout = null;

  if (isPlans) {
    Layout = NoAuthSignature;
  } else if (signed && !isAdmin) {
    Layout = AuthLayout;
  } else if (!signed && !isAdmin) {
    Layout = NoAuthLayout;
  } else if (isAdmin) {
    Layout = AdminLayout;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isPlans: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdmin: false,
  isPlans: false,
};
