import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  section {
    display: grid;
    grid-template-columns: 30% 70%;
    border: none;
    margin: 0;
    padding: 0;
    margin: 0 auto;

    grid-template-areas:
      'header header'
      'nav bloco1'
      'footer footer';
  }
`;

export const Navigation = styled.nav`
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  grid-area: nav;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 5px;
  margin: 5px;
`;

export const Wrapper = styled.div`
  height: 100vh;
  grid-area: bloco1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    'cab1 cab1'
    'itens1 itens1'
    'footer footer';
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
`;
