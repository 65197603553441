import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import api from '../../../services/api';
import { Container } from './styles';

export default function CompanyInput({ profile, setProfile }) {
  const { defaultValue, registerField } = useField('company');
  const ref = useRef();

  const [file, setFile] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.base64);

  const user = useSelector(state => state.user.profile);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'company_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }

    if (profile.company_id) {
      setPreview(user.company ? user.company.base64 : '');
    }
  }, [ref]);

  async function handleChange(e) {
    const data = new FormData();

    /* criação do req.file */
    data.append('file', e.target.files[0]);

    /* envio do arquivo para a rota files */
    const response = await api.post('files', data);

    const { id, base64 } = response.data;

    setFile(id);
    setProfile({ ...profile, company_id: id });
    setPreview(base64);
  }

  return (
    <Container>
      <label htmlFor="company">
        <img
          src={
            `data:image/jpeg;base64,${preview}` ||
            `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=Empresa`
          }
          alt="Avalie Fácil"
        />

        <input
          type="file"
          id="company"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}

CompanyInput.propTypes = {
  setProfile: PropTypes.func,
  profile: PropTypes.oneOfType([PropTypes.object]),
};

CompanyInput.defaultProps = {
  setProfile: null,
  profile: null,
};
