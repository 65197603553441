export function finalityRequest(finalidade) {
  return {
    type: '@req/FINALITY_REQUEST',
    payload: {
      finalidade,
    },
  };
}

export function finalityUpdate(id, finalidade) {
  return {
    type: '@req/FINALITY_UPDATE',
    payload: {
      id,
      finalidade,
    },
  };
}

export function finalitySuccess(finalidade) {
  return {
    type: '@req/FINALITY_SUCCESS',
    payload: { finalidade },
  };
}

export function finalityFailure() {
  return {
    type: '@req/FINALITY_FAILURE',
  };
}
