import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';

import { Container, Content, Close } from './styles';

const View = ({ data, setView }) => {
  return (
    <Container>
      <Content>
        <h1>Resumo da transação</h1>
        <Close>
          <button type="button" onClick={() => setView(false)}>
            <FiX size={22} color="#c53030" />
          </button>
        </Close>

        <ul>
          <li>
            <label htmlFor="name">
              Nome: <strong id="name">{data.customer_name}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="document">
              Nº CPF: <strong id="document">{data.customer_identity}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="email">
              E-mail: <strong id="email">{data.customer_email}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="purchase">
              Data da Compra: <strong id="purchase">{data.created_date}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="order">
              Nº Ordem: <strong id="order">{data.order_number}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="amount">
              Valor:{' '}
              <strong id="amount">
                {data.amount.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </label>
          </li>

          <li>
            <label htmlFor="checkout">
              Checkout Cielo:{' '}
              <strong id="checkout">{data.checkout_cielo_order_number}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="phone">
              Telefone: <strong id="phone">{data.customer_phone}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="tid">
              TID: <strong id="tid">{data.tid}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="product-id">
              Product ID: <strong id="product-id">{data.product_id}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="sku">
              Nº SKU: <strong id="sku">{data.product_sku}</strong>
            </label>
          </li>

          <li>
            <label htmlFor="description">
              Descrição:{' '}
              <strong id="description">{data.product_description}</strong>
            </label>
          </li>
        </ul>
      </Content>
    </Container>
  );
};

export default View;

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  setView: PropTypes.func.isRequired,
};
