import styled from 'styled-components';

export const Container = styled.div`
  input,
  select,
  textarea {
    margin-bottom: 3%;
  }

  label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3%;
  }

  h1 {
    color: #fa923f;
    font-size: 24px;
  }
`;
