import produce from 'immer';

const INITIAL_STATE = {
  sample: null,
  loading: false,
};

export default function sample(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@amostra/AMOSTRAS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@amostra/AMOSTRAS_UPDATE': {
        draft.loading = true;
        break;
      }

      case '@amostra/AMOSTRAS_SUCCESS': {
        draft.sample = action.payload.sample;
        draft.loading = false;
        break;
      }

      case '@amostra/AMOSTRAS_LOAD_SUCCESS': {
        draft.sample = action.payload.sample;
        draft.loading = false;
        break;
      }

      case '@amostra/AMOSTRAS_DELETE': {
        draft.loading = true;
        break;
      }

      case '@amostra/AMOSTRAS_CLEAR': {
        draft.sample = null;
        draft.loading = false;
        break;
      }

      case '@amostra/AMOSTRAS_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
