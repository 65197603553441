export function amostrasRequest({
  considera,
  tipo_imovel,
  tipo_imovel_outros,
  fonte_pesquisa,
  endereco_url,
  descricao,
  nro_quartos,
  valor,
  valor_por_m,
  valor_por_m_reduzido,
  valor_reduzido,
  idade,
  estado_conservacao,
  tipo_valor,
  bairro,
  cidade,
  estado,
  metragem,
  area_construida,
  data_vistoria,
  observacoes,
  id_ptam,
}) {
  return {
    type: '@amostra/AMOSTRAS_REQUEST',
    payload: {
      considera,
      tipo_imovel,
      tipo_imovel_outros,
      fonte_pesquisa,
      endereco_url,
      descricao,
      nro_quartos,
      valor,
      valor_por_m,
      valor_por_m_reduzido,
      valor_reduzido,
      idade,
      estado_conservacao,
      tipo_valor,
      bairro,
      cidade,
      estado,
      metragem,
      area_construida,
      data_vistoria,
      observacoes,
      id_ptam,
    },
  };
}

export function amostrasUpdate({
  id,
  considera,
  tipo_imovel,
  tipo_imovel_outros,
  fonte_pesquisa,
  endereco_url,
  descricao,
  nro_quartos,
  valor,
  valor_por_m,
  valor_por_m_reduzido,
  valor_reduzido,
  idade,
  estado_conservacao,
  tipo_valor,
  bairro,
  cidade,
  estado,
  metragem,
  area_construida,
  data_vistoria,
  observacoes,
  id_ptam,
}) {
  return {
    type: '@amostra/AMOSTRAS_UPDATE',
    payload: {
      id,
      considera,
      tipo_imovel,
      tipo_imovel_outros,
      fonte_pesquisa,
      endereco_url,
      descricao,
      nro_quartos,
      valor,
      valor_por_m,
      valor_por_m_reduzido,
      valor_reduzido,
      idade,
      estado_conservacao,
      tipo_valor,
      bairro,
      cidade,
      estado,
      metragem,
      area_construida,
      data_vistoria,
      observacoes,
      id_ptam,
    },
  };
}

export function amostrasChange({
  id,
  considera,
  tipo_imovel,
  fonte_pesquisa,
  endereco_url,
  descricao,
  nro_quartos,
  valor,
  idade,
  estado_conservacao,
  tipo_valor,
  bairro,
  metragem,
  area_construida,
  data_vistoria,
  observacoes,
  id_ptam,
  valor_por_m,
  valor_por_m_reduzido,
  valor_reduzido,
}) {
  return {
    type: '@amostra/AMOSTRAS_CHANGE',
    payload: {
      id,
      considera,
      tipo_imovel,
      fonte_pesquisa,
      endereco_url,
      descricao,
      nro_quartos,
      valor,
      idade,
      estado_conservacao,
      tipo_valor,
      bairro,
      metragem,
      area_construida,
      data_vistoria,
      observacoes,
      id_ptam,
      valor_por_m,
      valor_por_m_reduzido,
      valor_reduzido,
    },
  };
}

export function amostrasLoad(amostra) {
  return {
    type: '@amostra/AMOSTRAS_LOAD',
    payload: { amostra },
  };
}

export function amostrasDelete(id) {
  return {
    type: '@amostra/AMOSTRAS_DELETE',
    payload: { id },
  };
}

export function amostrasSuccess(amostras) {
  return {
    type: '@amostra/AMOSTRAS_SUCCESS',
    payload: { amostras },
  };
}

export function amostrasFailure() {
  return {
    type: '@amostra/AMOSTRAS_FAILURE',
  };
}
