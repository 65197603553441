export function edificacaoRequest({
  padrao,
  estado_conservacao,
  orientacao,
  orientacao_outras,
  edificacao,
  edificacao_outras,
  pavimentos,
  andar,
  elevador,
  numero_elevador,
  estacionamento_coberto,
  numero_vagas_coberto,
  estacionamento_descoberto,
  numero_vagas_descoberto,
  playground,
  churrasqueira,
  piscina,
  academia,
  clube,
  quadra,
  sauna,
  salao_festas,
  outras_caracteristicas,
  tipo_taxa_condominio,
  valor_condominio,
  valor_aluguel,
  data_fim_contrato,
  ocupacao_atual,
  ocupacao_outras,
  id_user,
  id_ptam,
}) {
  return {
    type: '@building/EDIFICACAO_REQUEST',
    payload: {
      padrao,
      estado_conservacao,
      orientacao,
      orientacao_outras,
      edificacao,
      edificacao_outras,
      pavimentos,
      andar,
      elevador,
      numero_elevador,
      estacionamento_coberto,
      numero_vagas_coberto,
      estacionamento_descoberto,
      numero_vagas_descoberto,
      playground,
      churrasqueira,
      piscina,
      academia,
      clube,
      quadra,
      sauna,
      salao_festas,
      outras_caracteristicas,
      tipo_taxa_condominio,
      valor_condominio,
      valor_aluguel,
      data_fim_contrato,
      ocupacao_atual,
      ocupacao_outras,
      id_user,
      id_ptam,
    },
  };
}

export function edificacaoUpdate({
  id,
  padrao,
  estado_conservacao,
  orientacao,
  orientacao_outras,
  edificacao,
  edificacao_outras,
  pavimentos,
  andar,
  elevador,
  numero_elevador,
  estacionamento_coberto,
  numero_vagas_coberto,
  estacionamento_descoberto,
  numero_vagas_descoberto,
  playground,
  churrasqueira,
  piscina,
  academia,
  clube,
  quadra,
  sauna,
  salao_festas,
  outras_caracteristicas,
  tipo_taxa_condominio,
  valor_condominio,
  valor_aluguel,
  data_fim_contrato,
  ocupacao_atual,
  ocupacao_outras,
  id_user,
  id_ptam,
}) {
  return {
    type: '@building/EDIFICACAO_UPDATE',
    payload: {
      id,
      padrao,
      estado_conservacao,
      orientacao,
      orientacao_outras,
      edificacao,
      edificacao_outras,
      pavimentos,
      andar,
      elevador,
      numero_elevador,
      estacionamento_coberto,
      numero_vagas_coberto,
      estacionamento_descoberto,
      numero_vagas_descoberto,
      playground,
      churrasqueira,
      piscina,
      academia,
      clube,
      quadra,
      sauna,
      salao_festas,
      outras_caracteristicas,
      tipo_taxa_condominio,
      valor_condominio,
      valor_aluguel,
      data_fim_contrato,
      ocupacao_atual,
      ocupacao_outras,
      id_user,
      id_ptam,
    },
  };
}

export function edificacaoDelete(id) {
  return {
    type: '@building/EDIFICACAO_DELETE',
    payload: { id },
  };
}

export function edificacaoLoad(id) {
  return {
    type: '@building/EDIFICACAO_LOAD',
    payload: { id },
  };
}

export function edificacaoSuccess(building_id) {
  return {
    type: '@building/EDIFICACAO_SUCCESS',
    payload: { building_id },
  };
}

export function edificacaoFailure() {
  return {
    type: '@building/EDIFICACAO_FAILURE',
  };
}
