import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { FaEdit, FaBuilding } from 'react-icons/fa';

import { MdDeleteSweep, MdLibraryAdd } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Handle from './handle';
import Loader from '../../../components/Loader';
import api from '../../../services/api';
import { infraSuccess } from '../../../store/modules/infraestrutura/actions';

import { Container, Header, Body } from './styles';

export default function Infraestrutura({ setFlag, flag }) {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [infraestrutura, setInfraestrutura] = useState({
    id: null,
    agua: false,
    iluminacao: false,
    lixo: false,
    telefone: false,
    pavimentacao: false,
    energia: false,
    esgoto: false,
    transporte: false,
    tv: false,
    outras: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  const loadInfra = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/infrastructures/${ptam.id_infrastructure}`
      );

      if (response.data) {
        setInfraestrutura(response.data);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ptam.id_infrastructure) {
      loadInfra();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  const handleDelete = async () => {
    try {
      await api.delete(`/infrastructures/${ptam.id_infrastructure}`);

      toast.success('Infraestrutura removida com sucesso!');
      dispatch(infraSuccess(null));
    } catch (error) {
      toast.error('Falha ao deletar a Infraestrutura!');
    } finally {
      setInfraestrutura({
        id: null,
        agua: false,
        iluminacao: false,
        lixo: false,
        telefone: false,
        pavimentacao: false,
        energia: false,
        esgoto: false,
        transporte: false,
        tv: false,
        outras: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      setExcluir(false);
      setFlag({ ...flag, infraestrutura: false });
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          infraestrutura={infraestrutura}
          setInfraestrutura={setInfraestrutura}
          setEdit={setEdit}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir a Infraestrutura do Imóvel ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da Infraestrutura atual ela não estará mais
              disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <FaBuilding size={20} color="#fa923f" />
          <h1>Infraestrutura do Imóvel</h1>
        </div>
        <div>
          {!ptam.id_infrastructure ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Infra</span>
              </div>
            </button>
          ) : null}

          {ptam.id_infrastructure && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Infra</span>
              </div>
            </button>
          )}

          {ptam.id_infrastructure && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Infra</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados da Infraestrutura</h1>

        <strong>Possui Rede de Agua ?</strong>
        <span>{infraestrutura.agua ? 'Sim' : 'Não'}</span>

        <strong>Possui Iluminação ?</strong>
        <span>{infraestrutura.iluminacao ? 'Sim' : 'Não'}</span>

        <strong>Passa Lixeiro ?</strong>
        <span>{infraestrutura.lixo ? 'Sim' : 'Não'}</span>

        <strong>Possui cabeamento de Telefonia ?</strong>
        <span>{infraestrutura.telefone ? 'Sim' : 'Não'}</span>

        <strong>Possui Pavimentação ?</strong>
        <span>{infraestrutura.pavimentacao ? 'Sim' : 'Não'}</span>

        <strong>Possui Energia Elétrica ?</strong>
        <span>{infraestrutura.energia ? 'Sim' : 'Não'}</span>

        <strong>Possui Rede de Esgoto ?</strong>
        <span>{infraestrutura.esgoto ? 'Sim' : 'Não'}</span>

        <strong>Possui Transporte Público ?</strong>
        <span>{infraestrutura.transporte ? 'Sim' : 'Não'}</span>

        <strong>Possui Rede de Televisão ?</strong>
        <span>{infraestrutura.tv ? 'Sim' : 'Não'}</span>

        <strong>Outras Informações</strong>
        <span>{infraestrutura.outras}</span>

        <hr />
      </Body>
    </Container>
  );
}

Infraestrutura.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
