import produce from 'immer';

const INITIAL_STATE = {
  requester: null,
  loading: false,
};

export default function requester(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@req/REQUESTER_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@req/REQUESTER_SUCCESS': {
        draft.requester = action.payload.requester;
        draft.loading = false;
        break;
      }

      case '@ptam/PTAM_CLEAR': {
        draft.requester = null;
        draft.loading = false;
        break;
      }

      case '@req/REQUESTER_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@req/REQUESTER_CLEAR': {
        draft.requester = null;
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
