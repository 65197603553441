export function procJudiRequest({
  processo,
  acao,
  forum,
  requerente,
  requerido,
  tipo,
  nome,
  id_user,
  id_ptam,
}) {
  return {
    type: '@jud/PROCJUDI_REQUEST',
    payload: {
      processo,
      acao,
      forum,
      requerente,
      requerido,
      tipo,
      nome,
      id_user,
      id_ptam,
    },
  };
}

export function procJudiUpdate({
  id,
  processo,
  acao,
  forum,
  requerente,
  requerido,
  tipo,
  nome,
  id_user,
  id_ptam,
}) {
  return {
    type: '@jud/PROCJUDI_UPDATE',
    payload: {
      id,
      processo,
      acao,
      forum,
      requerente,
      requerido,
      tipo,
      nome,
      id_user,
      id_ptam,
    },
  };
}

export function procJudiLoad(id) {
  return {
    type: '@jud/PROCJUDI_LOAD',
    payload: { id },
  };
}

export function procJudiDelete(id) {
  return {
    type: '@jud/PROCJUDI_DELETE',
    payload: { id },
  };
}

export function procJudiSuccess(judicial_id) {
  return {
    type: '@jud/PROCJUDI_SUCCESS',
    payload: { judicial_id },
  };
}

export function procJudiFailure() {
  return {
    type: '@req/PROCJUDI_FAILURE',
  };
}
