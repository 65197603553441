import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  form {
    textarea {
      width: 100%;
      height: 80vh;
      margin: 10px 5px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      border-radius: 4px;
      color: #333;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    h1 {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 20px;
    }

    button {
      width: 180px;
      height: 40px;
      align-self: flex-end;
      background: #fa923f;
      border: 0;
      border-radius: 4px;
      transition: 0.2s;
      margin-left: 10px;
      outline: none;

      &:hover {
        transform: translateY(-3px);
      }

      div {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #fff;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
`;
