export function contextsRequest({
  contexto_urbano,
  analise_mercadologica,
  id_user,
  id_ptam,
}) {
  return {
    type: '@context/CONTEXTS_REQUEST',
    payload: {
      contexto_urbano,
      analise_mercadologica,
      id_user,
      id_ptam,
    },
  };
}

export function contextsUpdate({
  id,
  contexto_urbano,
  analise_mercadologica,
  id_user,
  id_ptam,
}) {
  return {
    type: '@context/CONTEXTS_UPDATE',
    payload: {
      id,
      contexto_urbano,
      analise_mercadologica,
      id_user,
      id_ptam,
    },
  };
}

export function contextDelete(id) {
  return {
    type: '@context/CONTEXTS_DELETE',
    payload: { id },
  };
}

export function contextsLoad(id) {
  return {
    type: '@context/CONTEXTS_LOAD',
    payload: { id },
  };
}

export function contextsSuccess(contexts_id) {
  return {
    type: '@context/CONTEXTS_SUCCESS',
    payload: { contexts_id },
  };
}

export function contextsFailure() {
  return {
    type: '@context/CONTEXTS_FAILURE',
  };
}
