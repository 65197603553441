import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  margin: 10px;

  input {
    border: 0;
    width: 250px;
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }

  .whatsapp {
    width: 265px;
    height: 80px;
    margin-left: 500px;
    padding: 10px;
    z-index: 10000000;
  }

  .support {
    margin: 0 auto;

    strong {
      color: #fa923f;
      font-weight: bold;
    }

    button {
      outline: none;
      margin-left: 35vw;
      background: transparent;
      border: 0.5px solid #fa923f;
      color: #fa923f;
      width: 250px;
      height: 35px;
      border-radius: 24px;
      transition: background 0.2s;
      box-shadow: 0 6px 6px -2px #00000029;

      &:hover {
        strong {
          color: #fff;
        }
        background: ${darken(0.02, '#fa923f')};
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    h1 {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 20px;
    }

    button {
      width: 180px;
      height: 40px;
      align-self: flex-end;
      background: #fa923f;
      border: 0;
      border-radius: 4px;
      transition: 0.2s;
      margin-left: 10px;
      outline: none;

      &:hover {
        transform: translateY(-3px);
      }

      div {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #fff;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  h1 {
    color: #fa923f;
    font-size: 24px;
  }

  strong {
    margin-top: 10px;
  }
`;

export const BtnStyle = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border: 0;
    background: transparent;
    margin-left: 20px;
    transition: 0.2s;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .btnFlash {
    border: 0;
    background: transparent;
    margin-left: 20px;
    transition: 0.2s;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }

    animation-name: flash;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes flash {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const PageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;

  div {
    display: flex;
    justify-content: space-between;
    width: 400px;
  }

  button {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    width: 110px;
    height: 30px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: auto;
    transition: background 0.2s;

    &:hover {
      span {
        color: #fff;
      }
      transform: translateY(-3px);
    }

    &:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
`;

export const DownloadOk = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid #fa923f;
    margin: 30px 0;
  }

  strong {
    color: #fff;
  }

  span {
    color: #fff;
  }

  a {
    color: #fa923f;
  }
`;

export const SuportItems = styled.a`
  margin-bottom: 16px;
  text-decoration: none !important;

  display: flex;
  align-items: center;
  transition: all 0.2s !important;

  &:hover {
    transform: scale(1.06);
  }

  svg {
    margin-right: 16px;
  }
`;
