import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { FaEdit, FaRegListAlt } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../services/api';
import { inspectionsSuccess } from '../../../store/modules/vistoria/actions';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, Body } from './styles';

export default function Vistoria({ setFlag, flag }) {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [vistoria, setVistoria] = useState({
    id: null,
    data_vistoria: new Date(),
    observacoes: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  const loadInspection = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/inspections/${ptam.id_inspection}`);

      console.log(response.data);
      if (response.data) {
        setVistoria(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ptam.id_inspection) {
      loadInspection();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  const handleDelete = async () => {
    await api.delete(`/inspections/${ptam.id_inspection}`);
    dispatch(inspectionsSuccess(null));
    setVistoria({
      id: null,
      data_vistoria: new Date(),
      observacoes: '',
      id_user: user.id,
      id_ptam: ptam.id,
    });

    setFlag({ ...flag, vistoria: false });
    setExcluir(false);
  };

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          vistoria={vistoria}
          setVistoria={setVistoria}
          setEdit={setEdit}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}

      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir a Vistoria ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da Vistoria atual ela não estará mais disponível
              para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <FaRegListAlt size={20} color="#fa923f" />
          <h1>Registro da Vistoria</h1>
        </div>
        <div>
          {!ptam.id_inspection ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Vistoria</span>
              </div>
            </button>
          ) : null}

          {ptam.id_inspection && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Vistoria</span>
              </div>
            </button>
          )}

          {ptam.id_inspection && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Vistoria</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados da Vistoria</h1>

        <strong>Data da Vistoria</strong>
        <span>
          {ptam.id_inspection
            ? format(
                new Date(vistoria.data_vistoria),
                "d 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null}
        </span>

        <strong>Observações</strong>
        <span>{vistoria.observacoes}</span>

        <hr />
      </Body>
    </Container>
  );
}

Vistoria.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
