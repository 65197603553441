import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdBusiness } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../../services/api';
import { improvementSuccess } from '../../../../store/modules/benfeitorias/actions';

import Handle from './handle';
import Loader from '../../../../components/Loader';

import { Container, Header, Body, Grid } from './styles';

export default function Benfeitorias() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [benfeitoria, setBenfeitoria] = useState({
    id: null,
    caseiro: 0,
    banheiro_auxiliar: 0,
    bar: 0,
    churrasqueira: 0,
    cozinha: 0,
    lavabo: 0,
    quartos: 0,
    quiosque: 0,
    sala_estar: 0,
    sala_tv: 0,
    area_servico: 0,
    banheiros: 0,
    canil: 0,
    copa: 0,
    home_teather: 0,
    piscina: 0,
    quarto_empregada: 0,
    varanda: 0,
    sala_jantar: 0,
    suites: 0,
    descricao_imovel: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadImprovements() {
      const response = await api.get(`/improvements/${ptam.id_improvement}`);

      if (response.data) {
        setBenfeitoria(response.data);
        setLoading(false);
      }
    }

    if (ptam.id_improvement) {
      setLoading(true);
      loadImprovements();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/improvements/${benfeitoria.id}`);

      setBenfeitoria({
        id: null,
        caseiro: 0,
        banheiro_auxiliar: 0,
        bar: 0,
        churrasqueira: 0,
        cozinha: 0,
        lavabo: 0,
        quartos: 0,
        quiosque: 0,
        sala_estar: 0,
        sala_tv: 0,
        area_servico: 0,
        banheiros: 0,
        canil: 0,
        copa: 0,
        home_teather: 0,
        piscina: 0,
        quarto_empregada: 0,
        varanda: 0,
        sala_jantar: 0,
        suites: 0,
        descricao_imovel: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(improvementSuccess(null));
      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          benfeitoria={benfeitoria}
          setBenfeitoria={setBenfeitoria}
          setEdit={setEdit}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirma exclusão das Benfeitorias do Imóvel?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão das Benfeitorias atual ela não estará mais
              disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdBusiness size={20} color="#fa923f" />
          <h1>Benfeitorias do Imóvel</h1>
        </div>
        <div>
          {!ptam.id_improvement ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Benfeitoria</span>
              </div>
            </button>
          ) : null}

          {ptam.id_improvement && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Benfeitoria</span>
              </div>
            </button>
          )}

          {ptam.id_improvement && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Benfeitoria</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados das Benfeitorias do Imóvel</h1>

        <Grid>
          <div>
            <strong>Acomodação p/ Caseiro?</strong>
            <span>{benfeitoria.caseiro ? benfeitoria.caseiro : 0}</span>

            <strong>Banheiro Auxiliar?</strong>
            <span>
              {benfeitoria.banheiro_auxiliar
                ? benfeitoria.banheiro_auxiliar
                : 0}
            </span>

            <strong>Bar?</strong>
            <span>{benfeitoria.bar ? benfeitoria.bar : 0}</span>

            <strong>Churraqueira?</strong>
            <span>
              {benfeitoria.churrasqueira ? benfeitoria.churrasqueira : 0}
            </span>

            <strong>Cozinha?</strong>
            <span>{benfeitoria.cozinha ? benfeitoria.cozinha : 0}</span>

            <strong>Lavabo?</strong>
            <span>{benfeitoria.lavabo ? benfeitoria.lavabo : 0}</span>

            <strong>Quartos?</strong>
            <span>{benfeitoria.quartos ? benfeitoria.quartos : 0}</span>
          </div>
          <div>
            <strong>Quiosque?</strong>
            <span>{benfeitoria.quiosque ? benfeitoria.quiosque : 0}</span>

            <strong>Sala de Estar?</strong>
            <span>{benfeitoria.sala_estar ? benfeitoria.sala_estar : 0}</span>

            <strong>Sala de TV?</strong>
            <span>{benfeitoria.sala_tv ? benfeitoria.sala_tv : 0}</span>

            <strong>Sala de Jantar?</strong>
            <span>{benfeitoria.sala_jantar ? benfeitoria.sala_jantar : 0}</span>

            <strong>Suítes?</strong>
            <span>{benfeitoria.suites ? benfeitoria.suites : 0}</span>

            <strong>Área de Serviço?</strong>
            <span>
              {benfeitoria.area_servico ? benfeitoria.area_servico : 0}
            </span>

            <strong>Banheiros?</strong>
            <span>{benfeitoria.banheiros ? benfeitoria.banheiros : 0}</span>
          </div>
          <div>
            <strong>Home Theather?</strong>
            <span>
              {benfeitoria.home_teather ? benfeitoria.home_teather : 0}
            </span>

            <strong>Piscina?</strong>
            <span>{benfeitoria.piscina ? benfeitoria.piscina : 0}</span>

            <strong>Quarto de Empregada?</strong>
            <span>
              {benfeitoria.quarto_empregada ? benfeitoria.quarto_empregada : 0}
            </span>

            <strong>Varanda?</strong>
            <span>{benfeitoria.varanda ? benfeitoria.varanda : 0}</span>

            <strong>Copa?</strong>
            <span>{benfeitoria.copa ? benfeitoria.copa : 0}</span>

            <strong>Canil?</strong>
            <span>{benfeitoria.canil ? benfeitoria.canil : 0}</span>

            <strong>Descrição do Imóvel</strong>
            <span>
              {benfeitoria.descricao_imovel
                ? benfeitoria.descricao_imovel
                : '-'}
            </span>
          </div>
        </Grid>
      </Body>
    </Container>
  );
}
