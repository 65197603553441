import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { FaClipboardList, FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { situationSuccess } from '../../../store/modules/situation/actions';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, Body } from './styles';
import api from '../../../services/api';

export default function Situacao({ setFlag, flag }) {
  const ptam = useSelector(state => state.ptam.ptam);

  const user = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [situacao, setSituacao] = useState({
    id: null,
    proprietario_nome: '',
    proprietario_cep: '',
    proprietario_endereco: '',
    proprietario_numero: '',
    proprietario_complemento: '',
    proprietario_bairro: '',
    proprietario_estado: '',
    proprietario_cidade: '',
    tipo_imovel: '',
    tipo_imovel_outro: '',
    agrupamento: '',
    agrupamento_outro: '',
    utilizacao: '',
    utilizacao_outro: '',
    idade_imovel: 0,
    vida_util: 0,
    cep_imovel: '',
    endereco_imovel: '',
    complemento_imovel: '',
    numero_imovel: '',
    bairro_imovel: '',
    estado_imovel: '',
    cidade_imovel: '',
    use_ross: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadSituation() {
      const response = await api.get(`/situations/${ptam.id_situation}`);

      if (response.data) {
        setSituacao(response.data);
        setLoading(false);
      }

      setRefresh(false);
    }

    if (ptam.id_situation) {
      setLoading(true);
      loadSituation();
    }
  }, [refresh]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/situations/${situacao.id}`);

      setSituacao({
        id: null,
        proprietario_nome: '',
        proprietario_cep: '',
        proprietario_endereco: '',
        proprietario_numero: '',
        proprietario_complemento: '',
        proprietario_bairro: '',
        proprietario_estado: '',
        proprietario_cidade: '',
        tipo_imovel: '',
        tipo_imovel_outro: '',
        agrupamento: '',
        agrupamento_outro: '',
        utilizacao: '',
        utilizacao_outro: '',
        idade_imovel: 0,
        vida_util: 0,
        cep_imovel: '',
        endereco_imovel: '',
        complemento_imovel: '',
        numero_imovel: '',
        bairro_imovel: '',
        estado_imovel: '',
        cidade_imovel: '',
        use_ross: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(situationSuccess(null));

      setFlag({ ...flag, situacao: false });
      setExcluir(false);
      setLoading(false);
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          situacao={situacao}
          setRefresh={setRefresh}
          setSituacao={setSituacao}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir a Situação atual ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da Situação atual ela não estará mais disponível
              para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <FaClipboardList size={20} color="#fa923f" />
          <h1>Situação e Localização</h1>
        </div>
        <div>
          {!ptam.id_situation ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Situação</span>
              </div>
            </button>
          ) : null}

          {ptam.id_situation && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Situação</span>
              </div>
            </button>
          )}

          {ptam.id_situation && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Situação</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados do Proprietário</h1>
        <strong>Nome do Proprietário</strong>
        <span>{situacao.proprietario_nome}</span>
        <strong>CEP</strong>
        <span>{situacao.proprietario_cep}</span>
        <strong>Endereço</strong>
        <span>
          {situacao.proprietario_endereco}, {situacao.proprietario_numero}
        </span>
        <strong>Complemento</strong>
        {situacao.proprietario_complemento ? (
          <span>{situacao.proprietario_complemento}</span>
        ) : (
          ''
        )}
        <strong>Bairro</strong>
        <span>{situacao.proprietario_bairro}</span>
        <strong>Localidade</strong>
        <span>
          {situacao.proprietario_cidade} - {situacao.proprietario_estado}
        </span>
        <hr />
        <h1>Especificação do Imóvel</h1>
        <strong>Tipo do Imóvel</strong>
        <span>
          {situacao.tipo_imovel}{' '}
          {situacao.tipo_imovel_outro ? `(${situacao.tipo_imovel_outro})` : ''}
        </span>
        <strong>Agrupamento</strong>
        <span>
          {situacao.agrupamento}{' '}
          {situacao.agrupamento_outro ? `(${situacao.agrupamento_outro})` : ''}
        </span>
        <strong>Utilização</strong>
        <span>
          {situacao.utilizacao}{' '}
          {situacao.utilizacao_outro ? `(${situacao.utilizacao_outro})` : ''}
        </span>
        <strong>Usa Tabela Ross Heidecke</strong>
        <span>{situacao.use_ross}</span>
        <strong>Idade do Imóvel (Aparente)</strong>
        <span>{situacao.idade_imovel} ano(s)</span>
        <strong>Vida Útil</strong>
        <span>{situacao.vida_util} ano(s)</span>
        <hr />
        <h1>Localização do Imóvel</h1>
        <strong>CEP</strong>
        <span>{situacao.cep_imovel}</span>
        <strong>Endereço</strong>
        <span>
          {situacao.endereco_imovel}, {situacao.numero_imovel}
        </span>
        <strong>Complemento</strong>
        {situacao.complemento_imovel ? (
          <span>{situacao.complemento_imovel}</span>
        ) : (
          ''
        )}
        <strong>Bairro</strong>
        <span>{situacao.bairro_imovel}</span>
        <strong>Localidade</strong>
        <span>
          {situacao.cidade_imovel} - {situacao.estado_imovel}
        </span>
        <strong>Mapa de Localização</strong>
        <div className="img" id="img">
          <img
            src={
              situacao.mapa_localizacao
                ? `data:image/jpeg;base64,${situacao.mapa_localizacao}`
                : ''
            }
            alt="Mapa"
            id="mapImage"
          />
        </div>
        <hr />
      </Body>
    </Container>
  );
}

Situacao.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
