import React from 'react';

import { ClockLoader } from 'react-spinners';

import { Container, Content } from './styles';

function DownloadLoader() {
  return (
    <Container>
      <Content>
        <ClockLoader color="#fa923f" />
        <h1>Gerando PTAM...</h1>
      </Content>
    </Container>
  );
}

export default DownloadLoader;
