import styled from 'styled-components';

export const Container = styled.div`
  header {
    text-align: center;
    margin-top: 8px;
  }
`;

export const Users = styled.div`
  ul {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      height: 150px;
      margin: 0 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      strong {
        color: #333;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
          color: #26ce46;
          font-weight: bold;
          font-size: 36px;
        }

        p {
          font-size: 16px;
          color: #333;
          margin: 0 !important;
        }
      }
    }
  }
`;

export const Transactions = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-left: 16px;
  }

  ul {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      height: 150px;
      margin: 0 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      strong {
        color: #333;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
          color: #26ce46;
          font-weight: bold;
          font-size: 36px;
        }

        p {
          font-size: 16px;
          color: #333;
          margin: 0 !important;
        }
      }
    }
  }
`;
