import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Menu from '../../components/Navigation';
import Solicitante from './Solicitante';
import Judicial from './Judicial';
import Situacao from './Situacao';
import Cartorio from './Cartorio';
import Areas from './Areas';
import Infraestrutura from './Infraestrutura';
import Descricao from './Descricao';
import Vistoria from './Vistoria';
import Contexto from './Contexto';
import Imagem from './Imagem';
import Abertura from './Abertura';
import Finalidade from './Finalidade';
import Amostras from './Amostras';
import Oferta from './Oferta';
import Documentos from './Documentos';
import Benfeitorias from './Descricao/Benfeitorias';
import Edificacao from './Descricao/Edificacao';
import Terreno from './Descricao/Terreno';
import WIP from '../../components/WIP';

import { Container, Navigation, Wrapper } from './styles';

export default function Ptam() {
  const ptam = useSelector(state => state.ptam.ptam);

  const [option, setOption] = useState('');

  const [flag, setFlag] = useState({
    finalidade: ptam ? !!ptam.finalidade : false,
    solicitante: ptam ? !!ptam.id_requester : false,
    situacao: ptam ? !!ptam.id_situation : false,
    cartorio: ptam ? !!ptam.id_registration : false,
    areas: ptam ? !!ptam.id_area : false,
    infraestrutura: ptam ? !!ptam.id_infrastructure : false,
    descricao: ptam ? !!ptam.id_land : false,
    vistoria: ptam ? !!ptam.id_inspection : false,
    contexto: ptam ? !!ptam.id_context : false,
    amostra: ptam ? !!ptam.id_samples : false,
    terreno: ptam ? !!ptam.id_land : false,
  });

  const selectedMenu = useMemo(() => {
    switch (option) {
      case 'abertura':
        return <Abertura />;

      case 'finalidade':
        return <Finalidade setFlag={setFlag} flag={flag} />;

      case 'solicitante':
        return <Solicitante />;

      case 'judicial':
        return <Judicial />;

      case 'situacao':
        return <Situacao setFlag={setFlag} flag={flag} />;

      case 'matricula':
        return <Cartorio setFlag={setFlag} flag={flag} />;

      case 'areas':
        return <Areas setFlag={setFlag} flag={flag} />;

      case 'infra':
        return <Infraestrutura setFlag={setFlag} flag={flag} />;

      case 'descricao':
        return <Descricao />;

      case 'benfeitorias':
        return <Benfeitorias />;

      case 'terreno':
        return <Terreno setFlag={setFlag} flag={flag} />;

      case 'edificacao':
        return <Edificacao />;

      case 'vistoria':
        return <Vistoria setFlag={setFlag} flag={flag} />;

      case 'contexto':
        return <Contexto setFlag={setFlag} flag={flag} />;

      case 'imagens':
        return <Imagem ptam={ptam.id} />;

      case 'amostras':
        return <Amostras setFlag={setFlag} flag={flag} />;

      case 'oferta':
        return <Oferta setFlag={setFlag} />;

      case 'docs':
        return <Documentos ptam={ptam.id} />;

      default:
        return <Abertura />;
    }
  }, [option, flag, ptam.id]);

  return (
    <Container>
      <WIP flag={flag} />
      <section>
        <Navigation>
          <Menu setOption={setOption} />
        </Navigation>
        <Wrapper>{selectedMenu}</Wrapper>
      </section>
    </Container>
  );
}
