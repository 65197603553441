import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { rossFailure } from './actions';

export function* saveRoss({ payload }) {
  try {
    yield call(api.post, 'ross', payload);

    toast.success('Tabela Ross adicionada com sucesso !');
  } catch (err) {
    toast.error('Erro ao incluir uma nova tabela Ross');
    yield put(rossFailure());
  }
}

export function* updateRoss({ payload }) {
  try {
    yield call(api.put, 'ross', payload);

    toast.success('Tabela Ross atualizada com sucesso !');
  } catch (err) {
    toast.error('Erro ao atualizar a tabela Ross');
    yield put(rossFailure());
  }
}

export function* deleteRoss({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/ross/${id}`);

    toast.success('Tabela Ross deletada com sucesso !');
  } catch (err) {
    toast.error('Erro ao deletar a tabela Ross');
    yield put(rossFailure());
  }
}

export default all([
  takeLatest('@ross/REQUEST', saveRoss),
  takeLatest('@ross/UPDATE', updateRoss),
  takeLatest('@ross/DELETE', deleteRoss),
]);
