import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;

  input {
    border: 0;
    width: 450px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  h1 {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
  }

  button {
    grid-area: footer;
    width: 180px;
    height: 40px;
    align-self: flex-end;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;
    margin-left: 10px;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }
  }

  div {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
  }
`;

export const BtnStyle = styled.div`
  button {
    border: 0;
    background: transparent;
    margin-left: 20px;
    transition: 0.2s;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;
