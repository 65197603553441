import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 15px;

  span {
    font-size: 14px;
  }
`;

export const Headers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;

    h1 {
      margin-top: 6px;
      margin-left: 10px;
      font-size: 20px;
    }
  }

  button {
    align-items: center;
    width: 180px;
    height: 40px;
    align-self: center;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;
    margin-bottom: 10px;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }

    div {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }
`;
