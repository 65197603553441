import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  z-index: 9999;
`;

export const Content = styled.div`
  position: relative;
  padding: 16px;
  margin: 36px auto;
  width: 600px;
  height: 20vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;
  box-shadow: 0 0 8px #fa923f;
  border-radius: 8px;

  @media only screen and (max-width: 56.25em) {
    width: 80%;
  }

  h1 {
    color: #333;
    font-size: 16px;
  }

  form {
    width: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      width: 90%;
    }

    > button {
      margin-top: 10px;
      width: 90%;
      height: 40px;
      background: #fa923f;
      border: 0;
      border-radius: 4px;
      transition: 0.2s;

      &:hover {
        transform: translateY(-3px);
      }
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  > button {
    background: transparent;
    border: 0;

    display: flex;
    align-items: center;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
