import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { FcTodoList } from 'react-icons/fc';

import api from '../../services/api';

import { Container, Content, Header } from './styles';

const WIP = ({ flag }) => {
  const ptam = useSelector(state => state.ptam.ptam);

  const [samples, setSamples] = useState(false);
  const [offer, setOffer] = useState(false);

  useEffect(() => {
    async function loadSamples() {
      const response = await api.get(`/samples/${ptam.id}`);

      if (response.data.length > 0) {
        setSamples(true);
      } else {
        setSamples(false);
      }
    }

    loadSamples();
  }, [flag]);

  useEffect(() => {
    async function loadOffer() {
      const response = await api.get(`/offers/${ptam.id}`);

      if (response.data) {
        setOffer(true);
      } else {
        setOffer(false);
      }
    }

    loadOffer();
  }, [flag]);
  return (
    <Container>
      <Header>
        <FcTodoList />
        <strong>Cadastros Obrigatórios</strong>
      </Header>
      <Content>
        {ptam.finalidade ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Finalidade
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Finalidade
          </span>
        )}

        {ptam.id_requester ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Solicitante
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Solicitante
          </span>
        )}

        {ptam.id_situation ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Situação
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Situação
          </span>
        )}

        {ptam.id_registration ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Matrícula
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Matrícula
          </span>
        )}

        {ptam.id_area ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Áreas
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Áreas
          </span>
        )}

        {ptam.id_infrastructure ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Infraestrutura
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Infraestrutura
          </span>
        )}

        {ptam.id_inspection ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Vistoria
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Vistoria
          </span>
        )}

        {ptam.id_context ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Contexto
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Contexto
          </span>
        )}

        {ptam.id_land ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Terreno
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Terreno
          </span>
        )}

        {samples ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Amostras
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Amostras
          </span>
        )}

        {offer ? (
          <span>
            <FiCheckCircle size={20} color="#2BCE48" />
            Valor de Mercado
          </span>
        ) : (
          <span>
            <FiXCircle size={20} color="#c53030" />
            Valor de Mercado
          </span>
        )}
      </Content>
    </Container>
  );
};

export default WIP;
