import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MdDeleteSweep, MdDescription, MdLibraryAdd } from 'react-icons/md';
import { FaMarker, FaEdit } from 'react-icons/fa';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { signatureDelete } from '../../../store/modules/signature/actions';

import Signatures from '../../../components/Signatures';
import api from '../../../services/api';

import { Container, Header, BtnStyle } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
}));

export default function Assinaturas() {
  const classes = useStyles();

  const [plans, setPlans] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [assinatura, setAssinatura] = useState({
    sku: '',
    titulo: '',
    descricao: '',
    valor_total: 0,
    desconto: 0,
    dias_validade: 0,
    parcelas: 0,
    vlr_parcela: 0,
    botao: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    async function loadPlans() {
      const response = await api.get('signatures');

      const formatedData = response.data.map(data => ({
        ...data,
        formatedValue: data.valor_total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        formatedParc: data.vlr_parcela.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        formatedDesc: data.desconto.toLocaleString('pt-BR'),
      }));

      setPlans(formatedData);
      setRefresh(false);
    }

    loadPlans();
  }, [refresh, openModal]);

  function handleView(data) {
    setOpenModal(true);
    setView(true);
    setAssinatura(data);
  }

  function handleAdd() {
    setOpenModal(true);
    setView(false);
    setAssinatura({
      sku: '',
      titulo: '',
      descricao: '',
      valor_total: 0,
      desconto: 0,
      dias_validade: 0,
      parcelas: 0,
      vlr_parcela: 0,
      botao: '',
    });
  }

  function handleEdit(data) {
    setOpenModal(true);
    setView(false);
    setAssinatura(data);
    setRefresh(true);
    setEdit(true);
  }

  function handleDelete(id) {
    setRefresh(true);
    setExcluir(false);

    dispatch(signatureDelete(id));
  }

  return (
    <Container>
      <Header>
        <div>
          <FaMarker size={22} color="#5781c0" />
          <h1>Controle de Assinaturas</h1>
        </div>
        <div>
          <button type="button" onClick={() => handleAdd()}>
            <div>
              <MdLibraryAdd size={22} color="#fff" />
              <span>Incluir Assinatura</span>
            </div>
          </button>
        </div>
      </Header>
      <hr />

      {openModal ? (
        <Signatures
          open={openModal}
          setOpen={setOpenModal}
          view={view}
          assinatura={assinatura}
          setRefresh={setRefresh}
          setAssinatura={setAssinatura}
          edit={edit}
        />
      ) : null}

      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SKU</StyledTableCell>
              <StyledTableCell>Plano</StyledTableCell>
              <StyledTableCell align="right">Vlr. Desconto</StyledTableCell>
              <StyledTableCell align="right">Vlr. Total</StyledTableCell>
              <StyledTableCell align="right">Validade (dias)</StyledTableCell>
              <StyledTableCell align="right">Parcelas</StyledTableCell>
              <StyledTableCell align="right">Valor p/ Parcela</StyledTableCell>
              <StyledTableCell align="right">Ação</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map(plan => (
              <>
                <div>
                  <Dialog
                    open={excluir}
                    onClose={() => setExcluir(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Tem certeza de que deseja excluir a Assinatura ?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Após a exclusão da assinatura ela não estará mais
                        disponível para uso !
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setExcluir(false)}
                        color="primary"
                        style={{ outline: 'none' }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        onClick={() => handleDelete(plan.id)}
                        color="primary"
                        autoFocus
                        style={{ outline: 'none' }}
                      >
                        Excluir
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <StyledTableRow key={plan.id}>
                  <StyledTableCell component="th" scope="row">
                    {plan.sku}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {plan.titulo}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {plan.formatedDesc}%
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {plan.formatedValue}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {plan.dias_validade}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {plan.parcelas}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {plan.formatedParc}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <BtnStyle>
                      <button
                        type="button"
                        title="visualizar"
                        onClick={() => handleView(plan)}
                      >
                        <MdDescription size={20} color="#5781c0" />
                      </button>

                      <button
                        type="button"
                        title="editar"
                        onClick={() => handleEdit(plan)}
                      >
                        <FaEdit size={20} color="#5781c0" />
                      </button>

                      <button
                        type="button"
                        title="deletar"
                        onClick={() => setExcluir(true)}
                      >
                        <MdDeleteSweep size={20} color="#F93636" />
                      </button>
                    </BtnStyle>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}
