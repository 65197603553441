import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  FiChevronRight,
  FiTarget,
  FiHome,
  FiMapPin,
  FiClipboard,
  FiFileText,
  FiUploadCloud,
  FiCheckSquare,
} from 'react-icons/fi';
import { RiCalculatorLine } from 'react-icons/ri';

import { Container, Header, ListItem, Menu } from './styles';

const Navigation = ({ setOption }) => {
  const { id: idPtam, emissao, expiracao } = useSelector(
    state => state.ptam.ptam
  );

  const [abertura, setAbertura] = useState(true);
  const [finalidadeMenu, setFinalidadeMenu] = useState(false);
  const [pesquisaMenu, setPesquisaMenu] = useState(false);
  const [calculoMenu, setCalculoMenu] = useState(false);
  const [uploads, setUploads] = useState({
    upload: {
      view: false,
      imagens: {
        view: false,
      },
      docs: {
        view: false,
      },
    },
  });

  const [imovelMenu, setImovelMenu] = useState({
    view: false,
    solicitante: {
      view: false,
    },
    judicial: {
      view: false,
    },
    identificacao: {
      view: false,

      situacao: {
        view: false,
      },
      matricula: {
        view: false,
      },
      areas: {
        view: false,
      },
      Infraestrutura: {
        view: false,
      },
      vistoria: {
        view: false,
      },
      contexto: {
        view: false,
      },
      descricao: {
        view: false,

        terreno: {
          view: false,
        },
        edificacao: {
          view: false,
        },
        benfeitorias: {
          view: false,
        },
      },
    },
  });

  const handleMenu = useCallback(item => {
    switch (item) {
      case 'abertura': {
        setAbertura(true);
        setOption('abertura');

        /* desativa todos os menus */
        setFinalidadeMenu(false);
        setPesquisaMenu(false);
        setCalculoMenu(false);
        setUploads({
          upload: {
            view: false,
            imagens: {
              view: false,
            },
            docs: {
              view: false,
            },
          },
        });
        setImovelMenu({
          view: false,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });
        break;
      }

      case 'finalidade': {
        setFinalidadeMenu(true);
        setOption('finalidade');

        /* desativa todos os menus */
        setAbertura(false);
        setPesquisaMenu(false);
        setCalculoMenu(false);
        setUploads({
          upload: {
            view: false,
            imagens: {
              view: false,
            },
            docs: {
              view: false,
            },
          },
        });
        setImovelMenu({
          view: false,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });
        break;
      }

      case 'pesquisa': {
        setPesquisaMenu(true);
        setOption('amostras');

        /* desativa todos os menus */
        setAbertura(false);
        setFinalidadeMenu(false);
        setCalculoMenu(false);
        setUploads({
          upload: {
            view: false,
            imagens: {
              view: false,
            },
            docs: {
              view: false,
            },
          },
        });
        setImovelMenu({
          view: false,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });
        break;
      }

      case 'calculo': {
        setCalculoMenu(true);
        setOption('oferta');

        /* desativa todos os menus */
        setAbertura(false);
        setFinalidadeMenu(false);
        setPesquisaMenu(false);
        setUploads({
          upload: {
            view: false,
            imagens: {
              view: false,
            },
            docs: {
              view: false,
            },
          },
        });
        setImovelMenu({
          ...imovelMenu,
          view: false,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });
        break;
      }

      default:
    }
  }, []);

  const handleImovel = useCallback(item => {
    /* desativa todos os menus */
    setAbertura(false);
    setPesquisaMenu(false);
    setFinalidadeMenu(false);
    setCalculoMenu(false);
    setUploads({
      upload: {
        view: false,
        imagens: {
          view: false,
        },
        docs: {
          view: false,
        },
      },
    });

    /* trata o menu de imoveis */
    switch (item) {
      case 'imovel': {
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });
        break;
      }
      case 'solicitante': {
        setOption('solicitante');
        setImovelMenu({
          view: true,
          solicitante: {
            view: true,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'judicial': {
        setOption('judicial');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: true,
          },
          identificacao: {
            view: false,
            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'identificacao': {
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'situacao': {
        setOption('situacao');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: true,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'matricula': {
        setOption('matricula');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: true,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'areas': {
        setOption('areas');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: true,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'infra': {
        setOption('infra');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: true,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'vistoria': {
        setOption('vistoria');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: true,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'contexto': {
        setOption('contexto');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: true,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: true,
            },
            descricao: {
              view: false,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'descricao': {
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: true,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'terreno': {
        setOption('terreno');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: true,
              terreno: {
                view: true,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'edificacao': {
        setOption('edificacao');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: true,
              terreno: {
                view: false,
              },
              edificacao: {
                view: true,
              },
              benfeitorias: {
                view: false,
              },
            },
          },
        });

        break;
      }

      case 'benfeitorias': {
        setOption('benfeitorias');
        setImovelMenu({
          view: true,
          solicitante: {
            view: false,
          },
          judicial: {
            view: false,
          },
          identificacao: {
            view: false,

            situacao: {
              view: false,
            },
            matricula: {
              view: false,
            },
            areas: {
              view: false,
            },
            Infraestrutura: {
              view: false,
            },
            vistoria: {
              view: false,
            },
            contexto: {
              view: false,
            },
            descricao: {
              view: true,
              terreno: {
                view: false,
              },
              edificacao: {
                view: false,
              },
              benfeitorias: {
                view: true,
              },
            },
          },
        });

        break;
      }

      default:
    }
  }, []);

  const handleUpload = useCallback(item => {
    /* desativa todos os menus */
    setAbertura(false);
    setFinalidadeMenu(false);
    setPesquisaMenu(false);
    setCalculoMenu(false);
    setImovelMenu({
      view: false,
      solicitante: {
        view: false,
      },
      judicial: {
        view: false,
      },
      identificacao: {
        view: false,

        situacao: {
          view: false,
        },
        matricula: {
          view: false,
        },
        areas: {
          view: false,
        },
        Infraestrutura: {
          view: false,
        },
        vistoria: {
          view: false,
        },
        contexto: {
          view: false,
        },
        descricao: {
          view: false,

          terreno: {
            view: false,
          },
          edificacao: {
            view: false,
          },
          benfeitorias: {
            view: false,
          },
        },
      },
    });

    /* trata o menu de uploads */
    switch (item) {
      case 'upload': {
        setUploads({
          upload: {
            view: true,
            imagens: {
              view: false,
            },
            docs: {
              view: false,
            },
          },
        });
        break;
      }

      case 'imagens': {
        setOption('imagens');
        setUploads({
          upload: {
            view: true,
            imagens: {
              view: true,
            },
            docs: {
              view: false,
            },
          },
        });
        break;
      }

      case 'docs': {
        setOption('docs');
        setUploads({
          upload: {
            view: true,
            imagens: {
              view: false,
            },
            docs: {
              view: true,
            },
          },
        });
        break;
      }

      default:
    }
  }, []);

  return (
    <Container>
      <Header>
        <div>
          <strong>PTAM Nº: {idPtam}</strong>
          <span>Criado em: {emissao}</span>
          <span>Expira em: {expiracao}</span>
        </div>
      </Header>

      <hr />

      <Menu>
        <ListItem selectedMenu={abertura}>
          <button type="button" onClick={() => handleMenu('abertura')}>
            <FiHome size={22} />
            <span>Home</span>
          </button>
        </ListItem>

        <ListItem selectedMenu={finalidadeMenu}>
          <button type="button" onClick={() => handleMenu('finalidade')}>
            <FiTarget size={22} />
            <span>Finalidade</span>
          </button>
        </ListItem>

        <ListItem selectedMenu={imovelMenu.view}>
          <button type="button" onClick={() => handleImovel('imovel')}>
            <FiCheckSquare size={22} />
            <span>Dados do imóvel avaliado</span>
          </button>
        </ListItem>

        {imovelMenu.view && (
          <Menu>
            <ListItem selectedMenu={imovelMenu.solicitante.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleImovel('solicitante')}
              >
                <FiChevronRight size={22} />
                <span>Solicitante</span>
              </button>
            </ListItem>

            <ListItem selectedMenu={imovelMenu.judicial.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleImovel('judicial')}
              >
                <FiChevronRight size={22} />
                <span>Processo Judicial (se houver)</span>
              </button>
            </ListItem>

            <ListItem selectedMenu={imovelMenu.identificacao.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleImovel('identificacao')}
              >
                <FiClipboard size={22} />
                <span>Identificação do Imóvel</span>
              </button>
            </ListItem>

            {imovelMenu.identificacao.view && (
              <Menu>
                <ListItem selectedMenu={imovelMenu.identificacao.situacao.view}>
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('situacao')}
                  >
                    <FiChevronRight size={22} />
                    <span>Situação e Localização</span>
                  </button>
                </ListItem>

                <ListItem
                  selectedMenu={imovelMenu.identificacao.matricula.view}
                >
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('matricula')}
                  >
                    <FiChevronRight size={22} />
                    <span>Matrícula e Cartório</span>
                  </button>
                </ListItem>

                <ListItem selectedMenu={imovelMenu.identificacao.areas.view}>
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('areas')}
                  >
                    <FiChevronRight size={22} />
                    <span>Áreas</span>
                  </button>
                </ListItem>

                <ListItem
                  selectedMenu={imovelMenu.identificacao.Infraestrutura.view}
                >
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('infra')}
                  >
                    <FiChevronRight size={22} />
                    <span>Infraestrutura e Região</span>
                  </button>
                </ListItem>

                <ListItem selectedMenu={imovelMenu.identificacao.vistoria.view}>
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('vistoria')}
                  >
                    <FiChevronRight size={22} />
                    <span>Vistoria</span>
                  </button>
                </ListItem>

                <ListItem selectedMenu={imovelMenu.identificacao.contexto.view}>
                  <button
                    type="button"
                    style={{ margin: '0.2rem 4rem' }}
                    onClick={() => handleImovel('contexto')}
                  >
                    <FiChevronRight size={22} />
                    <span>Contexto Urbano</span>
                  </button>
                </ListItem>
              </Menu>
            )}

            <ListItem selectedMenu={imovelMenu.identificacao.descricao.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleImovel('descricao')}
              >
                <FiFileText size={22} />
                <span>Descrição detalhada do Imóvel</span>
              </button>
            </ListItem>

            {imovelMenu.identificacao.descricao.view && (
              <Menu>
                <ListItem
                  selectedMenu={imovelMenu.identificacao.descricao.terreno.view}
                >
                  <button
                    type="button"
                    style={{ margin: '0.2rem 2rem' }}
                    onClick={() => handleImovel('terreno')}
                  >
                    <FiChevronRight size={22} />
                    <span>Dados do terreno</span>
                  </button>
                </ListItem>

                <ListItem
                  selectedMenu={
                    imovelMenu.identificacao.descricao.edificacao.view
                  }
                >
                  <button
                    type="button"
                    style={{ margin: '0.2rem 2rem' }}
                    onClick={() => handleImovel('edificacao')}
                  >
                    <FiChevronRight size={22} />
                    <span>Edificação do imóvel</span>
                  </button>
                </ListItem>

                <ListItem
                  selectedMenu={
                    imovelMenu.identificacao.descricao.benfeitorias.view
                  }
                >
                  <button
                    type="button"
                    style={{ margin: '0.2rem 2rem' }}
                    onClick={() => handleImovel('benfeitorias')}
                  >
                    <FiChevronRight size={22} />
                    <span>Benfeitorias do imóvel</span>
                  </button>
                </ListItem>
              </Menu>
            )}
          </Menu>
        )}

        <ListItem selectedMenu={uploads.upload.view}>
          <button type="button" onClick={() => handleUpload('upload')}>
            <FiUploadCloud size={22} />
            <span>Upload de imagens e documentos</span>
          </button>
        </ListItem>

        {uploads.upload.view && (
          <Menu>
            <ListItem selectedMenu={uploads.upload.imagens.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleUpload('imagens')}
              >
                <FiChevronRight size={22} />
                <span>Upload de imagens</span>
              </button>
            </ListItem>

            <ListItem selectedMenu={uploads.upload.docs.view}>
              <button
                type="button"
                style={{ margin: '0.2rem 2rem' }}
                onClick={() => handleUpload('docs')}
              >
                <FiChevronRight size={22} />
                <span>Upload de documentos</span>
              </button>
            </ListItem>
          </Menu>
        )}

        <ListItem selectedMenu={pesquisaMenu}>
          <button type="button" onClick={() => handleMenu('pesquisa')}>
            <FiMapPin size={22} />
            <span>Amostras de imóveis para comparação</span>
          </button>
        </ListItem>

        <ListItem selectedMenu={calculoMenu}>
          <button type="button" onClick={() => handleMenu('calculo')}>
            <RiCalculatorLine size={22} />
            <span>Cálculo valor de mercado</span>
          </button>
        </ListItem>
      </Menu>
    </Container>
  );
};

export default Navigation;

Navigation.propTypes = {
  setOption: PropTypes.func.isRequired,
};
