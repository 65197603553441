import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MdDeleteSweep, MdDescription, MdLibraryAdd } from 'react-icons/md';
import { FaEdit, FaUserTie, FaCheckCircle } from 'react-icons/fa';
import { FcCancel } from 'react-icons/fc';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SeachIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  requesterDelete,
  requesterLoad,
} from '../../../store/modules/requester/actions';

import { ptamUpdateRequester } from '../../../store/modules/ptam/actions';

import api from '../../../services/api';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, BtnStyle, Strong, Body, BtnSelect } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function Solicitante() {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);
  const req = useSelector(state => state.requester.requester);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);

  const [idDelete, setIdDelete] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [requesters, setRequesters] = useState([]);
  const [solicitante, setSolicitante] = useState({});
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const [requester, setRequester] = useState({
    id: req ? req.id : null,
    nome: req ? req.nome : '',
    tipo: req ? req.tipo : '',
    documento: req ? req.documento : '',
    email: req ? req.email : '',
    oab: req ? req.oab : '',
    seccional: req ? req.seccional : '',
    sexo: req ? req.sexo : '',
    cep: req ? req.cep : '',
    endereco: req ? req.endereco : '',
    numero: req ? req.numero : '',
    complemento: req ? req.complemento : '',
    estado: req ? req.estado : '',
    cidade: req ? req.cidade : '',
    bairro: req ? req.bairro : '',
    inventariado: req ? req.inventariado : '',
    id_user: user.id,
  });

  useEffect(() => {
    async function loadRequester() {
      const response = await api.get(`/requesters/${user.id}`);

      const requesterData = response.data.filter(
        r => r.id === ptam.id_requester
      );

      if (requesterData) {
        setSolicitante(requesterData[0]);
      }

      setRequesters(response.data);
      setLoading(false);
    }
    setLoading(true);

    loadRequester();

    setOpenModal(false);
    setRefresh(false);
    setView(false);
    setEdit(false);
    setExcluir(false);
  }, [refresh, ptam.id_requester, user.id]);

  function handleAdd() {
    setRequester({
      id: null,
      nome: '',
      tipo: '',
      documento: '',
      email: '',
      oab: '',
      seccional: '',
      sexo: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      estado: '',
      cidade: '',
      bairro: '',
      inventariado: '',
      id_user: user.id,
    });

    setOpenModal(true);
    setView(false);
    setEdit(false);
  }

  function handleView(data) {
    setRequester(data);
    setOpenModal(true);
    setView(true);
    setEdit(false);
  }

  function handleEdit(data) {
    setRequester(data);
    setOpenModal(true);
    setView(false);
    setEdit(true);
  }

  function handleDelBtn(data) {
    setIdDelete(data.id);
    setExcluir(true);
  }

  async function handleDelete() {
    dispatch(requesterDelete(idDelete));
    setRefresh(true);
  }

  async function handleSearch() {
    const response = await api.get('ptam-requester', {
      params: {
        nome: search,
      },
    });

    setRequesters(response.data);
  }

  async function processar(request) {
    setRequester(request);
    setSolicitante(request);

    dispatch(requesterLoad(request.id));

    dispatch(ptamUpdateRequester(ptam.id, request.id));
  }

  return (
    <Container>
      {loading && <Loader />}
      {openModal ? (
        <Handle
          open={openModal}
          setOpen={setOpenModal}
          view={view}
          edit={edit}
          requester={requester}
          setRequester={setRequester}
          setRefresh={setRefresh}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir o Solicitante ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão do Solicitante ele não estará mais disponível para
              uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          {solicitante ? (
            <>
              <FaCheckCircle size={20} color="#008000" />
              <h1>Solicitante Selecionado: </h1>
              <Strong>
                <strong>{solicitante.nome}</strong>
              </Strong>
            </>
          ) : (
            <>
              <FcCancel size={20} />
              <h1>Solicitante Selecionado: </h1>
              <Strong>
                <strong>Nenhum Solicitante Selecionado</strong>
              </Strong>
            </>
          )}
        </div>
      </Header>
      <hr />
      <Body>
        <div>
          <FaUserTie size={20} color="#fa923f" />
          <h1>Lista de Solicitantes Cadastrados</h1>
        </div>
        <div>
          <button type="button" onClick={() => handleAdd()}>
            <div>
              <MdLibraryAdd size={22} color="#fff" />
              <span>Novo Solicitante</span>
            </div>
          </button>
        </div>
      </Body>
      <div>
        <FormControl className={classes.margin}>
          <InputLabel htmlFor="input-with-icon-adornment">
            Pesquise pelo Solicitante
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </FormControl>
        <Fab
          color="default"
          aria-label="seacr"
          className={classes.fab}
          size="small"
          onClick={() => handleSearch()}
        >
          <SeachIcon size="small" />
        </Fab>
      </div>
      <Paper className={classes.root}>
        <Table
          className={classes.table}
          aria-label="customized table"
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Nome</StyledTableCell>
              <StyledTableCell align="left">CPF/CNPJ</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="center">Ações</StyledTableCell>
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {requesters.map(r => (
              <StyledTableRow key={r.id}>
                <StyledTableCell align="left">{r.nome}</StyledTableCell>
                <StyledTableCell align="left">{r.documento}</StyledTableCell>
                <StyledTableCell align="left">{r.email}</StyledTableCell>
                <StyledTableCell align="center">
                  <BtnStyle>
                    <button
                      type="button"
                      title="visualizar"
                      onClick={() => handleView(r)}
                    >
                      <MdDescription size={20} color="#fa923f" />
                    </button>

                    <button
                      type="button"
                      title="editar"
                      onClick={() => handleEdit(r)}
                    >
                      <FaEdit size={20} color="#fa923f" />
                    </button>

                    <button
                      type="button"
                      title="deletar"
                      onClick={() => handleDelBtn(r)}
                    >
                      <MdDeleteSweep size={20} color="#F93636" />
                    </button>
                  </BtnStyle>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <BtnSelect>
                    {requester.id !== r.id ? (
                      <button
                        type="button"
                        title="Selecionar"
                        onClick={() => processar(r)}
                      >
                        <FaCheckCircle size={12} color="#fff" />
                        <span>Selecionar</span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        title="Selecionar"
                        style={{ background: '#008000' }}
                        disabled
                      >
                        <FaCheckCircle size={12} color="#fff" />
                        <span>Selecionado</span>
                      </button>
                    )}
                  </BtnSelect>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}
