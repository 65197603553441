import React from 'react';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import api from '../../services/api';
import history from '../../services/history';

import logo from '../../assets/logobranco.png';

import { Container, Content, Background } from './styles';

/* validação de campos */
const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido!')
    .required('O e-mail é obrigatório!'),
});

export default function ForgotPass() {
  async function handleSubmit({ email }) {
    await api
      .post('/forgot', {
        email,
      })
      .then(function(response) {
        toast.success('SMS enviado com sucesso!');
        history.push('/resetpass');
      })
      .catch(function(error) {
        console.log(error);
        toast.error('Falha ao enviar o SMS, verifique seus dados!');
        history.push('/');
      });
  }

  return (
    <Container>
      <Content>
        <img src={logo} alt="Avalie Fácil" />

        <Form schema={schema} onSubmit={handleSubmit}>
          <h2>Recuperação de Senha</h2>
          <p>
            Para recuperar sua senha digite abaixo seu email, aguarde alguns
            minutos e verifique seu celular. Será enviado a você um SMS contendo
            um token unico, com validade de 1 hora que deverá ser copiado e
            utilizado na tela seguinte para alteração da sua senha!
          </p>
          <Input
            name="email"
            type="email"
            placeholder="Digite seu e-mail"
            required
          />
          <hr />
          <button type="submit">Enviar SMS</button>
        </Form>
      </Content>
      <Background />
    </Container>
  );
}
