import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { ofertaFailure, ofertaSuccess } from './actions';

export function* saveOferta({ payload }) {
  try {
    const response = yield call(api.post, 'offers', payload);

    toast.success('Oferta cadastrada com sucesso!');

    yield put(ofertaSuccess(response.data));

    // history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao cadastrar Oferta, verifique seus dados!');

    yield put(ofertaFailure());
  }
}

export function* updateOferta({ payload }) {
  try {
    const response = yield call(api.put, 'offers', payload);

    toast.success('Oferta atualizada com sucesso!');

    yield put(ofertaSuccess(response.data));

    // history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao atualizar Oferta, verifique seus dados!');

    yield put(ofertaFailure());
  }
}

export function* deleteOferta({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/offers/${id}`);

    toast.success('Calculo apagado com sucesso!');
  } catch (err) {
    toast.error('Falha ao deletar o Calculo!');
    yield put(ofertaFailure());
  }
}

export function* loadOferta({ payload }) {
  try {
    const { id_ptam } = payload;
    const response = yield call(api.get, `/offers/${id_ptam}`);

    /* carrega o reducer do ptam */
    yield put(ofertaSuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao carregar a Oferta escolhida!');
    yield put(ofertaFailure());
  }
}

export default all([
  takeLatest('@oferta/OFERTA_REQUEST', saveOferta),
  takeLatest('@oferta/OFERTA_LOAD', loadOferta),
  takeLatest('@oferta/OFERTA_UPDATE', updateOferta),
  takeLatest('@oferta/OFERTA_DELETE', deleteOferta),
]);
