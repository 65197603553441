export function ptamRequest(id_user) {
  return {
    type: '@ptam/PTAM_REQUEST',
    payload: { id_user },
  };
}

export function ptamUpdate(id, finalidade) {
  return {
    type: '@ptam/PTAM_UPDATE',
    payload: { id, finalidade },
  };
}

export function ptamStatus(id, atual) {
  return {
    type: '@ptam/PTAM_STATUS',
    payload: { id, status: atual },
  };
}

export function ptamUpdateRequester(id_ptam, id_requester) {
  return {
    type: '@ptam/PTAM_UPDATE_REQUESTER',
    payload: { id_ptam, id_requester },
  };
}

export function ptamSuccess(ptam) {
  return {
    type: '@ptam/PTAM_SUCCESS',
    payload: { ptam },
  };
}

export function ptamDelete(id) {
  return {
    type: '@ptam/PTAM_DELETE',
    payload: { id },
  };
}

export function ptamClear() {
  return {
    type: '@ptam/PTAM_CLEAR',
  };
}

export function ptamLoad(ptam) {
  return {
    type: '@ptam/PTAM_LOAD',
    payload: { ptam },
  };
}

export function ptamFailure() {
  return {
    type: '@ptam/PTAM_FAILURE',
  };
}
