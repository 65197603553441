import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MdCheckCircle,
  MdError,
  MdPageview,
  MdDeleteSweep,
  MdSubtitles,
} from 'react-icons/md';
import { GoListOrdered } from 'react-icons/go';
import { CircularProgressbar } from 'react-circular-progressbar';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, FileInfo, Preview } from './styles';
import ImageOrder from '../ImageOrder';
import ImageView from '../ImageView';
import Legenda from '../Legenda';

export default function ImageList({ ptam, files, onDelete, handleRefresh }) {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [order, setOrder] = useState(false);
  const [excluir, setExcluir] = useState({
    id: '',
    exclui: false,
  });
  const [lineFile, setLineFile] = useState([]);

  function handleEdit(file) {
    setOpen(true);
    setLineFile(file);
  }

  function handleView(file) {
    setView(true);
    setLineFile(file);
  }

  function handleExcluir(id) {
    setExcluir({ id, exclui: true });
  }

  function handleDelete() {
    onDelete(excluir.id);
    setExcluir({ id: '', exclui: false });
  }

  function handleOrder(file) {
    setOrder(true);
    setLineFile(file);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={excluir.exclui}
          onClose={() => setExcluir({ id: '', exclui: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir esta imagem?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da imagem ela não estará mais disponível para uso!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir({ id: '', exclui: false })}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {open ? (
        <Legenda
          file={lineFile}
          setOpen={setOpen}
          open={open}
          handleRefresh={handleRefresh}
        />
      ) : null}
      {view ? (
        <ImageView file={lineFile} setView={setView} view={view} />
      ) : null}
      {order ? (
        <ImageOrder
          ptam={ptam}
          file={lineFile}
          setOrder={setOrder}
          order={order}
          handleRefresh={handleRefresh}
        />
      ) : null}

      {files.map(file => (
        <li key={file.id}>
          <FileInfo>
            <strong>{file.index}</strong>
            <Preview src={`data:image/jpeg;base64,${file.base64}`} />
            <div>
              <div>
                <strong>{file.name}</strong>
                <span> ({file.readableSize})</span>
                {file.uploaded && (
                  <MdCheckCircle
                    size={24}
                    color="#439e21"
                    style={{ marginLeft: 20 }}
                  />
                )}
                {file.error && (
                  <MdError
                    size={24}
                    color="#F54A4A"
                    style={{ marginLeft: 20 }}
                  />
                )}
              </div>
              {file.legenda || <span>{file.legenda}</span>}
            </div>
          </FileInfo>

          <div>
            {!file.uploaded && !file.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#439e21' },
                }}
                strokeWidth={10}
                value={file.progress}
              />
            )}

            {file.url && (
              <div className="btnView">
                <button
                  type="button"
                  title="Visualizar"
                  onClick={() => handleView(file)}
                >
                  <div>
                    <MdPageview
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#0d2c5b"
                    />
                  </div>
                </button>
                <button
                  type="button"
                  title="Legenda"
                  onClick={() => handleEdit(file)}
                >
                  <div>
                    <MdSubtitles
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#353839"
                    />
                  </div>
                </button>
                <button
                  type="button"
                  title="Ordem"
                  onClick={() => handleOrder(file)}
                >
                  <div>
                    <GoListOrdered
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#fa923f"
                    />
                  </div>
                </button>
                <button
                  type="button"
                  title="Excluir"
                  onClick={() => handleExcluir(file.id)}
                >
                  <div>
                    <MdDeleteSweep
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#F93636"
                    />
                  </div>
                </button>
              </div>
            )}
          </div>
        </li>
      ))}
    </Container>
  );
}

ImageList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.array),
  onDelete: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired,
  ptam: PropTypes.number.isRequired,
};

ImageList.defaultProps = {
  files: [],
  onDelete: null,
};
