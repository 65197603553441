import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { FaEdit, FaRegBuilding } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../../services/api';
import { edificacaoSuccess } from '../../../../store/modules/edificacao/actions';

import Handle from './handle';
import Loader from '../../../../components/Loader';

import { Container, Header, Body, Grid } from './styles';

export default function Edificacao() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [edificacao, setEdificacao] = useState({
    id: null,
    padrao: '',
    estado_conservacao: '',
    orientacao: '',
    orientacao_outras: '',
    edificacao: '',
    edificacao_outras: '',
    pavimentos: 0,
    andar: 0,
    elevador: false,
    numero_elevador: 0,
    estacionamento_coberto: false,
    numero_vagas_coberto: 0,
    estacionamento_descoberto: false,
    numero_vagas_descoberto: 0,
    playground: false,
    churrasqueira: false,
    piscina: false,
    academia: false,
    clube: false,
    quadra: false,
    sauna: false,
    salao_festas: false,
    outras_caracteristicas: '',
    tipo_taxa_condominio: '',
    valor_condominio: 0,
    valor_aluguel: 0,
    data_fim_contrato: null,
    ocupacao_atual: '',
    ocupacao_outras: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadEdificacao() {
      const response = await api.get(`/buildings/${ptam.id_building}`);

      if (response.data) {
        setEdificacao(response.data);
        setLoading(false);
      }
    }

    if (ptam.id_building) {
      setLoading(true);
      loadEdificacao();
    }
  }, [modal]);

  const selectedEstadoConservacao = useMemo(() => {
    switch (edificacao.estado_conservacao) {
      case 'a':
        return 'Nova';

      case 'b':
        return 'Entre Nova e Regular';

      case 'c':
        return 'Regular';

      case 'd':
        return 'Entre regular e reparos simples';

      case 'e':
        return 'Reparo simples';

      case 'f':
        return 'Entre reparos simples e importantes';

      case 'g':
        return 'Reparos importantes';

      case 'h':
        return 'Entre reparos importantes e sem valor';

      case 'i':
        return 'Sem valor';

      default:
        return '';
    }
  }, [edificacao]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/buildings/${edificacao.id}`);

      setEdificacao({
        id: null,
        padrao: '',
        estado_conservacao: '',
        orientacao: '',
        orientacao_outras: '',
        edificacao: '',
        edificacao_outras: '',
        pavimentos: 0,
        andar: 0,
        elevador: false,
        numero_elevador: 0,
        estacionamento_coberto: false,
        numero_vagas_coberto: 0,
        estacionamento_descoberto: false,
        numero_vagas_descoberto: 0,
        playground: false,
        churrasqueira: false,
        piscina: false,
        academia: false,
        clube: false,
        quadra: false,
        sauna: false,
        salao_festas: false,
        outras_caracteristicas: '',
        tipo_taxa_condominio: '',
        valor_condominio: 0,
        valor_aluguel: 0,
        data_fim_contrato: null,
        ocupacao_atual: '',
        ocupacao_outras: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(edificacaoSuccess(null));

      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          edificacao={edificacao}
          setEdificacao={setEdificacao}
          setEdit={setEdit}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir as informações da edificação ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão das informações da edificação atual ela não estará
              mais disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <FaRegBuilding size={20} color="#fa923f" />
          <h1>Edificação do Imóvel</h1>
        </div>
        <div>
          {!ptam.id_building ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Edificação</span>
              </div>
            </button>
          ) : null}

          {ptam.id_building && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Edificação</span>
              </div>
            </button>
          )}

          {ptam.id_building && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Edificação</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados da Edificação do Imóvel</h1>

        <Grid>
          <div>
            <strong>Padrão</strong>
            <span>{edificacao.padrao}</span>

            <strong>Estado de Conservação</strong>
            <span>{selectedEstadoConservacao}</span>

            <strong>Orientação</strong>
            <span>{edificacao.orientacao ? edificacao.orientacao : '-'}</span>

            <strong>Orientação Outras</strong>
            <span>
              {edificacao.orientacao_outras
                ? edificacao.orientacao_outras
                : '-'}
            </span>

            <strong>Edificação no Terreno</strong>
            <span>{edificacao.edificacao ? edificacao.edificacao : '-'}</span>

            <strong>Edificação Outras</strong>
            <span>
              {edificacao.edificacao_outras
                ? edificacao.edificacao_outras
                : '-'}
            </span>

            <strong>Nº de Pavimentos</strong>
            <span>{edificacao.pavimentos ? edificacao.pavimentos : '-'}</span>

            <strong>Nº do Andar</strong>
            <span>{edificacao.andar ? edificacao.andar : 0}</span>

            <strong>Elevador?</strong>
            <span>{edificacao.elevador ? 'Sim' : 'Não'}</span>

            <strong>Elevadores?</strong>
            <span>
              {edificacao.numero_elevador ? edificacao.numero_elevador : 0}
            </span>
          </div>
          <div>
            <strong>Estacionamento Coberto ?</strong>
            <span>{edificacao.estacionamento_coberto ? 'Sim' : 'Não'}</span>

            <strong>Nº de vagas (Cobertas)</strong>
            <span>
              {edificacao.numero_vagas_coberto
                ? edificacao.numero_vagas_coberto
                : 0}
            </span>

            <strong>Estacionamento Descoberto ?</strong>
            <span>{edificacao.estacionamento_descoberto ? 'Sim' : 'Não'}</span>

            <strong>Nº de vagas (Descobertas)</strong>
            <span>
              {edificacao.numero_vagas_descoberto
                ? edificacao.numero_vagas_descoberto
                : 0}
            </span>

            <strong>Playground?</strong>
            <span>{edificacao.playground ? 'Sim' : 'Não'}</span>

            <strong>Churrasqueira?</strong>
            <span>{edificacao.churrasqueira ? 'Sim' : 'Não'}</span>

            <strong>Piscina?</strong>
            <span>{edificacao.piscina ? 'Sim' : 'Não'}</span>

            <strong>Academia?</strong>
            <span>{edificacao.academia ? 'Sim' : 'Não'}</span>

            <strong>Clube?</strong>
            <span>{edificacao.clube ? 'Sim' : 'Não'}</span>

            <strong>Quadra?</strong>
            <span>{edificacao.quadra ? 'Sim' : 'Não'}</span>
          </div>
          <div>
            <strong>Sauna?</strong>
            <span>{edificacao.sauna ? 'Sim' : 'Não'}</span>

            <strong>Salão de Festas?</strong>
            <span>{edificacao.salao_festa ? 'Sim' : 'Não'}</span>

            <strong>Outras Caracteristicas</strong>
            <span>
              {edificacao.outras_caracteristicas
                ? edificacao.outras_caracteristicas
                : '-'}
            </span>

            <strong>Tipo da Taxa de Condominio</strong>
            <span>
              {edificacao.tipo_taxa_condominio
                ? edificacao.tipo_taxa_condominio
                : '-'}
            </span>

            <strong>Valor do Condominio</strong>
            <span>
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(edificacao.valor_condominio)}
            </span>

            <strong>Valor do Aluguel</strong>
            <span>
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(edificacao.valor_aluguel)}
            </span>

            <strong>Data Fim do Contrato</strong>
            <span>
              {edificacao.data_fim_contrato
                ? format(
                    parseISO(edificacao.data_fim_contrato),
                    "d 'de' MMMM 'de' yyyy",
                    {
                      locale: pt,
                    }
                  )
                : null}
            </span>

            <strong>Ocupação Atual</strong>
            <span>
              {edificacao.ocupacao_atual ? edificacao.ocupacao_atual : '-'}
            </span>

            <strong>Ocupação Outras</strong>
            <span>
              {edificacao.ocupacao_outras ? edificacao.ocupacao_outras : '-'}
            </span>
          </div>
        </Grid>
        <hr />
      </Body>
    </Container>
  );
}
