import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../services/api';

import { Container, Label } from './styles';

export default function Legenda({ file, setOpen, open, handleRefresh }) {
  const [legenda, setLegenda] = useState(file.legenda || '');
  const img = `data:image/jpeg;base64,${file.base64}` || '';

  async function handleUpdateLegenda(id) {
    await api.put('images', {
      id,
      legenda,
    });

    setOpen(false);
    handleRefresh(true);
  }

  return (
    <Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Dados da Legenda</DialogTitle>
        <DialogContent>
          <img src={img} alt={legenda} width="100%" height="100%" />
        </DialogContent>
        <Label htmlFor="legenda">
          Legenda
          <input
            id="legenda"
            label="Digite a legenda"
            type="text"
            value={legenda}
            onChange={e => setLegenda(e.target.value)}
          />
        </Label>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleUpdateLegenda(file.id);
            }}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

Legenda.propTypes = {
  file: PropTypes.objectOf(PropTypes.object).isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};
