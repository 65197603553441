import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  button {
    background: transparent;
    border: none;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 16px;
    font-size: 20px;
    text-transform: uppercase;
  }
`;
