import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';

/* nao precisa de autenticação */
import SignIn from '../pages/signIn';
import SignUp from '../pages/signUp';
import ForgotPass from '../pages/ForgotPass';
import ResetPass from '../pages/ResetPass';
import ValidMail from '../pages/ValidMail';
import Plans from '../pages/signUp/plans';

/* precisa de autenticação */
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import Ptam from '../pages/ptam';
import Admin from '../pages/admin';
import Signarures from '../pages/Signatures';
import Brokers from '../pages/Brokers';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot" component={ForgotPass} />
      <Route path="/resetpass" component={ResetPass} />
      <Route path="/validmail" component={ValidMail} />
      <Route path="/brokers" component={Brokers} />

      <Route path="/plans" component={Plans} isPlans isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/ptam" component={Ptam} isPrivate />
      <Route path="/signatures" component={Signarures} isPrivate />

      <Route path="/admin" component={Admin} isAdmin isPrivate />
    </Switch>
  );
}
