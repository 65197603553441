import React, { useState } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../services/api';

import { Container, Order } from './styles';

export default function ImageOrder({
  ptam,
  file,
  setOrder,
  order,
  handleRefresh,
}) {
  const ordem = file.index || '';
  const [novaOrdem, setNovaOrdem] = useState('');
  const img = `data:image/jpeg;base64,${file.base64}` || '';

  async function handleUpdateOrder(id) {
    try {
      await api.get('images-order', {
        params: {
          id_ptam: id,
          indexNew: novaOrdem,
          indexAtu: ordem,
          id_image: file.id,
        },
      });

      setOrder(false);
      handleRefresh(true);
      toast.success('Reordenado com sucesso!');
    } catch (err) {
      toast.error(err.response.data.error);
    }
  }

  return (
    <Container>
      <div>
        <Dialog
          open={order}
          onClose={() => setOrder(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Ordem da Imagem</DialogTitle>
          <DialogContent>
            <img src={img} alt={ordem} width="100%" height="100%" />
          </DialogContent>
          <Order>
            <label htmlFor="ordem_ori">
              Ordem Atual
              <input
                id="ordem_ori"
                label="Ordem atual"
                type="text"
                value={ordem}
                disabled
              />
            </label>
            &emsp;
            <label htmlFor="ordem">
              Nova Ordem
              <input
                id="ordem"
                label="Nova ordem"
                type="text"
                value={novaOrdem}
                onChange={e => setNovaOrdem(e.target.value)}
              />
            </label>
          </Order>
          <DialogActions>
            <Button onClick={() => setOrder(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleUpdateOrder(ptam);
              }}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

ImageOrder.propTypes = {
  file: PropTypes.objectOf(PropTypes.object).isRequired,
  setOrder: PropTypes.func.isRequired,
  order: PropTypes.bool.isRequired,
  ptam: PropTypes.number.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};
