import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'AvalieFacil',
      storage,
      whitelist: [
        'auth',
        'user',
        'image',
        'requester',
        'finality',
        'infra',
        'inspection',
        'ptam',
        'sample',
        'offer',
      ],
    },
    reducers
  );

  return persistedReducer;
};
