import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import { procJudiSuccess } from '../../../../store/modules/procJudi/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  judicial,
  setJudicial,
  setEdit,
}) {
  const dispatch = useDispatch();

  async function handleSave() {
    try {
      const response = edit
        ? await api.put('/judicials', judicial)
        : await api.post('/judicials', judicial);

      const { id } = response.data;

      dispatch(procJudiSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setModal(false);
    setEdit(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Processo Judícial &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=fVpRVg8jsBI"
                    rel="noreferrer"
                  >
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados do
              Processo Judicial do imóvel.
            </DialogContentText>
            <Form>
              <div className="side1">
                <label to="processo">
                  Nº do Processo
                  <input
                    autoFocus
                    id="processo"
                    type="text"
                    value={judicial.processo}
                    onChange={e =>
                      setJudicial({ ...judicial, processo: e.target.value })
                    }
                  />
                </label>

                <label to="acao">
                  Ação
                  <input
                    id="acao"
                    type="text"
                    value={judicial.acao}
                    onChange={e =>
                      setJudicial({ ...judicial, acao: e.target.value })
                    }
                  />
                </label>

                <label to="forum">
                  Fórum
                  <input
                    id="forum"
                    type="text"
                    value={judicial.forum}
                    onChange={e =>
                      setJudicial({ ...judicial, forum: e.target.value })
                    }
                  />
                </label>

                <label to="requerente">
                  Requerente
                  <input
                    id="requerente"
                    type="text"
                    value={judicial.requerente}
                    onChange={e =>
                      setJudicial({ ...judicial, requerente: e.target.value })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label to="requerido">
                  Requerido
                  <input
                    id="requerido"
                    type="text"
                    value={judicial.requerido}
                    onChange={e =>
                      setJudicial({ ...judicial, requerido: e.target.value })
                    }
                  />
                </label>

                <label to="tipo">
                  Tratamento
                  <select
                    id="tipo"
                    name="tipo"
                    value={judicial.tipo}
                    onChange={e =>
                      setJudicial({ ...judicial, tipo: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="Juiz">Juiz</option>
                    <option value="Juiza">Juiza</option>
                  </select>
                </label>

                <label to="nome">
                  Nome Juiz(a)
                  <input
                    id="nome"
                    type="text"
                    value={judicial.nome}
                    onChange={e =>
                      setJudicial({ ...judicial, nome: e.target.value })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  judicial: PropTypes.objectOf(PropTypes.object),
  setJudicial: PropTypes.func,
  setEdit: PropTypes.func,
};

Handle.defaultProps = {
  judicial: null,
  setJudicial: null,
  setEdit: null,
};
