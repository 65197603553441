import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import image from './Image/sagas';
import requester from './requester/sagas';
import finality from './finality/sagas';
import ptam from './ptam/sagas';
import sample from './amostras/sagas';
import offer from './oferta/sagas';
import signature from './signature/sagas';
import ross from './ross/sagas';
import tipo from './tipoimovel/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    image,
    requester,
    finality,
    ptam,
    sample,
    offer,
    signature,
    ross,
    tipo,
  ]);
}
