import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { inputImageSuccess, inputImageFailure } from './actions';

export function* inputImage({ id, payload }) {
  try {
    const { image_id, path, ptam_id, ...rest } = payload.image;

    const image = { id, image_id, path, ptam_id, ...rest };

    const response = yield call(api.put, 'image', image);

    toast.success('Imagem carregada com sucesso!');

    yield put(inputImageSuccess(response.data));
  } catch (err) {
    toast.error('Erro no carregamento da imagem, tente novamente!');
    yield put(inputImageFailure());
  }
}

export default all([takeLatest('@user/INPUT_IMAGE_REQUEST', inputImage)]);
