import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MdDeleteSweep,
  MdDescription,
  MdLibraryAdd,
  MdArtTrack,
  MdImage,
} from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Loader from '../../../components/Loader';

import { amostrasDelete } from '../../../store/modules/amostras/actions';
import { ofertaDelete } from '../../../store/modules/oferta/actions';

import api from '../../../services/api';
import AmostraImage from '../../../components/AmostraImage';
import Handle from './handle';

import { Container, Header, BtnStyle } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function Amostras() {
  const ptam = useSelector(state => state.ptam.ptam);

  const loading = useSelector(state => state.sample.loading);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [imagens, setImagens] = useState(false);
  const [amostra, setAmostra] = useState({
    id: null,
    considera: true,
    tipo_imovel: '',
    tipo_imovel_outros: '',
    fonte_pesquisa: '',
    endereco_url: '',
    descricao: '',
    valor: 0,
    valor_por_m: 0,
    valor_reduzido: 0,
    valor_por_m_reduzido: 0,
    idade: 0,
    estado_conservacao: '',
    tipo_valor: '',
    bairro: '',
    cidade: '',
    estado: '',
    metragem: 0,
    area_construida: 0,
    data_vistoria: '',
    observacoes: '',
    id_ptam: ptam.id,
    image_sample: null,
  });

  const [amostras, setAmostras] = useState([]);

  useEffect(() => {
    async function loadAmostras() {
      const response = await api.get(`/samples/${ptam.id}`);

      const formatedData = response.data.map(data => ({
        ...data,
        newArea: data.area_construida
          ? data.area_construida.toLocaleString('pt-BR', {
              style: 'decimal',
            })
          : '0,00',
        newMetragem: data.metragem
          ? data.metragem.toLocaleString('pt-BR', {
              style: 'decimal',
            })
          : '0,00',
        newValor: data.valor
          ? data.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : 'R$ 0,00',
      }));

      setAmostras(formatedData);
    }
    loadAmostras();

    setRefresh(false);
    setView(false);
    setEdit(false);
    setAmostra({
      id: null,
      considera: true,
      tipo_imovel: '',
      tipo_imovel_outros: '',
      fonte_pesquisa: '',
      endereco_url: '',
      descricao: '',
      valor: '',
      valor_por_m: 0,
      valor_reduzido: 0,
      valor_por_m_reduzido: 0,
      idade: 0,
      estado_conservacao: '',
      tipo_valor: '',
      bairro: '',
      cidade: '',
      estado: '',
      metragem: 0,
      area_construida: 0,
      data_vistoria: new Date(),
      observacoes: '',
      id_ptam: ptam.id,
      image_sample: null,
    });
  }, [loading, refresh, ptam.id]);

  function handleView(data) {
    setAmostra(data);
    setOpenModal(true);
    setView(true);
  }

  function handleEdit(data) {
    setAmostra(data);
    setOpenModal(true);
    setEdit(true);
    setView(false);
  }

  function handleImage(data) {
    setImagens(true);
    setAmostra(data);
  }

  function handleExcluir(data) {
    setAmostra(data);
    setExcluir(true);
  }

  function handleDelete() {
    dispatch(amostrasDelete(amostra.id));
    dispatch(ofertaDelete(ptam.id));

    setExcluir(false);
    setRefresh(true);
  }

  async function handleNew() {
    if (!ptam.id_situation) {
      toast.error(
        'Situação ainda não cadastrada, para incluir as amostras é preciso ter a situação cadastrada !'
      );
      return;
    }

    const response = await api.get(`/situations/${ptam.id_situation}`);
    if (response.data) {
      const { cidade_imovel, estado_imovel } = response.data;

      setAmostra({
        id: null,
        considera: true,
        tipo_imovel: '',
        tipo_imovel_outros: '',
        fonte_pesquisa: '',
        endereco_url: '',
        descricao: '',
        valor: '',
        valor_por_m: 0,
        valor_reduzido: 0,
        valor_por_m_reduzido: 0,
        idade: 0,
        estado_conservacao: '',
        tipo_valor: '',
        bairro: '',
        metragem: 0,
        area_construida: 0,
        data_vistoria: new Date(),
        observacoes: '',
        id_ptam: ptam.id,
        image_sample: null,
        cidade: cidade_imovel,
        estado: estado_imovel,
      });
    }

    setOpenModal(true);
  }

  return (
    <Container>
      {loading && <Loader />}
      {openModal ? (
        <Handle
          open={openModal}
          setOpen={setOpenModal}
          view={view}
          edit={edit}
          amostra={amostra}
          setAmostra={setAmostra}
          setRefresh={setRefresh}
        />
      ) : null}
      {imagens ? (
        <AmostraImage
          idAmostra={amostra.id}
          setImagens={setImagens}
          setRefresh={setRefresh}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir a Amostra ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão da Amostra ela não estará mais disponível para uso
              e será preciso refazer o calculo da oferta !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdArtTrack size={24} color="#fa923f" />
          <h1>Lista de Amostras</h1>
        </div>
        <div>
          <button type="button" onClick={() => handleNew()}>
            <div>
              <MdLibraryAdd size={22} color="#fff" />
              <span>Nova Amostra</span>
            </div>
          </button>
        </div>
      </Header>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Imagem</StyledTableCell>
              <StyledTableCell align="left">Pesquisa</StyledTableCell>
              <StyledTableCell align="center">Área (m²)</StyledTableCell>
              <StyledTableCell align="center">Terreno (m²)</StyledTableCell>
              <StyledTableCell align="center">Idade (anos)</StyledTableCell>
              <StyledTableCell align="center">Tipo Oferta</StyledTableCell>
              <StyledTableCell align="center">Valor</StyledTableCell>
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {amostras.map((am, index) => (
              <StyledTableRow key={am.id}>
                <StyledTableCell align="left">
                  <img
                    src={
                      am.image_sample[0]
                        ? `data:image/jpeg;base64,${am.image_sample[0].base64}`
                        : `https://ui-avatars.com/api/?font-size=0.33&background=fa923f&color=0d2c5b&name=Amostra ${index +
                            1}`
                    }
                    alt="Amostra"
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  {am.fonte_pesquisa}
                </StyledTableCell>
                <StyledTableCell align="center">{am.newArea}</StyledTableCell>
                <StyledTableCell align="center">
                  {am.newMetragem}
                </StyledTableCell>
                <StyledTableCell align="center">{am.idade}</StyledTableCell>
                <StyledTableCell align="center">
                  {am.tipo_valor}
                </StyledTableCell>
                <StyledTableCell align="center">{am.newValor}</StyledTableCell>

                <StyledTableCell align="center">
                  <BtnStyle>
                    <button
                      type="button"
                      title="Selecionar Imagem"
                      onClick={() => handleImage(am)}
                    >
                      <MdImage size={20} color="#03BB85" />
                    </button>
                    <button
                      type="button"
                      title="visualizar"
                      onClick={() => handleView(am)}
                    >
                      <MdDescription size={20} color="#0d2c5b" />
                    </button>

                    <button
                      type="button"
                      title="editar"
                      onClick={() => handleEdit(am)}
                    >
                      <FaEdit size={20} color="#fa923f" />
                    </button>

                    <button
                      type="button"
                      title="deletar"
                      onClick={() => handleExcluir(am)}
                    >
                      <MdDeleteSweep size={20} color="#F93636" />
                    </button>
                  </BtnStyle>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}
