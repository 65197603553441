export function terrenoRequest({
  frente,
  fundo,
  profundidade,
  topografia,
  topografia_outras,
  forma,
  forma_outras,
  orientacao,
  situacao,
  situacao_outras,
  protecao,
  protecao_outras,
  observacoes,
  id_user,
  id_ptam,
}) {
  return {
    type: '@land/TERRENO_REQUEST',
    payload: {
      frente,
      fundo,
      profundidade,
      topografia,
      topografia_outras,
      forma,
      forma_outras,
      orientacao,
      situacao,
      situacao_outras,
      protecao,
      protecao_outras,
      observacoes,
      id_user,
      id_ptam,
    },
  };
}

export function terrenoUpdate({
  id,
  frente,
  fundo,
  profundidade,
  topografia,
  topografia_outras,
  forma,
  forma_outras,
  orientacao,
  situacao,
  situacao_outras,
  protecao,
  protecao_outras,
  observacoes,
  id_user,
  id_ptam,
}) {
  return {
    type: '@land/TERRENO_UPDATE',
    payload: {
      id,
      frente,
      fundo,
      profundidade,
      topografia,
      topografia_outras,
      forma,
      forma_outras,
      orientacao,
      situacao,
      situacao_outras,
      protecao,
      protecao_outras,
      observacoes,
      id_user,
      id_ptam,
    },
  };
}

export function terrenoDelete(id) {
  return {
    type: '@land/TERRENO_DELETE',
    payload: { id },
  };
}

export function terrenoLoad(id) {
  return {
    type: '@land/TERRENO_LOAD',
    payload: { id },
  };
}

export function terrenoSuccess(land_id) {
  return {
    type: '@land/TERRENO_SUCCESS',
    payload: { land_id },
  };
}

export function terrenoFailure() {
  return {
    type: '@land/TERRENO_FAILURE',
  };
}
