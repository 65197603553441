import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

import api from '../../services/api';
import history from '../../services/history';

import logo from '../../assets/logobranco.png';
import SendRequest from './SendRequest';

import { Container, Content, Background, AnimatedContainer } from './styles';

/* validação de campos */
const schema = Yup.object().shape({
  token: Yup.string().required('O token é obrigatório!'),
});

export default function ValidMail() {
  const [loading, setLoading] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  async function handleSubmit({ token }) {
    try {
      setLoading(true);

      await api.put('/validation/valid', {
        token,
      });

      toast.success('Usuário validado com sucesso');
      history.push('/');
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao validar sua conta');
      setLoading(false);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {sendRequest && <SendRequest setSendRequest={setSendRequest} />}
      <Background />
      <Content>
        <AnimatedContainer>
          <img src={logo} alt="Avalie Fácil" />
          <Form schema={schema} onSubmit={handleSubmit}>
            <h2>Validação por SMS</h2>
            <p>
              Com o objetivo de proteger seus dados utilizamos a validação do
              token enviado ao seu celular para finalizarmos esse processo!
            </p>
            <Input name="token" type="text" placeholder="Digite o token" />
            <Link to="/validmail" onClick={() => setSendRequest(true)}>
              Não recebi o SMS
            </Link>
            <button type="submit">Enviar</button>
          </Form>
        </AnimatedContainer>
      </Content>
    </Container>
  );
}
