import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import avalieBackgroundSignIn from '../../assets/avalie-background-SignIn.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: #fff;
  outline: none;

  span {
    color: #ff0000;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  place-content: center;

  width: 100%;
  height: 100vh;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;

  animation: ${appearFromLeft} 1s;

  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }

  img {
    width: 250px;

    @media only screen and (max-width: 37.5em) {
      width: 200px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    width: 100%;
    height: 100%;
    align-items: center;

    h1 {
      color: #333;
      margin-bottom: 30px;
      font-size: 24px;
    }

    input {
      width: 90%;
      color: #0d2c5b;

      & + input {
        margin-top: 10px;
      }

      &::placeholder {
        color: #666;
      }
    }

    a {
      color: #333;
      display: block;
      margin: 15px 0;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fa923f')};
      }
    }
  }

  > a {
    color: #f4ede8;
    background: #fa923f;
    width: 350px;
    height: 40px;
    border-radius: 4px;
    display: block;

    text-decoration: none;
    transition: color 0.2s;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#fa923f')};
    }
  }

  button {
    width: 90%;
    height: 40px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  button + button {
    color: #f4ede8;
    font-weight: bold;
    font-size: 18px;
    width: 90%;
    height: 40px;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;
    margin-top: 20px;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${avalieBackgroundSignIn}) no-repeat center;
  background-size: cover;
`;
