/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import { cartorioSuccess } from '../../../../store/modules/registration/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  cartorio,
  setCartorio,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  function handleChange(name, value) {
    setCartorio({
      ...cartorio,
      [name]: value,
    });
  }

  async function handleSave() {
    if (!cartorio.matricula) {
      toast.error('A matrícula do imóvel precisa ser informada!');
      return;
    }

    if (!cartorio.cartorio) {
      toast.error('O cartório de registro do imóvel precisa ser informado!');
      return;
    }

    if (!cartorio.descricao) {
      toast.error('A descrição do imóvel precisa ser informada!');
      return;
    }

    try {
      const response = edit
        ? await api.put('/registrations', cartorio)
        : await api.post('/registrations', cartorio);

      const { id } = response.data;

      dispatch(cartorioSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setFlag({ ...flag, cartorio: true });
    setModal(false);
    setEdit(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados da Matrícula e Cartório de Registro &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a
                    target="_blank"
                    href="https://youtu.be/9uV8JnQqV5s"
                    rel="noreferrer"
                  >
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados da
              Matrícula e do Cartório do imóvel. ** ATENÇÃO ** ITENS EM VERMELHO
              SÃO OBRIGATÓRIOS !!
            </DialogContentText>
            <Form>
              <div className="side1">
                <h1>Dados da Matricula</h1>
                <label htmlFor="matricula">
                  Nº Matricula
                  <input
                    autoFocus
                    required
                    id="matricula"
                    type="text"
                    value={cartorio.matricula}
                    onChange={e => handleChange('matricula', e.target.value)}
                  />
                </label>

                <label htmlFor="cartorio">
                  Cartório
                  <input
                    required
                    id="cartorio"
                    type="text"
                    value={cartorio.cartorio}
                    onChange={e => handleChange('cartorio', e.target.value)}
                  />
                </label>

                <label htmlFor="descricao">
                  Descrição do Imóvel
                  <textarea
                    required
                    id="descricao"
                    type="text"
                    value={cartorio.descricao}
                    onChange={e => handleChange('descricao', e.target.value)}
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <h1>Dados do IPTU</h1>
                <label htmlFor="cadastro">
                  Nº do Cadastro IPTU
                  <input
                    id="cadastro"
                    type="text"
                    value={cartorio.cadastro}
                    onChange={e => handleChange('cadastro', e.target.value)}
                  />
                </label>

                <label htmlFor="vlr_imposto_anual">
                  Valor do Imposto Anual
                  <NumberFormat
                    id="vlr_imposto_anual"
                    label="Valor do Imposto Anual"
                    value={cartorio.vlr_imposto_anual}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        vlr_imposto_anual: e.target.value,
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="vlr_imposto_mensal">
                  Valor do Imposto Mensal
                  <NumberFormat
                    id="vlr_imposto_mensal"
                    value={cartorio.vlr_imposto_mensal}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        vlr_imposto_mensal: e.target.value,
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="mts_terreno">
                  Metragem do Terreno (m²)
                  <NumberFormat
                    id="mts_terreno"
                    value={cartorio.mts_terreno}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        mts_terreno: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="vlr_venal_terreno">
                  Valor Venal do Terreno
                  <NumberFormat
                    id="vlr_venal_terreno"
                    value={cartorio.vlr_venal_terreno}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        vlr_venal_terreno: e.target.value,
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="mts_construcao">
                  Metragem da Construção (m²)
                  <NumberFormat
                    id="mts_construcao"
                    value={cartorio.mts_construcao}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        mts_construcao: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="vlr_venal_construcao">
                  Valor Venal da Construção
                  <NumberFormat
                    id="vlr_venal_construcao"
                    value={cartorio.vlr_venal_construcao}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        vlr_venal_construcao: e.target.value,
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="vlr_venal_total">
                  Valor Venal Total
                  <NumberFormat
                    id="vlr_venal_total"
                    value={cartorio.vlr_venal_total}
                    onChange={e =>
                      setCartorio({
                        ...cartorio,
                        vlr_venal_total: e.target.value,
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  cartorio: PropTypes.objectOf(PropTypes.object),
  setCartorio: PropTypes.func,
  setEdit: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  cartorio: null,
  setCartorio: null,
  setEdit: null,
};
