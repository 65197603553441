import React, { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';

import {
  FaMarker,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaEdit,
} from 'react-icons/fa';
import { MdDescription } from 'react-icons/md';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import SeachIcon from '@material-ui/icons/Search';
import Fab from '@material-ui/core/Fab';

import Pago from '../../../components/Status/Pago';
import Expirado from '../../../components/Status/Expirado';
import Unitario from '../../../components/Status/Unitario';

import Users from '../../../components/Users';
import api from '../../../services/api';

import { Container, Header, PageActions, BtnStyle } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
}));

export default function Usuarios() {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState({
    cpf: '',
    name: '',
    email: '',
  });
  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([
    {
      id: null,
      name: '',
      cpf: '',
      email: '',
      mobile: '',
      expired: false,
      expire_at: '',
      createdAt: '',
      password_reset_token: '',
      password_reset_expires: '',
      valid_mail_token: false,
      mail_ok: false,
      ptam_quantity: 0,
    },
  ]);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('users-search', {
        params: {
          cpf: search.cpf,
          name: search.name,
          email: search.email,
          per_page: 6,
          page,
        },
      });

      setUsers(response.data);
      setRefresh(false);
    }

    loadUsers();
  }, [page, refresh, search.cpf, search.email, search.name]);

  function handleView(data) {
    setOpenModal(true);
    setView(true);
    setUser(data);
  }

  async function handleEdit(data) {
    setOpenModal(true);
    setView(false);
    setUser(data);
    setRefresh(true);
    setEdit(true);
  }

  function handlePage(action) {
    if (action === 'next') {
      setPage(page + 1);
    }

    if (action === 'back') {
      setPage(page - 1);
    }

    if (page < 0) {
      setPage(0);
    }
  }

  async function handleSearch() {
    const response = await api.get('users-search', {
      params: {
        cpf: search.cpf,
        name: search.name,
        email: search.email,
        page,
      },
    });

    setUsers(response.data);
  }

  /*
  async function handleFilter() {
    const response = await api.get('users-filter', {
      params: {
        page,
      },
    });

    setUsers(response.data);
  }
  */

  function handleExpires(date) {
    const now = new Date();
    if (now.getTime() > parseISO(date).getTime()) {
      return false;
    }
    return true;
  }

  return (
    <Container>
      <Header>
        <div>
          <FaMarker size={22} color="#5781c0" />
          <h1>Lista de Usuários</h1>
        </div>
      </Header>
      <hr />

      {openModal ? (
        <Users
          open={openModal}
          setOpen={setOpenModal}
          view={view}
          user={user}
          setRefresh={setRefresh}
          setUser={setUser}
          edit={edit}
        />
      ) : null}

      <FormControl className={classes.margin}>
        <div className="search">
          <label htmlFor="cpf">
            Pesquise pelo CPF
            <input
              id="cpf"
              type="text"
              value={search.cpf}
              onChange={e =>
                setSearch({
                  ...search,
                  cpf: e.target.value,
                })
              }
            />
          </label>
          <label htmlFor="name">
            Pesquise pelo Nome
            <input
              id="name"
              type="text"
              value={search.name}
              onChange={e =>
                setSearch({
                  ...search,
                  name: e.target.value,
                })
              }
            />
          </label>
          <label htmlFor="email">
            Pesquise pelo Email
            <input
              id="email"
              type="text"
              value={search.email}
              onChange={e =>
                setSearch({
                  ...search,
                  email: e.target.value,
                })
              }
            />
          </label>
          <Fab
            color="default"
            aria-label="seacr"
            className={classes.fab}
            size="small"
            onClick={() => handleSearch()}
          >
            <SeachIcon size="small" />
          </Fab>
          {/*
          &emsp;&emsp;&emsp;&emsp;
          <Fab
            color="default"
            aria-label="filter"
            label="Filtro"
            className={classes.fab}
            size="small"
            onClick={() => handleFilter()}
          >
            <FilterList size="small" />
          </Fab>
          */}
        </div>
      </FormControl>

      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>CPF</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Celular</StyledTableCell>
              <StyledTableCell align="center">Data Criação</StyledTableCell>
              <StyledTableCell align="center">Data Expiração</StyledTableCell>
              <StyledTableCell>Expirado?</StyledTableCell>
              <StyledTableCell align="center">Email Validado?</StyledTableCell>
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(userM => (
              <>
                <StyledTableRow key={userM.id}>
                  <StyledTableCell component="th" scope="row">
                    {userM.ptam_quantity === 1 ? (
                      <Unitario />
                    ) : handleExpires(userM.expire_at) && !userM.expired ? (
                      <Pago />
                    ) : (
                      <Expirado />
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {userM.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {userM.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {userM.cpf}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {userM.email}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {userM.mobile}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${userM.createdAt.substring(
                      8,
                      10
                    )}/${userM.createdAt.substring(
                      5,
                      7
                    )}/${userM.createdAt.substring(0, 4)}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${userM.expire_at.substring(
                      8,
                      10
                    )}/${userM.expire_at.substring(
                      5,
                      7
                    )}/${userM.expire_at.substring(0, 4)}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {userM.expired ? 'Sim' : 'Não'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {userM.mail_ok ? 'Sim' : 'Não'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <BtnStyle>
                      <button
                        type="button"
                        title="visualizar"
                        onClick={() => handleView(userM)}
                      >
                        <MdDescription size={20} color="#5781c0" />
                      </button>
                      <button
                        type="button"
                        title="editar"
                        onClick={() => handleEdit(userM)}
                      >
                        <FaEdit size={20} color="#5781c0" />
                      </button>
                    </BtnStyle>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <PageActions>
        <div>
          <button
            type="button"
            onClick={() => handlePage('back')}
            disabled={page < 2}
          >
            <FaAngleDoubleLeft size={24} color="#fff" />
            <strong>
              <span>Voltar</span>
            </strong>
          </button>
          <button
            type="button"
            onClick={() => handlePage('next')}
            disabled={users.length < 6}
          >
            <strong>
              <span>Próximo</span>
            </strong>
            <FaAngleDoubleRight size={24} color="#fff" />
          </button>
        </div>
      </PageActions>
    </Container>
  );
}
