import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DatePicker from 'react-datepicker';

import api from '../../../../services/api';

import {
  amostrasRequest,
  amostrasUpdate,
} from '../../../../store/modules/amostras/actions';

import { Container, Form } from './styles';

export default function Handle({
  open,
  setOpen,
  view,
  edit,
  amostra,
  setAmostra,
  setRefresh,
}) {
  const dispatch = useDispatch();
  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    async function SelectTipos() {
      const response = await api.get('tipo');

      if (response) {
        const data = response.data.map(tipo => ({
          ...tipo,
          descricao: tipo.descricao,
        }));

        setTipos(data);
      }
    }
    SelectTipos();
  }, []);

  function handleSave() {
    if (!amostra.tipo_imovel) {
      toast.error('O Tipo do Imóvel precisa ser informado !');
      return;
    }

    if (!amostra.fonte_pesquisa) {
      toast.error('A Fonte Pesquisa precisa ser informado !');
      return;
    }

    if (!amostra.valor) {
      toast.error('O Valor do imóvel precisa ser informado !');
      return;
    }

    if (!amostra.area_construida) {
      toast.error('A Área Construída/Total precisa ser informado !');
      return;
    }

    if (!amostra.tipo_valor) {
      toast.error('O Tipo Valor do Imóvel precisa ser informado !');
      return;
    }

    if (!edit) {
      dispatch(amostrasRequest(amostra));
    } else {
      dispatch(amostrasUpdate(amostra));
    }

    setRefresh(true);
    setOpen(false);
  }

  function handleCalM2() {
    if (amostra.area_construida) {
      const vlr_m =
        parseFloat(
          amostra.valor
            .replace(/\./g, '')
            .replace(',', '.')
            .replace('R$ ', '')
        ) / amostra.area_construida;

      setAmostra({
        ...amostra,
        valor_por_m: vlr_m.toFixed(2),
        valor_por_m_reduzido: vlr_m.toFixed(2),
      });
    }
  }

  function handleCancel() {
    setAmostra({
      id: null,
      considera: true,
      tipo_imovel: '',
      tipo_imovel_outros: '',
      fonte_pesquisa: '',
      endereco_url: '',
      descricao: '',
      valor: '',
      valor_por_m: 0,
      valor_reduzido: 0,
      valor_por_m_reduzido: 0,
      idade: 0,
      estado_conservacao: '',
      tipo_valor: '',
      bairro: '',
      cidade: '',
      estado: '',
      metragem: 0,
      area_construida: 0,
      data_vistoria: new Date(),
      observacoes: '',
      image_sample: null,
    });

    setOpen(false);
  }

  return (
    <Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Dados da Amostra</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os dados abaixo para gravação, edição ou visualização de
            uma amostra. ** ATENÇÃO ** ITENS EM VERMELHO SÃO OBRIGATÓRIOS!!
          </DialogContentText>
          <Form>
            <div className="side1">
              <label htmlFor="tipo_imovel">
                Tipo do Imóvel
                <select
                  required
                  id="tipo_imovel"
                  label="Tipo do Imóvel"
                  value={amostra.tipo_imovel}
                  onChange={e =>
                    setAmostra({ ...amostra, tipo_imovel: e.target.value })
                  }
                >
                  <option value="" />
                  {tipos.map(tipo => (
                    <option key={tipo.id} value={tipo.descricao}>
                      {tipo.descricao}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="tipo_imovel_outros">
                Outros Tipos (se houver)
                <input
                  id="tipo_imovel_outros"
                  type="text"
                  value={amostra.tipo_imovel_outros}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      tipo_imovel_outros: e.target.value,
                    })
                  }
                />
              </label>

              <label htmlFor="fonte_pesquisa">
                Fonte de Pesquisa
                <input
                  required
                  id="fonte_pesquisa"
                  label="Fonte de Pesquisa"
                  type="text"
                  value={amostra.fonte_pesquisa}
                  onChange={e =>
                    setAmostra({ ...amostra, fonte_pesquisa: e.target.value })
                  }
                />
              </label>

              <label htmlFor="endereco_url">
                Endereço ou URL (site)
                <input
                  id="endereco_url"
                  label="Endereço/URL"
                  type="text"
                  value={amostra.endereco_url}
                  onChange={e =>
                    setAmostra({ ...amostra, endereco_url: e.target.value })
                  }
                />
              </label>

              <label htmlFor="descricao">
                Descrição Completa
                <textarea
                  id="descricao"
                  label="Descrição Completa"
                  type="text"
                  value={amostra.descricao}
                  onChange={e =>
                    setAmostra({ ...amostra, descricao: e.target.value })
                  }
                />
              </label>

              <label htmlFor="nro_quartos">
                Quartos
                <input
                  id="nro_quartos"
                  label="Quartos"
                  type="number"
                  value={amostra.nro_quartos}
                  onChange={e =>
                    setAmostra({ ...amostra, nro_quartos: e.target.value })
                  }
                />
              </label>

              <label htmlFor="valor">
                Valor do Imóvel
                <NumberFormat
                  required
                  id="valor"
                  label="Valor do Imóvel"
                  value={amostra.valor}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      valor: e.target.value,
                      valor_reduzido: parseFloat(
                        e.target.value
                          .replace(/\./g, '')
                          .replace(',', '.')
                          .replace('R$ ', '')
                      ),
                    })
                  }
                  onBlur={() => handleCalM2()}
                  displayType="input"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="R$ "
                />
              </label>

              <label htmlFor="metragem">
                Metragem do Terreno (m²)
                <NumberFormat
                  id="metragem"
                  label="Metragem do Terreno"
                  value={amostra.metragem}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      metragem: parseFloat(
                        e.target.value.replace(/\./g, '').replace(',', '.')
                      ),
                    })
                  }
                  displayType="input"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </label>

              <label htmlFor="area_construida">
                Área total a ser considerada
                <NumberFormat
                  required
                  id="area_construida"
                  label="Área total a ser considerada"
                  value={amostra.area_construida}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      area_construida: parseFloat(
                        e.target.value.replace(/\./g, '').replace(',', '.')
                      ),
                    })
                  }
                  onBlur={() => handleCalM2()}
                  displayType="input"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </label>

              <label htmlFor="valor_por_m">
                Valor por m²
                <input
                  id="valor_por_m"
                  label="Valor por m²"
                  value={amostra.valor_por_m}
                  type="number"
                  disabled
                />
              </label>
            </div>
            <div className="middle" />
            <div className="side2">
              <label htmlFor="idade">
                Idade do Imóvel (Aparente)
                <input
                  id="idade"
                  label="Idade do Imóvel (Aparente)"
                  type="number"
                  value={amostra.idade}
                  onChange={e =>
                    setAmostra({ ...amostra, idade: e.target.value })
                  }
                />
              </label>

              <label htmlFor="estado_conservacao">
                Estado de Conservação
                <select
                  id="estado_conservacao"
                  label="Estado de Conservação"
                  type="text"
                  value={amostra.estado_conservacao}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      estado_conservacao: e.target.value,
                    })
                  }
                >
                  <option value="" />
                  <option value="a">(A) Nova</option>
                  <option value="b">(B) Entre nova e regular</option>
                  <option value="c">(C) Regular</option>
                  <option value="d">(D) Entre regular e reparos simples</option>
                  <option value="e">(E) Reparos simples</option>
                  <option value="f">
                    (F) Entre reparos simples e importantes
                  </option>
                  <option value="g">(G) Reparos importantes</option>
                  <option value="h">
                    (H) Entre reparos importantes e sem valor
                  </option>
                </select>
              </label>

              <label htmlFor="tipo_valor">
                Tipo do Valor
                <select
                  required
                  id="tipo_valor"
                  label="Tipo do Valor"
                  value={amostra.tipo_valor}
                  onChange={e =>
                    setAmostra({ ...amostra, tipo_valor: e.target.value })
                  }
                >
                  <option value="" />
                  <option value="Oferta">Oferta</option>
                  <option value="Comercializado">Comercializado</option>
                </select>
              </label>

              <label htmlFor="bairro">
                Bairro do Imóvel
                <input
                  id="bairro"
                  label="Bairro do Imóvel"
                  type="text"
                  value={amostra.bairro}
                  onChange={e =>
                    setAmostra({ ...amostra, bairro: e.target.value })
                  }
                />
              </label>

              <label htmlFor="cidade">
                Cidade do Imóvel
                <input
                  disabled
                  id="cidade"
                  label="Cidade do Imóvel"
                  type="text"
                  value={amostra.cidade}
                />
              </label>

              <label htmlFor="estado">
                Estado do Imóvel
                <input
                  disabled
                  id="estado"
                  label="Estado do Imóvel"
                  type="text"
                  value={amostra.estado}
                />
              </label>

              <label htmlFor="dataVistoria">
                Data da vistoria
                <DatePicker
                  id="dataVistoria"
                  showPopperArrow={false}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(amostra.data_vistoria)}
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      data_vistoria: e,
                    })
                  }
                />
              </label>

              {/* <label HtmFor="data_vistoria">
                Data da Vistoria
                <input
                  id="data_vistoria"
                  label="Data da Vistoria"
                  type="date"
                  onChange={e =>
                    setAmostra({
                      ...amostra,
                      data_vistoria: e.target.value,
                    })
                  }
                />
              </label> */}

              <label htmlFor="observacoes">
                Observações (se houver)
                <textarea
                  id="observacoes"
                  label="Observações (se houver)"
                  type="text"
                  value={amostra.observacoes}
                  onChange={e =>
                    setAmostra({ ...amostra, observacoes: e.target.value })
                  }
                />
              </label>
            </div>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">
            Cancelar
          </Button>
          {!view ? (
            <Button
              onClick={() => handleSave()}
              color="primary"
              disabled={view}
            >
              Salvar
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setOpen: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  amostra: PropTypes.objectOf(PropTypes.object),
  setAmostra: PropTypes.func.isRequired,
  setRefresh: PropTypes.func,
};

Handle.defaultProps = {
  amostra: null,
  setRefresh: null,
};
