import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdAspectRatio } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../../services/api';
import { terrenoSuccess } from '../../../../store/modules/terreno/actions';
import Handle from './handle';
import Loader from '../../../../components/Loader';

import { Container, Header, Body, Grid } from './styles';

export default function Terreno({ setFlag, flag }) {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [terreno, setTerreno] = useState({
    id: null,
    frente: 0,
    fundo: 0,
    profundidade: 0,
    topografia: '',
    topografia_outras: '',
    forma: '',
    forma_outras: '',
    orientacao: '',
    situacao: '',
    situacao_outras: '',
    protecao: '',
    protecao_outras: '',
    observacoes: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadLands() {
      const response = await api.get(`/lands/${ptam.id_land}`);

      if (response.data) {
        setTerreno(response.data);
        setLoading(false);
      }
    }

    if (ptam.id_land) {
      setLoading(true);
      loadLands();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/lands/${terreno.id}`);

      setTerreno({
        id: null,
        frente: 0,
        fundo: 0,
        profundidade: 0,
        topografia: '',
        topografia_outras: '',
        forma: '',
        forma_outras: '',
        orientacao: '',
        situacao: '',
        situacao_outras: '',
        protecao: '',
        protecao_outras: '',
        observacoes: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(terrenoSuccess(null));

      setFlag({ ...flag, terreno: false });
      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          terreno={terreno}
          setTerreno={setTerreno}
          setEdit={setEdit}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir os dados do terreno do imóvel ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão os dados do terreno atual ela não estará mais
              disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdAspectRatio size={20} color="#fa923f" />
          <h1>Terreno do Imóvel</h1>
        </div>
        <div>
          {!ptam.id_land ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Terreno</span>
              </div>
            </button>
          ) : null}

          {ptam.id_land && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Terreno</span>
              </div>
            </button>
          )}

          {ptam.id_land && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Terreno</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados do Terreno do Imóvel</h1>

        <Grid>
          <div>
            <strong>Frente (m²)</strong>
            <span>{terreno.frente ? terreno.frente : 0}</span>

            <strong>Fundo (m²)</strong>
            <span>{terreno.fundo ? terreno.fundo : 0}</span>

            <strong>Profundidade (m²)</strong>
            <span>{terreno.profundidade ? terreno.profundidade : 0}</span>

            <strong>Topografia</strong>
            <span>{terreno.topografia ? terreno.topografia : '-'}</span>

            <strong>Topografia Outras</strong>
            <span>
              {terreno.topografia_outras ? terreno.topografia_outras : '-'}
            </span>

            <strong>Forma</strong>
            <span>{terreno.forma ? terreno.forma : '-'}</span>

            <strong>Forma Outras</strong>
            <span>{terreno.forma_outras ? terreno.forma_outras : '-'}</span>
          </div>

          <div>
            <strong>Orientação</strong>
            <span>{terreno.orientacao ? terreno.orientacao : '-'}</span>

            <strong>Situação</strong>
            <span>{terreno.situacao ? terreno.situacao : '-'}</span>

            <strong>Situação Outras</strong>
            <span>
              {terreno.situacao_outras ? terreno.situacao_outras : '-'}
            </span>

            <strong>Proteção</strong>
            <span>{terreno.protecao ? terreno.protecao : '-'}</span>

            <strong>Proteção Outras</strong>
            <span>
              {terreno.protecao_outras ? terreno.protecao_outras : '-'}
            </span>

            <strong>Observações</strong>
            <span>{terreno.observacoes ? terreno.observacoes : '-'}</span>
          </div>
        </Grid>
        <hr />
      </Body>
    </Container>
  );
}

Terreno.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
