import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    margin-top: 4px;
    height: 100%;
    font-size: 11px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }
  }
`;
