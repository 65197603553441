/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Tooltip,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DatePicker from 'react-datepicker';

import api from '../../../../services/api';

import { inspectionsSuccess } from '../../../../store/modules/vistoria/actions';

import { Container } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  vistoria,
  setVistoria,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      const response = edit
        ? await api.put('/inspections', vistoria)
        : await api.post('/inspections', vistoria);

      const { id } = response.data;

      dispatch(inspectionsSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }

    setFlag({ ...flag, vistoria: true });
    setModal(false);
    setEdit(false);
  };

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Registro da Vistoria &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a target="_blank" href="https://youtu.be/ciQwgO--hvA">
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados da
              Vistoria do imóvel.
            </DialogContentText>
            <Container>
              <label htmlFor="dateVistoria">
                Data da vistoria
                <DatePicker
                  id="dateVistoria"
                  showPopperArrow={false}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(vistoria.data_vistoria)}
                  onChange={e =>
                    setVistoria({
                      ...vistoria,
                      data_vistoria: e,
                    })
                  }
                />
              </label>

              <label htmlFor="observacoes">
                Observações (se houver)
                <textarea
                  id="observacoes"
                  label="Observações se houver"
                  type="text"
                  value={vistoria.observacoes}
                  onChange={e =>
                    setVistoria({ ...vistoria, observacoes: e.target.value })
                  }
                />
              </label>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  vistoria: PropTypes.objectOf(PropTypes.object),
  setVistoria: PropTypes.func,
  setEdit: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  vistoria: null,
  setVistoria: null,
  setEdit: null,
};
