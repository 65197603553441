import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { signatureSuccess, signatureFailure } from './actions';

export function* saveSignature({ payload }) {
  try {
    const response = yield call(api.post, 'signatures', payload);

    toast.success('Plano de assinatura cadastrado com sucesso!');
    yield put(signatureSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao gravar o plano de assinatura!');
    yield put(signatureFailure());
  }
}

export function* updateSignature({ payload }) {
  try {
    const response = yield call(api.put, 'signatures', payload);

    toast.success('Plano de assinatura atualizado com sucesso!');
    yield put(signatureSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao atualizar a assinatura!');
    yield put(signatureFailure());
  }
}

export function* deleteSignature({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/signatures/${id}`);

    toast.success('Plano de assinatura excluído com sucesso!');
  } catch (err) {
    toast.error('Erro ao deletar a assinatura!');
    yield put(signatureFailure());
  }
}

export default all([
  takeLatest('@signature/REQUEST', saveSignature),
  takeLatest('@signature/UPDATE', updateSignature),
  takeLatest('@signature/DELETE', deleteSignature),
]);
