import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

import api from '../../../../../services/api';
import { terrenoSuccess } from '../../../../../store/modules/terreno/actions';
import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  terreno,
  setTerreno,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  async function handleSave() {
    try {
      const response = edit
        ? await api.put('/lands', terreno)
        : await api.post('/lands', terreno);

      const { id } = response.data;

      dispatch(terrenoSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setFlag({ ...flag, terreno: true });
    setModal(false);
    setEdit(false);
  }
  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados do Terreno do Imóvel &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a target="_blank" href="https://youtu.be/MphwxdRmB9Y">
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados do
              Terreno do imóvel. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
              OBRIGATÓRIOS!!
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="frente">
                  Metragem da frente do imóvel (m)
                  <NumberFormat
                    id="frente"
                    value={terreno.frente}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        frente: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="fundo">
                  Metragem do fundo do imóvel (m)
                  <NumberFormat
                    id="fundo"
                    value={terreno.fundo}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        fundo: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="profundidade">
                  Metragem da profundidade do Imóvel (m)
                  <NumberFormat
                    id="profundidade"
                    label="Metragem da profundidade do Imóvel (m)"
                    value={terreno.profundidade}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        profundidade: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="topografia">
                  Topografia do Imóvel
                  <select
                    id="topografia"
                    value={terreno.topografia}
                    onChange={e =>
                      setTerreno({ ...terreno, topografia: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="plana">Plana</option>
                    <option value="ondulada">Ondulada</option>
                    <option value="aclive">Aclive</option>
                    <option value="declive">Declive</option>
                    <option value="outros">Outros (especificar abaixo)</option>
                  </select>
                </label>

                <label htmlFor="topografia_outras">
                  Outras Topografias (se houver)
                  <input
                    id="topografia_outras"
                    label="Outras se houver"
                    type="text"
                    value={terreno.topografia_outras}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        topografia_outras: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="forma">
                  Forma do Imóvel
                  <select
                    id="forma"
                    value={terreno.forma}
                    onChange={e =>
                      setTerreno({ ...terreno, forma: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="regular">Regular</option>
                    <option value="irregular">Irregular</option>
                    <option value="poligonal">Poligonal</option>
                    <option value="triangular">Triangular</option>
                    <option value="outros">Outros (especificar abaixo)</option>
                  </select>
                </label>

                <label htmlFor="forma_outras">
                  Outras Formas (se houver)
                  <input
                    id="forma_outras"
                    type="text"
                    value={terreno.forma_outras}
                    onChange={e =>
                      setTerreno({ ...terreno, forma_outras: e.target.value })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="orientacao">
                  Orientação do Imóvel
                  <select
                    id="orientacao"
                    value={terreno.orientacao}
                    onChange={e =>
                      setTerreno({ ...terreno, orientacao: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="" />
                    <option value="nascente">Nascente</option>
                    <option value="poente">Poente</option>
                  </select>
                </label>

                <label htmlFor="situacao">
                  Situação do Imóvel
                  <select
                    id="situacao"
                    value={terreno.situacao}
                    onChange={e =>
                      setTerreno({ ...terreno, situacao: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="meio quadra">Meio de quadra</option>
                    <option value="esquina">Esquina</option>
                    <option value="tres frentes">Tres Frentes</option>
                    <option value="outros">Outros (especificar abaixo)</option>
                  </select>
                </label>

                <label htmlFor="situacao_outras">
                  Outras Situações (se houver)
                  <input
                    id="situacao_outras"
                    type="text"
                    value={terreno.situacao_outras}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        situacao_outras: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="protecao">
                  Proteção do imóvel
                  <select
                    id="protecao"
                    value={terreno.protecao}
                    onChange={e =>
                      setTerreno({ ...terreno, protecao: e.target.value })
                    }
                  >
                    <option value="" />
                    <option value="muro">Muro</option>
                    <option value="cerca">Cerca</option>
                    <option value="divisa predio">Divisa com Predio</option>
                    <option value="nenhum">Nenhum</option>
                    <option value="outros">Outros (especificar abaixo)</option>
                  </select>
                </label>

                <label htmlFor="protecao_outras">
                  Outras Proteções (se houver)
                  <input
                    id="protecao_outras"
                    type="text"
                    value={terreno.protecao_outras}
                    onChange={e =>
                      setTerreno({
                        ...terreno,
                        protecao_outras: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="observacoes">
                  Observações
                  <textarea
                    id="observacoes"
                    label="Observações"
                    type="text"
                    value={terreno.observacoes}
                    onChange={e =>
                      setTerreno({ ...terreno, observacoes: e.target.value })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  terreno: PropTypes.objectOf(PropTypes.object),
  setTerreno: PropTypes.func,
  setEdit: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  terreno: null,
  setTerreno: null,
  setEdit: null,
};
