import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import avalieBackgroundSignUp from '../../assets/avalie-background-SignIn.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: #0d2c5b;
  outline: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  width: 100%;
  max-width: 700px;

  img {
    width: 400px;
    margin-top: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 400px;
    align-items: center;
    text-align: center;

    h2 {
      color: #fff;
      margin-bottom: 10px;
    }

    p {
      margin: 10px 0;
      color: #fff;
    }

    input {
      width: 350px;
      margin-top: 10px;
      color: #0d2c5b;

      &::placeholder {
        color: #666;
      }
    }

    button {
      margin-top: 20px;
      width: 350px;
      height: 40px;
      background: #fa923f;
      border: 0;
      border-radius: 4px;
      transition: 0.2s;

      &:hover {
        transform: translateY(-3px);
      }
    }
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  animation: ${appearFromRight} 1s;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${avalieBackgroundSignUp}) no-repeat center;
  background-size: cover;
`;
