import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import './config/ReactotronConfig';

import GlobalStyles from './styles/global';
import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <Router history={history}>
            <Routes />
            <GlobalStyles />
            <ToastContainer autoClose={3000} />
          </Router>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}
