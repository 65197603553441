import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  h1 {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
  }

  button {
    grid-area: footer;
    width: 200px;
    height: 40px;
    align-self: flex-end;
    background: #fa923f;
    border: 0;
    border-radius: 4px;
    transition: 0.2s;
    margin-left: 10px;
    outline: none;

    &:hover {
      transform: translateY(-3px);
    }
  }

  div {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-top: 8px;
    font-size: 12px;
    margin-left: 5px;
  }

  h1 {
    color: #fa923f;
    font-size: 24px;
    margin-top: 16px;
  }

  strong {
    margin-top: 10px;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const Texto = styled.div`
  display: grid;
  grid-template-columns: 35% 35% 35%;
  grid-template-areas: 'side1 middle side2';

  label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3%;
  }

  .side1 {
    display: flex;
    flex-direction: column;
    grid-area: side1;
    span {
      font-size: 18px;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    grid-area: middle;
    span {
      font-size: 18px;
    }
    h4 {
      margin-left: 5px;
    }
    hr {
      margin-right: 50px;
    }

    .calcs {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      margin-bottom: 2%;

      span {
        font-size: 10px;
        color: #000;
      }
    }

    .title {
      display: flex;
      align-items: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      span {
        font-size: 10px;
      }
    }
  }

  .side2 {
    display: flex;
    flex-direction: column;
    grid-area: side2;
    span {
      font-size: 18px;
    }

    .calcs {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      margin-bottom: 2%;

      span {
        font-size: 10px;
        color: #000;
      }
    }
  }
`;
