export function ofertaRequest({
  somatorio_valores,
  quantidade_imoveis,
  media,
  media_ajustada,
  fator_oferta,
  fator_ponderacao,
  media_ponderada,
  media_ponderada_menos,
  media_ponderada_mais,
  id_ptam,
}) {
  return {
    type: '@oferta/OFERTA_REQUEST',
    payload: {
      somatorio_valores,
      quantidade_imoveis,
      media,
      media_ajustada,
      fator_oferta,
      fator_ponderacao,
      media_ponderada,
      media_ponderada_menos,
      media_ponderada_mais,
      id_ptam,
    },
  };
}

export function ofertaUpdate({
  id,
  somatorio_valores,
  quantidade_imoveis,
  media,
  media_ajustada,
  fator_oferta,
  fator_ponderacao,
  media_ponderada,
  media_ponderada_menos,
  media_ponderada_mais,
  id_ptam,
}) {
  return {
    type: '@oferta/OFERTA_UPDATE',
    payload: {
      id,
      somatorio_valores,
      quantidade_imoveis,
      media,
      media_ajustada,
      fator_oferta,
      fator_ponderacao,
      media_ponderada,
      media_ponderada_menos,
      media_ponderada_mais,
      id_ptam,
    },
  };
}

export function ofertaDelete(id) {
  return {
    type: '@oferta/OFERTA_DELETE',
    payload: { id },
  };
}

export function ofertaLoad(id_ptam) {
  return {
    type: '@oferta/OFERTA_LOAD',
    payload: { id_ptam },
  };
}

export function ofertaSuccess(oferta) {
  return {
    type: '@oferta/OFERTA_SUCCESS',
    payload: { oferta },
  };
}

export function ofertaFailure() {
  return {
    type: '@oferta/OFERTA_FAILURE',
  };
}
