import React from 'react';
import { FaHome, FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Textarea } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { ptamUpdate } from '../../../store/modules/ptam/actions';
import Loader from '../../../components/Loader';

import { Container, Header } from './styles';

const schema = Yup.object().shape({
  finalidade: Yup.string(),
});

export default function Finalidade({ setFlag, flag }) {
  const ptam = useSelector(state => state.ptam.ptam);
  const loading = useSelector(state => state.ptam.loading);

  const dispatch = useDispatch();

  function handlePtam({ finalidade }) {
    if (ptam) {
      const { id } = ptam;
      if (finalidade) {
        dispatch(ptamUpdate(id, finalidade));
        setFlag({ ...flag, finalidade: true });
      } else {
        toast.error('A finalidade precisa ser informada');
      }
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      <Form initialData={ptam} schema={schema} onSubmit={handlePtam}>
        <Header>
          <div>
            <FaHome size={20} color="#fa923f" />
            <h1>Finalidade do PTAM</h1>
          </div>
          <div>
            <button type="submit">
              <div>
                <FaSave size={22} color="#fff" />
                <span>{ptam.finalidade ? 'Atualizar' : 'Cadastrar'}</span>
              </div>
            </button>
          </div>
        </Header>
        <div>
          <Textarea rows="2" cols="50" name="finalidade" />
        </div>
      </Form>
    </Container>
  );
}

Finalidade.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
