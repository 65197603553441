import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdGavel } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Handle from './handle';
import Loader from '../../../components/Loader';

import api from '../../../services/api';
import { procJudiSuccess } from '../../../store/modules/procJudi/actions';

import { Container, Header, Body } from './styles';

export default function Judicial() {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [judicial, setJudicial] = useState({
    id: null,
    processo: '',
    acao: '',
    forum: '',
    requerente: '',
    requerido: '',
    tipo: '',
    nome: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadJudicials() {
      const response = await api.get(`/judicials/${ptam.id_judicial}`);

      if (response.data) {
        setJudicial(response.data);
        setLoading(false);
      }
    }

    if (ptam.id_judicial) {
      setLoading(true);
      loadJudicials();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/judicials/${judicial.id}`);

      setJudicial({
        id: null,
        processo: '',
        acao: '',
        forum: '',
        requerente: '',
        requerido: '',
        tipo: '',
        nome: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(procJudiSuccess(null));

      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          judicial={judicial}
          setJudicial={setJudicial}
          setEdit={setEdit}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirma exclusão do processo judicial?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Os dados adicionados serão removidos após confirmação.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdGavel size={20} color="#fa923f" />
          <h1>Processo Jurídico (se necessário)</h1>
        </div>
        <div>
          {!ptam.id_judicial ? (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Processo</span>
              </div>
            </button>
          ) : null}

          {ptam.id_judicial && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Processo</span>
              </div>
            </button>
          )}

          {ptam.id_judicial && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Processo</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados do Processo Jurídico</h1>

        <strong>Nº do Processo</strong>
        <span>{judicial.processo}</span>

        <strong>Ação</strong>
        <span>{judicial.acao}</span>

        <strong>Fórum</strong>
        <span>{judicial.forum}</span>

        <strong>Requerente</strong>
        <span>{judicial.requerente}</span>

        <strong>Requerido</strong>
        <span>{judicial.requerido}</span>

        <strong>Tipo de Tratamento</strong>
        <span>{judicial.tipo}</span>

        <strong>Nome</strong>
        <span>{judicial.nome}</span>

        <hr />
      </Body>
    </Container>
  );
}
