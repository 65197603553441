import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteSweep, MdLibraryAdd, MdViewList } from 'react-icons/md';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from '../../../services/api';
import { contextsSuccess } from '../../../store/modules/contexto/actions';

import Handle from './handle';
import Loader from '../../../components/Loader';

import { Container, Header, Body } from './styles';

export default function Contexto({ setFlag, flag }) {
  const user = useSelector(state => state.user.profile);
  const ptam = useSelector(state => state.ptam.ptam);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);

  const [contexto, setContexto] = useState({
    id: null,
    contexto_urbano: '',
    analise_mercadologica: '',
    id_user: user.id,
    id_ptam: ptam.id,
  });

  useEffect(() => {
    async function loadContexts() {
      const response = await api.get(`/contexts/${ptam.id_context}`);

      if (response.data) {
        setContexto(response.data);
        setLoading(false);
      }
    }

    if (ptam.id_context) {
      setLoading(true);
      loadContexts();
    }
  }, [modal]);

  function handleEdit() {
    setEdit(true);
    setModal(true);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/contexts/${ptam.id_context}`);

      setContexto({
        id: null,
        contexto_urbano: '',
        analise_mercadologica: '',
        id_user: user.id,
        id_ptam: ptam.id,
      });

      dispatch(contextsSuccess(null));

      setFlag({ ...flag, contexto: false });
      setExcluir(false);
      setLoading(false);
      toast.success('Dados excluídos com sucesso!');
    } catch (error) {
      toast.error(error.response);
    }
  }

  return (
    <Container>
      {loading && <Loader />}
      {modal ? (
        <Handle
          open={modal}
          setModal={setModal}
          edit={edit}
          contexto={contexto}
          setContexto={setContexto}
          setEdit={setEdit}
          setFlag={setFlag}
          flag={flag}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir o Contexto e a Análise
            Mercadológica ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão do Contexto e Análise Mercadológica atual ela não
              estará mais disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <MdViewList size={20} color="#fa923f" />
          <h1>Contexto Urbano e Análise Mercadológica</h1>
        </div>
        <div>
          {!ptam.id_context && (
            <button type="button" onClick={() => setModal(true)}>
              <div>
                <MdLibraryAdd size={22} color="#fff" />
                <span>Incluir Contexto</span>
              </div>
            </button>
          )}

          {ptam.id_context && (
            <button type="button" onClick={() => handleEdit()}>
              <div>
                <FaEdit size={22} color="#fff" />
                <span>Editar Contexto</span>
              </div>
            </button>
          )}

          {ptam.id_context && (
            <button type="button" onClick={() => setExcluir(true)}>
              <div>
                <MdDeleteSweep size={22} color="#fff" />
                <span>Excluir Contexto</span>
              </div>
            </button>
          )}
        </div>
      </Header>
      <Body>
        <h1>Dados do Contexto e Análise Mercadológica</h1>

        <strong>Contexto</strong>
        <span>{contexto.contexto_urbano}</span>

        <strong>Análise Mercadológica</strong>
        <span>{contexto.analise_mercadologica}</span>
      </Body>
    </Container>
  );
}

Contexto.propTypes = {
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};
