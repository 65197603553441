import styled, { css } from 'styled-components';

export const Container = styled.nav`
  height: 100%;
  background-color: #fff;
  color: #0d2c5b;
  font-size: 1.4rem;
  list-style: none;
  border-radius: 4px;

  hr {
    height: 1px;
    background-color: #fa923f;
    width: 95%;
    opacity: 0.3;
    margin: 0 auto;
    margin-top: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 80px;
    width: 80px;
  }

  div {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    strong {
      font-size: 32px;
      color: #0d2c5b;
      padding-left: 16px;
    }

    span {
      margin: 4px 0;
      color: #0d2c5b;
      padding-left: 16px;
      font-size: 16px;
    }
  }
`;

export const Menu = styled.ul`
  margin-top: 8px;
`;

export const ListItem = styled.li`
  position: relative;

  cursor: pointer;
  z-index: 1;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #fa923f;
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
      background-color 0.1s;
  }

  &:hover::before {
    transform: scaleY(1);
    width: 100%;

    ${props =>
      props.selectedMenu &&
      css`
        transform: scaleY(0);
        width: 0%;
      `}
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    background: transparent;

    border: 0;

    color: #0d2c5b;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    position: relative;
    z-index: 10;

    span {
      color: #0d2c5b;
      font-size: 12px;

      ${props =>
        props.selectedMenu &&
        css`
          color: #fa923f;
        `}
    }

    svg {
      margin-right: 2rem;

      ${props =>
        props.selectedMenu &&
        css`
          color: #fa923f;
        `}
    }
  }
`;
