import produce from 'immer';

const INITIAL_STATE = {
  finality: null,
};

export default function finality(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@req/FINALITY_SUCCESS': {
        draft.finality = action.payload.finality;
        break;
      }

      case '@ptam/PTAM_CLEAR': {
        draft.finality = null;
        break;
      }

      default:
    }
  });
}
