export function requesterRequest({
  nome,
  tipo,
  documento,
  email,
  oab,
  seccional,
  sexo,
  cep,
  endereco,
  numero,
  complemento,
  estado,
  cidade,
  bairro,
  inventariado,
  id_user,
}) {
  return {
    type: '@req/REQUESTER_REQUEST',
    payload: {
      nome,
      tipo,
      documento,
      email,
      oab,
      seccional,
      sexo,
      cep,
      endereco,
      numero,
      complemento,
      estado,
      cidade,
      bairro,
      inventariado,
      id_user,
    },
  };
}

export function requesterUpdate({
  id,
  nome,
  tipo,
  documento,
  email,
  oab,
  seccional,
  sexo,
  cep,
  endereco,
  numero,
  complemento,
  estado,
  cidade,
  bairro,
  inventariado,
  id_user,
  id_ptam,
}) {
  return {
    type: '@req/REQUESTER_UPDATE',
    payload: {
      id,
      nome,
      tipo,
      documento,
      email,
      oab,
      seccional,
      sexo,
      cep,
      endereco,
      numero,
      complemento,
      estado,
      cidade,
      bairro,
      inventariado,
      id_user,
      id_ptam,
    },
  };
}

export function requesterDelete(id) {
  return {
    type: '@req/REQUESTER_DELETE',
    payload: { id },
  };
}

export function requesterClear() {
  return {
    type: '@req/REQUESTER_CLEAR',
  };
}

export function requestersAllLoad(id_user) {
  return {
    type: '@req/REQUESTER_ALL_USER',
    payload: { id_user },
  };
}

export function requesterLoad(id) {
  return {
    type: '@req/REQUESTER_LOAD',
    payload: { id },
  };
}

export function requesterSuccess(requester) {
  return {
    type: '@req/REQUESTER_SUCCESS',
    payload: { requester },
  };
}

export function requesterFailure() {
  return {
    type: '@req/REQUESTER_FAILURE',
  };
}
