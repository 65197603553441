import React from 'react';
import { FaCircle } from 'react-icons/fa';

import { Container } from './styles';

export default function Pago() {
  return (
    <>
      <Container>
        <FaCircle size={16} color="#0d2c" />
      </Container>
    </>
  );
}
