import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { ptamFailure, ptamSuccess } from './actions';

export function* savePtam({ payload }) {
  try {
    const response = yield call(api.post, 'ptams', payload);

    toast.success('Ptam cadastrado com sucesso !');

    yield put(ptamSuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao salvar o PTAM !');
    yield put(ptamFailure());
  }
}

export function* updatePtam({ payload }) {
  try {
    const response = yield call(api.put, 'ptams', payload);

    toast.success('Ptam atualizado com sucesso!');
    yield put(ptamSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao atualizar o PTAM !');
    yield put(ptamFailure());
  }
}

export function* statusPtam({ payload }) {
  try {
    const { status } = payload;
    /* se for false limpa o link de download */
    if (!status) {
      Object.assign(payload, { link_download: '' });
    }
    const response = yield call(api.put, 'ptams-status', payload);

    toast.success('Ptam atualizado com sucesso!');
    yield put(ptamSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao atualizar o PTAM !');
    yield put(ptamFailure());
  }
}

export function* loadPtam({ payload }) {
  try {
    const { ptam } = payload;

    /* carrega o reducer do ptam */
    yield put(ptamSuccess(ptam));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao carregar o PTAM escolhido!');
    yield put(ptamFailure());
  }
}

export function* deletePtam({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/ptams/${id}`);

    toast.success('Ptam apagado com sucesso!');
  } catch (err) {
    toast.error('Falha ao apagar o Ptam!');
    yield put(ptamFailure());
  }
}

export function* updatePtamRequester({ payload }) {
  try {
    yield call(api.put, '/ptam-requester', payload);
    toast.success('Ptam atualizado com sucesso!');
  } catch (err) {
    toast.error('Falha ao atualizar o Ptam com o Solicitante');
    yield put(ptamFailure());
  }
}

export default all([
  takeLatest('@ptam/PTAM_REQUEST', savePtam),
  takeLatest('@ptam/PTAM_LOAD', loadPtam),
  takeLatest('@ptam/PTAM_UPDATE', updatePtam),
  takeLatest('@ptam/PTAM_STATUS', statusPtam),
  takeLatest('@ptam/PTAM_DELETE', deletePtam),
  takeLatest('@ptam/PTAM_UPDATE_REQUESTER', updatePtamRequester),
]);
