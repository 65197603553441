export function cartorioRequest({
  matricula,
  cartorio,
  descricao,
  cadastro,
  vlr_imposto_anual,
  vlr_imposto_mensal,
  mts_terreno,
  vlr_venal_terreno,
  mts_construcao,
  vlr_venal_construcao,
  vlr_venal_total,
  id_user,
  id_ptam,
}) {
  return {
    type: '@reg/CARTORIO_REQUEST',
    payload: {
      matricula,
      cartorio,
      descricao,
      cadastro,
      vlr_imposto_anual,
      vlr_imposto_mensal,
      mts_terreno,
      vlr_venal_terreno,
      mts_construcao,
      vlr_venal_construcao,
      vlr_venal_total,
      id_user,
      id_ptam,
    },
  };
}

export function cartorioUpdate({
  id,
  matricula,
  cartorio,
  descricao,
  cadastro,
  vlr_imposto_anual,
  vlr_imposto_mensal,
  mts_terreno,
  vlr_venal_terreno,
  mts_construcao,
  vlr_venal_construcao,
  vlr_venal_total,
  id_user,
  id_ptam,
}) {
  return {
    type: '@reg/CARTORIO_UPDATE',
    payload: {
      id,
      matricula,
      cartorio,
      descricao,
      cadastro,
      vlr_imposto_anual,
      vlr_imposto_mensal,
      mts_terreno,
      vlr_venal_terreno,
      mts_construcao,
      vlr_venal_construcao,
      vlr_venal_total,
      id_user,
      id_ptam,
    },
  };
}

export function cartorioLoad(id) {
  return {
    type: '@reg/CARTORIO_LOAD',
    payload: { id },
  };
}

export function cartorioDelete(id) {
  return {
    type: '@reg/CARTORIO_DELETE',
    payload: { id },
  };
}

export function cartorioSuccess(registration_id) {
  return {
    type: '@reg/CARTORIO_SUCCESS',
    payload: { registration_id },
  };
}

export function cartorioFailure() {
  return {
    type: '@reg/CARTORIO_FAILURE',
  };
}
