import produce from 'immer';

const INITIAL_STATE = {
  ptam: {
    id: null,
    finalidade: null,
    tipo: null,
    status: false,
    link_download: null,
    delete: false,
    mobile_lock: false,
    individual: false,
    id_user: null,
    id_requester: null,
    id_judicial: null,
    id_situation: null,
    id_registration: null,
    id_area: null,
    id_infrastructure: null,
    id_land: null,
    id_building: null,
    id_improvement: null,
    id_inspection: null,
    id_context: null,
    id_samples: null,
    id_offer: null,
  },
  loading: false,
};

export default function ptam(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@ptam/PTAM_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@ptam/PTAM_UPDATE': {
        draft.loading = true;
        break;
      }

      case '@ptam/PTAM_SUCCESS': {
        draft.ptam = action.payload.ptam;
        draft.loading = false;
        break;
      }

      case '@area/AREAS_SUCCESS': {
        draft.ptam.id_area = action.payload.areas_id;
        break;
      }

      case '@improvement/IMPROVEMENT_SUCCESS': {
        draft.ptam.id_improvement = action.payload.improvement_id;
        break;
      }

      case '@context/CONTEXTS_SUCCESS': {
        draft.ptam.id_context = action.payload.contexts_id;
        break;
      }

      case '@building/EDIFICACAO_SUCCESS': {
        draft.ptam.id_building = action.payload.building_id;
        break;
      }

      case '@infra/INFRA_SUCCESS': {
        draft.ptam.id_infrastructure = action.payload.infra_id;
        break;
      }

      case '@jud/PROCJUDI_SUCCESS': {
        draft.ptam.id_judicial = action.payload.judicial_id;
        break;
      }

      case '@reg/CARTORIO_SUCCESS': {
        draft.ptam.id_registration = action.payload.registration_id;
        break;
      }

      case '@req/REQUESTER_SUCCESS': {
        draft.ptam.id_requester = action.payload.requester.id;
        break;
      }

      case '@sit/SITUATION_SUCCESS': {
        draft.ptam.id_situation = action.payload.situation_id;
        break;
      }

      case '@land/TERRENO_SUCCESS': {
        draft.ptam.id_land = action.payload.land_id;
        break;
      }

      case '@inspection/INSPECTIONS_SUCCESS': {
        draft.ptam.id_inspection = action.payload.inspection_id;
        break;
      }

      case '@ptam/PTAM_LOAD_SUCCESS': {
        draft.ptam = action.payload.ptam;
        break;
      }

      case '@ptam/PTAM_CLEAR': {
        draft.ptam = null;
        draft.loading = false;
        break;
      }

      case '@ptam/PTAM_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
