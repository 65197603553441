import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import api from '../../../../services/api';
import { contextsSuccess } from '../../../../store/modules/contexto/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  contexto,
  setContexto,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  async function handleSave() {
    try {
      const response = edit
        ? await api.put('/contexts', contexto)
        : await api.post('/contexts', contexto);

      const { id } = response.data;

      dispatch(contextsSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setFlag({ ...flag, contexto: true });
    setModal(false);
    setEdit(false);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Contexto Urbano e Análise Mercadológica &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=iHHcdE6j03g&list=PLWszlQVI17L4ibZFxgobrvsaSfP5OCtGF&index=14&t=0s"
                  >
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados de
              Contexto Urbano e Análise Mercadológica do imóvel.
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="contexto">
                  Contexto Urbano
                  <textarea
                    autoFocus
                    required
                    id="contexto"
                    type="text"
                    value={contexto.contexto_urbano}
                    onChange={e =>
                      setContexto({
                        ...contexto,
                        contexto_urbano: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="contexto">
                  Análise Mercadológica
                  <textarea
                    required
                    id="analise"
                    type="text"
                    value={contexto.analise_mercadologica}
                    onChange={e =>
                      setContexto({
                        ...contexto,
                        analise_mercadologica: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  contexto: PropTypes.objectOf(PropTypes.object),
  setContexto: PropTypes.func,
  setEdit: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  contexto: null,
  setContexto: null,
  setEdit: null,
};
