import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { amostrasFailure, amostrasSuccess } from './actions';

export function* saveamostras({ payload }) {
  try {
    const response = yield call(api.post, 'samples', payload);

    toast.success('Amostra cadastrada com sucesso!');

    yield put(amostrasSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao cadastrar Amostra, verifique seus dados!');

    yield put(amostrasFailure());
  }
}

export function* updateamostras({ payload }) {
  try {
    const response = yield call(api.put, 'samples', payload);

    toast.success('Amostra atualizada com sucesso!');

    yield put(amostrasSuccess(response.data));
  } catch (err) {
    toast.error('Falha ao cadastrar Amostra, verifique seus dados!');

    yield put(amostrasFailure());
  }
}

export function* changeamostras({ payload }) {
  try {
    const response = yield call(api.put, 'samples-offer', payload);

    toast.success('Amostra atualizada com sucesso!');

    yield put(amostrasSuccess(response.data));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao cadastrar Amostra, verifique seus dados!');

    yield put(amostrasFailure());
  }
}

export function* loadAmostras({ payload }) {
  try {
    const { sample } = payload;

    /* carrega o reducer do ptam */
    yield put(amostrasSuccess(sample));

    history.push('/ptam');
  } catch (err) {
    toast.error('Falha ao carregar a Amostra escolhida!');
    yield put(amostrasFailure());
  }
}

export function* deleteAmostras({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/samples/${id}`);
    yield call(api.delete, `/images-sample/${id}`);

    toast.success('Amostra deletada com sucesso !');
    yield put(amostrasSuccess(null));
  } catch (err) {
    toast.error('Falha ao deletar a Amostra escolhida!');
    yield put(amostrasFailure());
  }
}

export default all([
  takeLatest('@amostra/AMOSTRAS_REQUEST', saveamostras),
  takeLatest('@amostra/AMOSTRAS_LOAD', loadAmostras),
  takeLatest('@amostra/AMOSTRAS_UPDATE', updateamostras),
  takeLatest('@amostra/AMOSTRAS_CHANGE', changeamostras),
  takeLatest('@amostra/AMOSTRAS_DELETE', deleteAmostras),
]);
