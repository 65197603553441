import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: 0;
  border-radius: 10px;
  background: #deffc0 0% 0% no-repeat padding-box;

  strong {
    margin-top: 3px;
    margin-left: 16px;
    color: #dec600;
  }
`;
