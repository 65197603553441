import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';

import {
  signatureRequest,
  signatureUpdate,
} from '../../store/modules/signature/actions';

import { Container, Form } from './styles';

export default function Signatures({
  open,
  setOpen,
  view,
  assinatura,
  setRefresh,
  setAssinatura,
  edit,
}) {
  const dispatch = useDispatch();

  function handleSignature() {
    /* valida a assinatura */
    if (assinatura.titulo === '') {
      toast.error('É preciso informar um título para a assinatura!');
      return;
    }

    /* valida a descrição */
    if (assinatura.descricao === '') {
      toast.error('É preciso informar uma descrição para a assinatura!');
      return;
    }

    /* valida o valor total da assinatura */
    if (assinatura.valor_total === 0) {
      toast.error('É preciso informar um valor para a assinatura!');
      return;
    }

    /* valida a validade da assinatura */
    if (assinatura.dias_validade === 0) {
      toast.error('É preciso informar o numero de dias da assinatura!');
      return;
    }

    /* valida as parcelas da assinatura */
    if (assinatura.parcelas === 0) {
      toast.error(
        'É preciso informar o numero de parcelas da assinatura (minimo de 1)!'
      );
      return;
    }

    /* valida botao p/ pagamento da assinatura */
    if (assinatura.botao === '') {
      toast.error(
        'É preciso informar o botão (Cielo) p/ pagamento da assinatura!'
      );
      return;
    }

    if (edit) {
      dispatch(signatureUpdate(assinatura));
    } else {
      dispatch(signatureRequest(assinatura));
    }

    setRefresh(true);
    setOpen(false);
  }

  function handleParcela() {
    let vlr_parc = 0;
    let vlr_liq = 0;

    vlr_liq = assinatura.valor_total * (1 - assinatura.desconto / 100);

    if (assinatura.parcelas === 1) {
      vlr_parc = vlr_liq;
    } else {
      vlr_parc = (vlr_liq / assinatura.parcelas).toFixed(2);
    }

    setAssinatura({ ...assinatura, vlr_parcela: parseFloat(vlr_parc) });
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Dados da Assinatura</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para gravação, edição ou visualização de
              uma assinatura
            </DialogContentText>
            <Form>
              <div className="side1">
                <label htmlFor="sku">
                  Sku
                  <input
                    id="sku"
                    type="text"
                    value={assinatura.sku}
                    onChange={e =>
                      setAssinatura({ ...assinatura, sku: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="titulo">
                  Título da assinatura
                  <input
                    id="titulo"
                    type="text"
                    value={assinatura.titulo}
                    onChange={e =>
                      setAssinatura({ ...assinatura, titulo: e.target.value })
                    }
                  />
                </label>

                <label htmlFor="descricao">
                  Descrição da assinatura
                  <input
                    id="descricao"
                    type="text"
                    value={assinatura.descricao}
                    onChange={e =>
                      setAssinatura({
                        ...assinatura,
                        descricao: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="valor_total">
                  Valor total da assinatura
                  <NumberFormat
                    required
                    id="valor_total"
                    value={assinatura.valor_total}
                    onChange={e =>
                      setAssinatura({
                        ...assinatura,
                        valor_total: parseFloat(
                          e.target.value
                            .replace(/\./g, '')
                            .replace(',', '.')
                            .replace('R$ ', '')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="desconto">
                  Valor do desconto (%)
                  <NumberFormat
                    id="desconto"
                    label="Valor do desconto (%)"
                    value={assinatura.desconto}
                    type="number"
                    onChange={e =>
                      setAssinatura({
                        ...assinatura,
                        desconto: parseFloat(e.target.value),
                      })
                    }
                  />
                </label>

                <label htmlFor="parcelas">
                  Numero de parcelas
                  <input
                    id="parcelas"
                    type="number"
                    value={assinatura.parcelas}
                    onChange={e =>
                      setAssinatura({
                        ...assinatura,
                        parcelas: parseFloat(e.target.value),
                      })
                    }
                    onBlur={() => handleParcela()}
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="vlr_parcela">
                  Valor por parcela
                  <NumberFormat
                    disabled
                    id="vlr_parcela"
                    label="Valor por parcela"
                    value={assinatura.vlr_parcela}
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                  />
                </label>

                <label htmlFor="validade">
                  Numero de dias de validade
                  <input
                    id="validade"
                    type="number"
                    value={assinatura.dias_validade}
                    onChange={e =>
                      setAssinatura({
                        ...assinatura,
                        dias_validade: e.target.value,
                      })
                    }
                  />
                </label>

                <label htmlFor="botao">
                  Botão p/ pagamento
                  <textarea
                    id="botao"
                    type="text"
                    value={assinatura.botao}
                    onChange={e =>
                      setAssinatura({ ...assinatura, botao: e.target.value })
                    }
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => handleSignature()}
              color="primary"
              disabled={view}
            >
              {view ? '' : 'Salvar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Signatures.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  view: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  assinatura: PropTypes.objectOf(PropTypes.object),
  setRefresh: PropTypes.func,
  setAssinatura: PropTypes.func.isRequired,
};

Signatures.defaultProps = {
  assinatura: null,
  setRefresh: false,
};
