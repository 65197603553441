import React from 'react';

import { FadeLoader } from 'react-spinners';

import { Container, Content } from './styles';

function Loader() {
  return (
    <Container>
      <Content>
        <FadeLoader color="#fa923f" />
        <h1>Processando...</h1>
      </Content>
    </Container>
  );
}

export default Loader;
