import styled from 'styled-components';

export const Container = styled.div``;

export const Order = styled.div`
  display: flex;
  align-items: center;

  label {
    flex: 1;
    padding: 0 16px;

    input {
      width: 100%;
    }
  }
`;
