import React from 'react';

import Terreno from './Terreno';
import Edificacao from './Edificacao';
import Benfeitorias from './Benfeitorias';

export default function Descricao({ setFlag, flag }) {
  return (
    <>
      <Terreno setFlag={setFlag} flag={flag} />
      <hr />
      <Edificacao />
      <hr />
      <Benfeitorias />
    </>
  );
}
