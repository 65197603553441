import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MdDeleteSweep, MdDescription, MdLibraryAdd } from 'react-icons/md';
import {
  FaFileInvoice,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaBinoculars,
  FaEdit,
} from 'react-icons/fa';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SeachIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';

import api from '../../../services/api';
import { tipoDelete } from '../../../store/modules/tipoimovel/actions';
import Handle from './handle';
import { Container, Header, BtnStyle, PageActions } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
}));

export default function TipoImovel() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [tipoes, settipoes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [tipo, setTipo] = useState({
    id: null,
    descricao: '',
    vida_util: 0,
  });

  useEffect(() => {
    async function loadTipo() {
      const response = await api.get(
        'tipo',
        {
          params: {
            per_page: 8,
            page,
          },
        },
        [refresh, page]
      );

      settipoes(response.data);
    }

    loadTipo();
    setRefresh(false);
    setTipo([]);
  }, [refresh, page]);

  function handlePage(action) {
    if (action === 'next') {
      setPage(page + 1);
    }

    if (action === 'back') {
      setPage(page - 1);
    }

    if (page < 0) {
      setPage(0);
    }
  }

  async function handleSearch() {
    const response = await api.get('tipo-descr', {
      params: {
        idade: search,
        page,
      },
    });

    settipoes(response.data);
  }

  function handleEdit(data) {
    setTipo(data);
    setOpenModal(true);
    setView(false);
    setEdit(true);
  }

  function handleView(data) {
    setTipo(data);
    setOpenModal(true);
    setView(true);
    setEdit(false);
  }

  function handleExcluir(data) {
    setTipo(data);
    setExcluir(true);
  }

  function handleDelete() {
    dispatch(tipoDelete(tipo.id));
    setExcluir(false);
    setTipo([]);
    setRefresh(true);
  }

  return (
    <Container>
      {openModal ? (
        <Handle
          open={openModal}
          setOpen={setOpenModal}
          view={view}
          edit={edit}
          tipo={tipo}
          setTipo={setTipo}
          setRefresh={setRefresh}
        />
      ) : null}
      <div>
        <Dialog
          open={excluir}
          onClose={() => setExcluir(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Tem certeza de que deseja excluir o item da tabela Tipo de Imóvel ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a exclusão do item da tabela Tipo de Imóvel atual ela não
              estará mais disponível para uso !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExcluir(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDelete()}
              color="primary"
              autoFocus
              style={{ outline: 'none' }}
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Header>
        <div>
          <FaFileInvoice size={22} color="#5781c0" />
          <h1>Controle da Tabela Tipo de Imóvel</h1>
        </div>
        <div>
          <button type="button" onClick={() => setOpenModal(true)}>
            <div>
              <MdLibraryAdd size={22} color="#fff" />
              <span>Incluir Registro</span>
            </div>
          </button>
        </div>
      </Header>
      <hr />
      <div>
        <FormControl className={classes.margin}>
          <InputLabel htmlFor="input-with-icon-adornment">
            Pesquise pela Descrição
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            type="number"
            startAdornment={
              <InputAdornment position="start">
                <FaBinoculars />
              </InputAdornment>
            }
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </FormControl>
        <Fab
          color="default"
          aria-label="seacr"
          className={classes.fab}
          size="small"
          onClick={() => handleSearch()}
        >
          <SeachIcon size="small" />
        </Fab>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Descrição</StyledTableCell>
              <StyledTableCell align="center">Vida Útil</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tipoes.map(tp => (
              <StyledTableRow key={tp.id}>
                <StyledTableCell align="center">{tp.descricao}</StyledTableCell>
                <StyledTableCell align="center">{tp.vida_util}</StyledTableCell>
                <StyledTableCell align="right">
                  <BtnStyle>
                    <button
                      type="button"
                      title="visualizar"
                      onClick={() => handleView(tp)}
                    >
                      <MdDescription size={20} color="#5781c0" />
                    </button>

                    <button
                      type="button"
                      title="editar"
                      onClick={() => handleEdit(tp)}
                    >
                      <FaEdit size={20} color="#5781c0" />
                    </button>

                    <button
                      type="button"
                      title="deletar"
                      onClick={() => handleExcluir(tp)}
                    >
                      <MdDeleteSweep size={20} color="#F93636" />
                    </button>
                  </BtnStyle>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <PageActions>
        <div>
          <button
            type="button"
            onClick={() => handlePage('back')}
            disabled={page < 2}
          >
            <FaAngleDoubleLeft size={24} color="#fff" />
            <strong>
              <span>Voltar</span>
            </strong>
          </button>
          <button
            type="button"
            onClick={() => handlePage('next')}
            disabled={page > tipoes.length}
          >
            <strong>
              <span>Próximo</span>
            </strong>
            <FaAngleDoubleRight size={24} color="#fff" />
          </button>
        </div>
      </PageActions>
    </Container>
  );
}
