import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import image from './Image/reducer';
import requester from './requester/reducer';
import finality from './finality/reducer';
import ptam from './ptam/reducer';
import sample from './amostras/reducer';
import offer from './oferta/reducer';

export default combineReducers({
  auth,
  user,
  image,
  requester,
  finality,
  ptam,
  sample,
  offer,
});
