export function tipoRequest({ descricao, vida_util }) {
  return {
    type: '@tipo/REQUEST',
    payload: { descricao, vida_util },
  };
}

export function tipoUpdate({ id, descricao, vida_util }) {
  return {
    type: '@tipo/UPDATE',
    payload: { id, descricao, vida_util },
  };
}

export function tipoDelete(id) {
  return {
    type: '@tipo/DELETE',
    payload: { id },
  };
}

export function tipoFailure() {
  return {
    type: '@tipo/FAILURE',
  };
}
