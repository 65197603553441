/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

import api from '../../../../services/api';
import { areasSuccess } from '../../../../store/modules/areas/actions';

import { Container, Form } from './styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function Handle({
  open,
  setModal,
  edit,
  area,
  setArea,
  setRefresh,
  setEdit,
  setFlag,
  flag,
}) {
  const dispatch = useDispatch();

  async function handleSave() {
    if (!area.total) {
      toast.error('A área total do Imóvel precisa ser informada!');
      return;
    }

    try {
      const response = edit
        ? await api.put('/areas', area)
        : await api.post('/areas', area);

      const { id } = response.data;

      dispatch(areasSuccess(id));
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }

    setFlag({ ...flag, areas: true });
    setModal(false);
    setEdit(false);
    setRefresh(true);
  }

  return (
    <Container>
      <div>
        <Dialog
          open={open}
          onClose={() => setModal(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Dados das Áreas (Metragens) do Imóvel &nbsp;&nbsp;
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="inherit">
                    Ajuda com preenchimento desta tela?
                  </Typography>
                  <em>Veja o vídeo de </em>{' '}
                  <a
                    target="_blank"
                    href="https://youtu.be/SJzFAybCgGY"
                    rel="noreferrer"
                  >
                    como preencher
                  </a>
                  <em> corretamente os campos desta tela!</em>{' '}
                </>
              }
            >
              <HelpOutlineIcon />
            </HtmlTooltip>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo para gravação ou edição dos dados das
              Áreas do imóvel. ** ATENÇÃO ** ITENS EM VERMELHO SÃO
              OBRIGATÓRIOS!!
            </DialogContentText>
            <Form>
              <div className="side1">
                <h1>Áreas do Imóvel</h1>
                <label htmlFor="terreno">
                  Área do Terreno (m²)
                  <NumberFormat
                    id="terreno"
                    value={area.terreno}
                    onChange={e =>
                      setArea({
                        ...area,
                        terreno: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="construido">
                  Área Construída (m²)
                  <NumberFormat
                    id="construido"
                    value={area.construido}
                    onChange={e =>
                      setArea({
                        ...area,
                        construido: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="privado">
                  Área Privada (m²)
                  <NumberFormat
                    id="privado"
                    value={area.privado}
                    onChange={e =>
                      setArea({
                        ...area,
                        privado: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>
              </div>
              <div className="middle" />
              <div className="side2">
                <label htmlFor="comum">
                  Área Comum (m²)
                  <NumberFormat
                    id="comum"
                    value={area.comum}
                    onChange={e =>
                      setArea({
                        ...area,
                        comum: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="fracao">
                  Área Fracionada (m²)
                  <NumberFormat
                    id="fracao"
                    label="Área Fracionada"
                    value={area.fracao}
                    onChange={e =>
                      setArea({
                        ...area,
                        fracao: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>

                <label htmlFor="total">
                  Área total a ser considerada
                  <NumberFormat
                    required
                    id="total"
                    label="Área Total"
                    value={area.total}
                    onChange={e =>
                      setArea({
                        ...area,
                        total: parseFloat(
                          e.target.value.replace(/\./g, '').replace(',', '.')
                        ),
                      })
                    }
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </label>
              </div>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}

Handle.propTypes = {
  open: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
  setModal: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  area: PropTypes.objectOf(PropTypes.object),
  setArea: PropTypes.func,
  setEdit: PropTypes.func,
  setRefresh: PropTypes.func,
  setFlag: PropTypes.func.isRequired,
  flag: PropTypes.PropTypes.objectOf(PropTypes.bool).isRequired,
};

Handle.defaultProps = {
  area: null,
  setArea: null,
  setEdit: null,
  setRefresh: false,
};
